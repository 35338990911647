// TODO: Typescript typings
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, runInAction, action, computed } from 'mobx';
import { createContext, useContext } from 'react';
import mapRoles from '~utils/map-roles';
import { AccountService } from '~modules/features/account';
import permissions from '~config/permissions';
export class User {
    constructor() {
        this.roles = {};
        this.user = {};
        this.employee = {};
        this.isAccountFilled = () => {
            const { email, firstName, lastName, middleName, phone, photo, personalBirthday, city } = this.user;
            console.log('Check', this.user);
            return Boolean(email && firstName && lastName && middleName && phone && photo && personalBirthday && city);
        };
    }
    get permissions() {
        if (!!this.roles) {
            return Object.keys(permissions).filter(permission => {
                const roles = permissions[permission];
                return this.hasOneOfRoles(roles);
            });
        }
        return [];
    }
    async fetchEmployee(userId) {
        const employee = await AccountService.getEmployee(userId);
        runInAction(() => {
            this.roles = mapRoles(employee);
            this.employee = employee;
            this.user = { ...employee.user, employeeId: employee.id };
        });
    }
    async updateProfile(body) {
        const data = await AccountService.update(body);
        this.user = { ...this.user, ...data };
    }
    clearStore() {
        this.roles = {};
        this.user = {};
    }
    hasOneOfRoles(findingRoles) {
        if (this.roles) {
            const userRoles = Object.keys(this.roles);
            return findingRoles.findIndex((roleId) => userRoles.includes(roleId)) !== -1;
        }
        return false;
    }
}
__decorate([
    observable
], User.prototype, "roles", void 0);
__decorate([
    observable
], User.prototype, "user", void 0);
__decorate([
    observable
], User.prototype, "employee", void 0);
__decorate([
    computed
], User.prototype, "permissions", null);
__decorate([
    action.bound
], User.prototype, "fetchEmployee", null);
__decorate([
    action.bound
], User.prototype, "updateProfile", null);
__decorate([
    action.bound
], User.prototype, "clearStore", null);
__decorate([
    action.bound
], User.prototype, "hasOneOfRoles", null);
const userStore = new User();
export default userStore;
export const UserStoreContext = createContext(userStore);
export const useUserStore = () => useContext(UserStoreContext);
