import i18n from '~services/i18n';
export function generateApartmentName(section) {
    const { typeObject } = section;
    const name = i18n.t(`estate.apartment.${section.typeObject}`);
    if (typeObject === 'studio' || typeObject === 'room' || typeObject === 'room_hotel') {
        return name;
    }
    return `${name} квартира`;
}
function generateHouseName(section) {
    return i18n.t(`estate.house.${section.typeObject}`);
}
function generateSteadName(section) {
    if (section.landCategory) {
        return `Участок в ${i18n.t(`estate.stead.${section.landCategory}`, { context: 'in' })}`;
    }
    else {
        return 'Участок';
    }
}
function generateCommercialName(section) {
    return i18n.t(`estate.commercial.${section.typeObject}`);
}
export const EstateNamePipe = (estate) => {
    if (!(estate.section?.landCategory || estate.section?.typeObject)) {
        return `Не указано ${i18n
            .t(`estate.section.${estate.general.sectionName}_single`)
            .toLowerCase()}`;
    }
    switch (estate.general.sectionName) {
        case 'apartment':
            return generateApartmentName(estate.section);
        case 'house':
            return generateHouseName(estate.section);
        case 'stead':
            return generateSteadName(estate.section);
        case 'commercial':
            return generateCommercialName(estate.section);
    }
    return '';
};
