export function* combinationsGenerator(arrOfArr) {
    const [head, ...tail] = arrOfArr;
    const remainder = tail.length ? combinations(tail) : [{}];
    for (const r of remainder) {
        for (const h of head) {
            yield { ...h, ...r };
        }
    }
}
const combinations = (arrOfArr) => Array.from(combinationsGenerator(arrOfArr));
export default combinations;
