import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { Select } from '~modules/ui/select';
import DoubleSelect from '~ui/DoubleSelect';

import debounce from '~utils/debounce';
import shallowEqual from 'shallowequal';

export default class AutocompleteFromAPI extends PureComponent {
  static propTypes = {
    isDouble: PropTypes.bool,
    api: PropTypes.func.isRequired,
    defaultLabel: PropTypes.string,
    name: PropTypes.string.isRequired,
    secondaryName: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    parent: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
    withParent: PropTypes.bool,
    onOptionsChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    isMultiple: PropTypes.bool,
    params: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    placeholder: PropTypes.string
  };

  static defaultProps = {
    isDouble: false,
    withParent: false,
    isMultiple: false,
    isDisabled: false,
    params: {},
    onOptionsChange: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      options: [],
      isLoaded: false,
      isSearching: false,
      searchValue: ''
    };

    this.fetchDebounce = debounce(this.fetch, 500);
  }

  componentDidMount() {
    this._mounted = true;
    const { withParent, parent, defaultLabel } = this.props;
    if ((withParent && parent) || !withParent) {
      this.fetch('');
    }

    if (defaultLabel && (!withParent || (withParent && parent))) {
      this.setDefaultValue();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    if (this.source) {
      this.source.cancel('AutocompleteFromAPI is unmounting');
      this.source = null;
    }

    if (this.source2) {
      this.source2.cancel('AutocompleteFromAPI is unmounting');
      this.source2 = null;
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      withParent,
      parent,
      name,
      isMultiple,
      isDouble,
      secondaryName,
      defaultLabel,
      value
    } = this.props;

    if (withParent && parent !== prevProps.parent) {
      if (!parent && (isMultiple ? value.length : value)) {
        this.onChange({ target: { name, value: isMultiple ? [] : null } });
        return;
      }

      if (parent) {
        await this.fetch();

        if (defaultLabel && (isMultiple ? !value.lenght : !value)) {
          this.setDefaultValue();
        }
      }

      if (isMultiple) {
        const existedValues = value.filter(
          val => this.state.options?.findIndex(option => shallowEqual(option, val)) !== -1
        );

        this.onChange({ target: { name, value: existedValues } });
        if (isDouble) {
          this.onChange({ target: { name: secondaryName, value: existedValues } });
        }
      } else {
        const existedValue =
          this.state.options?.findIndex(option => shallowEqual(option, value)) !== -1;

        if (!existedValue) {
          this.onChange({ target: { name, value: null } });
          if (isDouble) {
            this.onChange({ target: { name: secondaryName, value: null } });
          }
        }
      }
    }
  }

  onChange = event => {
    this.props.onChange(event);
  };

  onSearch = event => {
    const searchValue = event.target.value;

    this.setState({ searchValue }, this.fetchDebounce);
  };

  setDefaultValue = async () => {
    const { name, defaultLabel, api, params, isMultiple, value } = this.props;

    if (isMultiple ? value.length : value) {
      return;
    }

    if (this.source2) {
      this.source2.cancel('User ia canceled');
      this.source2 = null;
    }

    const CancelToken = axios.CancelToken;
    const source2 = CancelToken.source();
    this.source2 = source2;

    try {
      const data = await api(
        source2.token,
        typeof params === 'function'
          ? params(defaultLabel)
          : { searchValue: defaultLabel, ...params }
      );
      const value = data.length ? (isMultiple ? [data[0]] : data[0]) : isMultiple ? [] : null;
      this.onChange({ target: { name, value } });
    } catch (error) {
      if (axios.isCancel(error)) {
        process.env.NODE_ENV !== 'production' &&
          console.log(`Request was canceled. Message: ${error.message}`);
      } else {
        //
      }
    }
  };

  fetch = async () => {
    const searchValue = this.state.searchValue.trim();

    if (this._mounted) {
      this.setState({ isSearching: true });
    }
    const { api, params } = this.props;

    if (this.source) {
      this.source.cancel('User ia canceled');
      this.source = null;
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    this.source = source;

    try {
      const data = await api(
        source.token,
        typeof params === 'function' ? params(searchValue) : { searchValue, ...params }
      );

      const options = data;

      if (this._mounted) {
        this.setState({
          options,
          isLoaded: true
        });
      }
      this.props.onOptionsChange(this.props.name, options);
    } catch (error) {
      if (axios.isCancel(error)) {
        process.env.NODE_ENV !== 'production' &&
          console.log(`Request was canceled. Message: ${error.message}`);
      } else {
        //
      }
    }

    if (this._mounted) {
      this.setState({ isSearching: false });
    }
    Promise.resolve();
  };

  render() {
    const { options, isSearching, searchValue } = this.state;

    /* eslint-disable no-unused-vars */
    const {
      isDouble,
      api,
      withParent,
      parent,
      defaultLabel,
      onOptionsChange,
      isDisabled,
      onChange,
      ...props
    } = this.props;
    /* eslint-enable no-unused-vars */

    const Component = isDouble ? DoubleSelect : Select;

    return (
      <Component
        isSearchable
        onChange={this.onChange}
        onSearch={this.onSearch}
        options={options}
        isDisabled={isDisabled || props.disabled || (withParent && !parent)}
        isSearching={isSearching}
        searchValue={searchValue}
        {...props}
      />
    );
  }
}
