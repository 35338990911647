import { PricePipe } from '~modules/pipes/price.pipe';
const rules = {
    required: (name, value, values, checkbox) => {
        if (typeof value !== 'object' && typeof value !== 'string') {
            value = `${value}`;
        }
        if (checkbox ? !value : !`${value}`.length) {
            return 'Это поле обязательно для заполнения';
        }
        return null;
    },
    min: (name, value, values, min) => {
        if (value.length < min) {
            return `Минимальная длина - ${min} символов`;
        }
        return null;
    },
    max: (name, value, values, max) => {
        if (value.length > max) {
            return `Максимальная длина - ${max} символов`;
        }
        return null;
    },
    min_value: (name, value, values, min) => {
        if (`${value}`.length && +value < min) {
            return `Не может быть меньше ${PricePipe(min)}`;
        }
        return null;
    },
    max_value: (name, value, values, max) => {
        if (`${value}`.length && +value > max) {
            return `Не может быть больше ${PricePipe(max)}`;
        }
        return null;
    },
    equal_value: (name, value, values, equal) => {
        if (!!value && +value !== +equal) {
            return `Должно быть равно ${equal}`;
        }
        return null;
    },
    confirms: (name, value, values, target) => {
        if (value !== values[target]) {
            return 'Пароли должны совпадать';
        }
        return null;
    },
    email: (name, value) => {
        if (value.length && !/^\S+@\S+\.\S+$/.test(value)) {
            return 'Неверный формат';
        }
        return null;
    },
    time: (name, value) => {
        if (value.length < 4) {
            return 'Это поле обязательно для заполнения';
        }
        return null;
    },
    url: (name, value) => {
        if (value.length && !/^https?:\/\/.+/.test(value)) {
            return 'Должна быть ссылка вида: https://domain.ru';
        }
        return null;
    },
    integer: (name, value) => {
        if (!Number.isInteger(+value)) {
            return 'Должно быть целым числом';
        }
        return null;
    }
};
export default rules;
