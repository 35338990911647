import { createContext, useContext } from 'react';

import dataStoreFactory from '~utils/data-store-factory';
import { TransfersEstatesService } from '~modules/features/transfers';
import userStore from '~stores/user';

import FILTERS from '~constants/estates/filters';
import { NEW_FIRST, NEW_LAST, PRICE_ASC, PRICE_DESC } from '~constants/types/sort';
import { ACTIVE } from '~constants/transfer-status';

const transformDistricts = value => {
  const districts = [];

  value.forEach(v => {
    const isSubdistict = !!v.parent;
    const district = isSubdistict ? v.parent : v;
    const e = districts.find(e => e.id === district.id);

    if (e) {
      if (isSubdistict) {
        e.subdistrictIds.push(v.id);
      }
    } else {
      const newDistrict = {
        id: district.id,
        subdistrictIds: []
      };

      if (isSubdistict) {
        newDistrict.subdistrictIds.push(v.id);
      }

      districts.push(newDistrict);
    }
  });

  return districts;
};

const Estates = dataStoreFactory(TransfersEstatesService, {
  sortOptions: [NEW_FIRST, NEW_LAST, PRICE_ASC, PRICE_DESC],
  initialFilters: params => ({
    sectionName: params.sectionName || 'apartment',
    completed: false,
    ...FILTERS[params.sectionName || 'apartment'],
    cities: [{ id: userStore.user.city.id, title: userStore.user.city.name }]
  }),
  transformFilters: ({ cities, districts, completed, ...filters }) => {
    return {
      cityIds: cities.map(e => e.id),
      districts: transformDistricts(districts),
      transferStatuses: completed ? [] : [ACTIVE],
      ...filters
    };
  }
});

const estatesStore = new Estates();

export default estatesStore;

export const EstatesStoreContext = createContext(estatesStore);

export const useEstatesStore = () => {
  const estatesStore = useContext(EstatesStoreContext);
  return estatesStore;
};
