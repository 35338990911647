import i18n from '~services/i18n';
import formatPrice from '~utils/format-price';
import * as SECTION_NAME from '~constants/types/section';

function generateApartmentName(data) {
  const { typeObject } = data;
  const name = i18n.t(`estate.apartment.${data.typeObject}`);
  if (typeObject === 'studio' || typeObject === 'room' || typeObject === 'room_hotel') {
    return name;
  }
  return `${name} квартира`;
}

function generateHouseName(data) {
  return i18n.t(`estate.house.${data.typeObject}`);
}

function generateSteadName(data) {
  return `Участок в ${i18n.t(`estate.stead.${data.landCategory}`, { context: 'in' })}`;
}

function generateCommercialName(data) {
  return i18n.t(`estate.commercial.${data.typeObject}`);
}

export default function generateEstateName(data) {
  let estateName = '';
  switch (data.sectionName) {
    case SECTION_NAME.APARTMENT:
      estateName = generateApartmentName(data);
      break;
    case SECTION_NAME.HOUSE:
      estateName = generateHouseName(data);
      break;
    case SECTION_NAME.STEAD:
      estateName = generateSteadName(data);
      break;
    case SECTION_NAME.COMMERCIAL:
      estateName = generateCommercialName(data);
  }
  const district = data.subdistrict
    ? `, ${data.subdistrict.name}`
    : data.district
    ? `, ${data.district.name}`
    : '';
  const street = data.street ? `, ${data.street}` : '';
  if (!(data?.landCategory || data?.typeObject)) {
    estateName = `Не указано ${i18n.t(`estate.section.${data.sectionName}_single`).toLowerCase()}`;
  }
  return `${estateName}, ${formatPrice(data.price)} ₽${district}${street}`;
}
