import { createContext, useContext } from 'react';
import { router } from '~services/router';
export class NavigationStore {
    constructor() {
        this.states = [];
        this.isBack = false;
        this.nextScrollX = null;
        this.nextScrollY = null;
        this.middleware = () => (toState, fromState, done) => {
            if (!this.isBack && fromState && !fromState?.meta?.options?.replace) {
                const scrollX = document.getElementById('scroll-x-restore');
                if (scrollX) {
                    fromState.scrollX = scrollX.scrollLeft;
                }
                fromState.scrollY = window.scrollY;
                this.states.push(fromState);
            }
            this.isBack = false;
            done();
            window.scrollTo(0, this.nextScrollY || 0);
            const scrollX = document.getElementById('scroll-x-restore');
            if (scrollX) {
                scrollX.scrollLeft = this.nextScrollX || 0;
            }
            this.nextScrollX = null;
            this.nextScrollY = null;
        };
        this.back = () => {
            if (this.states.length) {
                window.history.back();
            }
            else {
                const url = window.location.pathname
                    .split('/')
                    .filter((e, i) => !(i > 0 && e === ''))
                    .slice(0, -1)
                    .join('/');
                this.states.pop();
                const state = router.matchPath(url);
                if (state) {
                    router.navigate(state.name, state.params);
                }
                else {
                    router.navigate('home.view');
                }
            }
        };
        window.addEventListener('popstate', () => {
            this.isBack = true;
            if (this.states.length) {
                this.nextScrollX = this.states[this.states.length - 1]?.scrollX || 0;
                this.nextScrollY = this.states[this.states.length - 1]?.scrollY || 0;
                this.states.pop();
            }
        });
    }
}
export const navigationStore = new NavigationStore();
export const NavigationStoreContext = createContext(navigationStore);
export const useNavigationStore = () => {
    return useContext(NavigationStoreContext);
};
