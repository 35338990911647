import userStore from '~stores/user';
const hasPermission = (persmission) => {
    return userStore.permissions.includes(persmission);
};
export default function hasAccessToRoute(routeName) {
    const rootRoute = routeName ? routeName.split('.')[0] : '';
    switch (rootRoute) {
        case 'calendar':
            return hasPermission('calendar.view');
        case 'reports':
            return hasPermission('reports.view');
        case 'estates':
            return hasPermission('estates.view');
        case 'leads':
            return hasPermission('leads.view');
        case 'deals':
            return hasPermission('deals.view');
        case 'transfer':
            return hasPermission('transfers.view');
        case 'employees':
            return hasPermission('employees.view');
        case 'referrals':
            return hasPermission('referrals.view');
        case 'franchises':
            return hasPermission('franchises.view');
        case 'offices':
            return hasPermission('offices.view');
        case 'map':
            return hasPermission('map.view');
        case 'uploads':
            return hasPermission('uploads.view');
        case 'reminders':
            return hasPermission('reminders.view');
        case 'funnels':
            return hasPermission('funnels.view');
        default:
            return true;
    }
}
