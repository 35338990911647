import './button.module.scss';
import React, { memo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialButton from '@material-ui/core/Button';
export const Button = memo(({ children, className, disabled, isSending, ...props }) => {
    return (<MaterialButton styleName="button" className={className} disabled={disabled || isSending} {...props}>
        {isSending && (<div styleName="button__spinner">
            <CircularProgress size={24}/>
          </div>)}
        {children}
      </MaterialButton>);
});
// @ts-ignore
Button.muiName = 'Button';
