import { FormControl } from '~modules/forms';
const STEP0 = [
    'fromThirdPartyAgent',
    'thirdPartyAgentName',
    'thirdPartyAgentPhone',
    'customerName',
    'customerPhone',
    'customerEmail',
    'comment',
    'price',
    'hidden',
    'employee'
];
const STEP1 = [
    'sectionName',
    'propertyType',
    'dealType',
    'calculationForm',
    'commertionalObjectType',
    'houseObjectType',
    'apartmentsType',
    'conditionObject',
    'houseType',
    'wallMaterial',
    'landSquare',
    'landCategory',
    'fullSquare',
    'livingSquare',
    'kitchenSquare',
    'floor',
    'houseStorey',
    'wc',
    'distanceToCity',
    'sewerage',
    'gas',
    'water',
    'electricity',
    'security',
    'balcony',
    'country',
    'region',
    'cities',
    'districts',
    'subdistricts',
    'street',
    'leadSource',
    'leadSourceOther',
    'residentialComplex'
];
const STEPS = [STEP0, STEP1];
const getErrors = (step, form) => {
    const stepNames = STEPS[step].slice();
    if (step === 0) {
        form.$.customerPhone.$.forEach(c => {
            stepNames.push(c.name);
        });
        form.$.thirdPartyAgentPhone.$.forEach(c => {
            stepNames.push(c.name);
        });
    }
    const names = [];
    Object.keys(form.$).forEach(key => {
        const control = form.$[key];
        if (control instanceof FormControl) {
            if (stepNames.indexOf(control.name) !== -1 && control.error) {
                names.push(control.name);
            }
        }
        else {
            control.$.forEach(c => {
                if (stepNames.indexOf(c.name) !== -1 && c.error) {
                    names.push(c.name);
                }
            });
        }
    });
    return names;
};
export const scrollToError = (step, setStep, form) => {
    const errors = {
        0: getErrors(0, form),
        1: getErrors(1, form)
    };
    const errorsStep = errors[0].length ? 0 : 1;
    if (step !== errorsStep) {
        setStep(errorsStep);
    }
    setTimeout(() => {
        const names = errors[errorsStep];
        let min = null;
        let minInput = null;
        for (const name of names) {
            const input = document.getElementById(name);
            if (input) {
                const parrent = input.parentElement;
                if (parrent) {
                    const top = parrent.getBoundingClientRect().top;
                    if (min === null || top < min) {
                        min = top;
                        minInput = parrent;
                    }
                }
            }
        }
        if (minInput) {
            const top = minInput.getBoundingClientRect().top;
            const scrollBy = top - window.innerHeight / 2;
            const conatiner = document.querySelector('.MuiDialog-scrollBody');
            if (conatiner) {
                conatiner.scrollTop += scrollBy;
            }
            else {
                window.scrollTo(0, window.scrollY + scrollBy);
            }
        }
    }, step !== errorsStep ? 700 : 0);
};
