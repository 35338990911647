export const STATUS_NOT_PROCESSED = 'not_processed';
export const STATUS_FIRST_CONTACT = 'first_contact';
export const STATUS_SHOWS = 'shows';
export const STATUS_ASSIGNED_DEPOSIT_DATE = 'assigned_deposite_date';
export const STATUS_ASSIGNED_DEAL_DATE = 'assigned_deal_date';
export const STATUS_DEAL_FINISHED = 'deal_finished';
export const STATUS_REFUSE = 'refuse';
export const SECTION_APARTMENT = 'apartment';
export const SECTION_HOUSE = 'house';
export const SECTION_STEAD = 'stead';
export const SECTION_COMMERCIAL = 'commercial';
