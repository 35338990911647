import React, { memo } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
const overrides = {
    MuiAvatar: {
        img: {
            position: 'absolute'
        }
    },
    MuiButton: {
        contained: {
            boxShadow: 'none !important',
            '&:hover, &:focus, &:active': {
                boxShadow: 'none'
            }
        }
    },
    MuiDialog: {
        paper: {
            margin: '8px'
        },
        paperWidthXs: {
            '&$paperScrollBody': {
                maxWidth: '400px !important'
            }
        },
        paperWidthSm: {
            '&$paperScrollBody': {
                maxWidth: '600px !important'
            }
        },
        paperFullWidth: {
            width: 'calc(100% - 16px)'
        }
    },
    MuiDialogActions: {
        root: {
            padding: '24px',
            paddingTop: '8px'
        },
        spacing: {
            '& > * + *': {
                marginLeft: '24px'
            }
        }
    },
    MuiTabs: {
        root: {
            position: 'relative',
            '&::after': {
                content: '""',
                position: 'absolute',
                right: 0,
                bottom: 0,
                left: 0,
                height: '1px',
                backgroundColor: '#CCC'
            }
        },
        indicator: {
            zIndex: 2
        }
    },
    MuiOutlinedInput: {
        inputMarginDense: {
            paddingTop: '14.5px',
            paddingBottom: '14.5px'
        }
    },
    MuiInputLabel: {
        outlined: {
            '&$marginDense': {
                transform: 'translate(14px, 16px) scale(1)'
            }
        }
    },
    MuiTableRow: {
        root: {
            height: '100%'
        },
        hover: {
            transition: 'background-color 0s',
            cursor: 'pointer',
            '& > td > a': {
                transitionDuration: 0
            },
            '&:hover > td > a': {
                background: 'transparent'
            }
        }
    },
    MuiTableCell: {
        root: {
            padding: '12px',
            '&:first-child': {
                paddingLeft: '24px'
            },
            '&:last-child': {
                paddingRight: '24px'
            }
        },
        head: {
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '0.75rem',
            lineHeight: 'normal'
        },
        body: {
            fontSize: '13px'
        }
    },
    MuiStepper: {
        root: {
            padding: '0px'
        }
    },
    MuiStepContent: {
        root: {
            paddingRight: '0px'
        }
    },
    MuiPagination: {
        root: {
            flexShrink: 0
        }
    },
    MuiPaginationItem: {
        root: {
            height: '30px',
            minHeight: '30px',
            minWidth: '30px',
            margin: '0 1px',
            fontSize: '12px'
        },
        page: {
            '&$selected': {
                fontWeight: 'bold'
            }
        }
    }
};
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#5C6BC0'
        },
        secondary: {
            main: '#D81B60'
        },
        error: {
            main: '#E74C3C'
        }
    },
    overrides,
    props: {
        MuiTabs: {
            indicatorColor: 'primary',
            variant: 'scrollable',
            scrollButtons: 'auto'
        },
        MuiTextField: {
            fullWidth: true
        },
        MuiPaper: {
            elevation: 1
        }
    }
});
export const ApplicationTheme = memo(({ children }) => {
    return (<StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <>
          <CssBaseline />
          {children}
        </>
      </ThemeProvider>
    </StylesProvider>);
});
