import { UploadsLeadsService } from '~modules/features/uploads';
import uploadsStore from './stores/upload-leads';
import uploadsListStore from './stores/uploads';

import { UploadsPermissionsRoutes } from './views/uploads-permissions/uploads-permissions.routes';
import { UploadsGuard } from './uploads.guard';

import { uploadsStore as socketUploads } from './views/New/uploads';

import userStore from '~stores/user';

export default [
  {
    name: 'list',
    path: '/',
    component: () => import('./views/List').then(m => m.default),
    beforeActivate: UploadsGuard,
    onActivate: uploadsStore.getInitialPage
  },
  {
    name: 'uploads',
    path: '/list',
    component: () => import('./views/UploadsList').then(m => m.default),
    beforeActivate: UploadsGuard,
    onActivate: uploadsListStore.getInitialPage
  },
  {
    name: 'new',
    path: '/news',
    component: () => import('./views/New').then(m => m.default),
    beforeActivate: UploadsGuard,
    onActivate: async () => {
      await socketUploads.connect(userStore.user.employeeId);
    }
  },
  {
    name: 'duplicate',
    path: '/:id/duplicate',
    component: () => import('./views/Duplicate').then(m => m.default),
    beforeActivate: UploadsGuard,
    onActivate: ({ id }) => {
      const promises = [UploadsLeadsService.getOne(id), UploadsLeadsService.getDuplicate(id)];

      return Promise.all(promises).then(([duplicateUploadLead, { originalUploadLead }]) => ({
        originalUploadLead,
        duplicateUploadLead
      }));
    }
  },
  {
    name: 'exact-address',
    path: '/:id/exact-address',
    component: () => import('./views/ExactAddress').then(m => m.default),
    beforeActivate: UploadsGuard,
    onActivate: ({ id }) => {
      const promises = [UploadsLeadsService.getXlsx(id), UploadsLeadsService.getOne(id)];

      return Promise.all(promises).then(([{ xlsxData }, data]) => ({
        data,
        xlsxData
      }));
    }
  },
  {
    name: 'moderate',
    path: '/:id/moderate',
    component: () => import('./views/Moderate').then(m => m.default),
    beforeActivate: UploadsGuard,
    onActivate: ({ id }) => {
      const promises = [UploadsLeadsService.getXlsx(id), UploadsLeadsService.getOne(id)];

      return Promise.all(promises).then(([{ xlsxData }, data]) => ({
        data,
        xlsxData
      }));
    }
  },
  {
    name: 'show',
    path: '/:id',
    component: () => import('./views/Show').then(m => m.default),
    beforeActivate: UploadsGuard,
    onActivate: ({ id }) => UploadsLeadsService.getOne(id)
  },
  {
    name: 'permissions',
    path: '/permissions',
    children: UploadsPermissionsRoutes,
    beforeActivate: UploadsGuard
  },
  {
    name: 'forbidden',
    path: '/forbidden',
    component: () => import('./views/uploads-forbidden').then(m => m.UploadsForbidden)
  }
];
