import dataStoreFactory from '~utils/data-store-factory';
import request from '~services/request';
import { createContext, useContext } from 'react';
import axios from 'axios';
import moment from 'moment';
import userStore from '~stores/user';
const isAgent = () => Object.keys(userStore.roles).every(role => role === 'agent' ||
    role === 'lawyer' ||
    role === 'office_manager' ||
    role === 'dispatcher' ||
    role === 'upload_lead_manager');
export const ReportsService = {
    getAll: ({ agent, period, from, to, tab }, page, pageSize, sort) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if (!agent) {
            return Promise.resolve({ data: [], totalEntries: 0 });
        }
        if (period !== null) {
            from = to = null;
        }
        const promise = request
            .get(`/reports/agents/${agent}/${tab}`, {
            cancelToken: source.token,
            params: {
                page,
                pageSize,
                sort,
                period,
                from,
                to
            },
            headers: {
                'x-timezone': 'Z'
            }
        })
            .then(response => response.data);
        return [promise, source.cancel];
    }
};
const Reports = dataStoreFactory(ReportsService, {
    initialFilters: () => {
        const user = userStore.user;
        return {
            agent: isAgent()
                ? { id: user.employeeId, title: `${user.lastName} ${user.firstName} ${user.middleName}` }
                : null,
            period: { period: 'month' },
            date: {
                from: moment.utc(moment()
                    .startOf('month')
                    .format('YYYY-MM-DD')),
                to: moment.utc(moment()
                    .endOf('month')
                    .format('YYYY-MM-DD'))
            },
            tab: 'summary',
            franchises: [],
            referral: false
        };
    },
    transformFilters: (filters) => {
        return {
            agent: filters.agent?.id,
            period: filters?.period.period,
            from: filters.date?.from?._i || filters.date?.from,
            to: filters.date?.to?._i || filters.date?.to,
            tab: filters.tab
        };
    }
});
export const reportsStore = new Reports();
export const reportsStoreContext = createContext(reportsStore);
export const useReportsStore = () => useContext(reportsStoreContext);
