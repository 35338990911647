var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, runInAction, action, computed, reaction } from 'mobx';
import { Presence } from 'phoenix';
import { ChatsService } from '~modules/features/chats';
export class Chat {
    constructor(id, socket, setUnreadCount, updateUnreadCount) {
        this.channel = null;
        this.isInitialized = false;
        this.title = '';
        this.type = '';
        this.messages = [];
        this.reply = null;
        this.isActive = false;
        this.isLoading = false;
        this.isAll = false;
        this.isNextAll = false;
        this.employeesCount = 0;
        this.employees = [];
        this.presences = {};
        this.dividerId = null;
        this.isAtBottom = false;
        this.lastId = -1;
        this.connect = () => {
            const channel = this.socket.channel(`social:chat_${this.id}`, {});
            return new Promise((resolve, reject) => {
                channel
                    .join()
                    .receive('ok', () => {
                    this.channel = channel;
                    channel.on('new_msg', (message) => {
                        if (!this.messages.find(e => e.id === message.id)) {
                            runInAction(() => {
                                if (this.isActive && !this.hasUnreadMessages && this.isAtBottom && this.isNextAll) {
                                    message.read = true;
                                    channel.push('read_msg', {
                                        id: message.id
                                    });
                                }
                                else {
                                    this.updateUnreadCount(1);
                                }
                                this.messages.push(message);
                            });
                        }
                    });
                    channel.on('user_joined', () => {
                        this.employeesCount++;
                    });
                    channel.on('presence_state', state => {
                        this.presences = Presence.syncState(this.presences, state);
                    });
                    channel.on('presence_diff', diff => {
                        this.presences = Presence.syncDiff(this.presences, diff);
                    });
                    channel.on('like_msg', (data) => {
                        const { messageId, employee: { id: employeeId }, likeDislike } = data;
                        const message = this.messages.find(message => message.id === messageId);
                        if (message) {
                            const e = message.employeeMessages.find(e => e.employee.id === employeeId);
                            if (e) {
                                e.likeDislike = likeDislike;
                            }
                            else {
                                message.employeeMessages.push(data);
                            }
                        }
                    });
                    resolve(this);
                })
                    .receive('error', response => {
                    console.error('Unable to join to chat', response);
                    reject(response);
                });
            });
        };
        this.getChat = async () => {
            if (this.isInitialized) {
                this.updateDivider();
                return;
            }
            const response = await Promise.all([
                ChatsService.getOne(this.id),
                ChatsService.getMessages(this.id)
            ]);
            const chat = response[0];
            let messages = response[1];
            runInAction(() => {
                this.isNextAll = !messages.length || (messages.length > 0 && messages[0].read);
            });
            if (messages.length > 0 && !messages[0].read) {
                const fillMessages = await ChatsService.getMessages(this.id, messages[0].id);
                messages = fillMessages.concat(messages);
            }
            runInAction(() => {
                this.messages = messages;
                this.employeesCount = chat.employeesCount;
                this.title = chat.title;
                this.type = chat.type;
                this.isAll = messages.length < 1;
                this.recipient = chat.recipient;
            });
            this.isInitialized = true;
            this.updateDivider();
        };
        this.getMessages = async () => {
            if (!this.isLoading && !this.isAll) {
                runInAction(() => {
                    this.isLoading = true;
                });
                try {
                    const messages = await ChatsService.getMessages(this.id, this.messages[0].id);
                    runInAction(() => {
                        this.isAll = messages.length < 1;
                        this.messages = messages.concat(this.messages);
                    });
                }
                catch (error) {
                    //
                }
                runInAction(() => {
                    this.isLoading = false;
                });
            }
        };
        this.getNextMessages = async () => {
            if (!this.isLoading && !this.isNextAll) {
                runInAction(() => {
                    this.isLoading = true;
                });
                try {
                    const messages = await ChatsService.getMessages(this.id, this.messages[this.messages.length - 1].id, 'down');
                    runInAction(() => {
                        this.isNextAll = messages.length < 1;
                        this.messages = this.messages.concat(messages);
                    });
                }
                catch (error) {
                    //
                }
                runInAction(() => {
                    this.isLoading = false;
                });
            }
        };
        this.getLastMessages = async () => {
            runInAction(() => {
                this.isLoading = true;
            });
            try {
                const messages = await ChatsService.getMessages(this.id);
                runInAction(() => {
                    this.isAll = messages.length < 1;
                    this.messages = messages;
                });
            }
            catch (error) {
                //
            }
            runInAction(() => {
                this.isLoading = false;
            });
        };
        this.likeMessage = (id, like) => {
            if (this.channel) {
                this.channel
                    .push('like_msg', {
                    id,
                    like
                })
                    .receive('ok', () => {
                    const message = this.messages.find(message => message.id === id);
                    if (message) {
                        runInAction(() => {
                            message.myLike = like;
                        });
                    }
                });
            }
        };
        this.sendMessage = ({ text, photoIds }) => new Promise(resolve => {
            const replyMessage = this.reply;
            if (text.length && this.channel) {
                this.setReplyMessage(null);
                this.channel
                    .push('new_msg', {
                    text,
                    photoIds,
                    replyMessageId: replyMessage ? replyMessage.id : null
                })
                    .receive('ok', () => {
                    resolve(true);
                });
            }
            resolve(false);
        });
        this.reaction1 = reaction(() => this.hasUnreadMessages, () => {
            if (!this.hasUnreadMessages) {
                this.dividerId = null;
            }
            else {
                this.updateDivider();
            }
        });
        this.setAtBottom = (isAtBottom) => {
            console.log('Bottom:', isAtBottom);
            this.isAtBottom = isAtBottom;
        };
        this.id = id;
        this.socket = socket;
        this.setUnreadCount = setUnreadCount;
        this.updateUnreadCount = updateUnreadCount;
    }
    setActive(isActive) {
        this.isActive = isActive;
    }
    read(id) {
        if (id > this.lastId) {
            const message = this.messages.find(message => message.id === id);
            if (message && !message.read && this.channel) {
                this.lastId = id;
                this.channel
                    .push('read_msg', {
                    id: message.id
                })
                    .receive('ok', () => {
                    runInAction(() => {
                        this.messages.forEach(message => {
                            if (!message.read && message.id <= id) {
                                message.read = true;
                            }
                        });
                    });
                    this.setUnreadCount(this.unreadMessages);
                });
            }
        }
    }
    async readAll() {
        await ChatsService.readAllMessages(this.id);
        runInAction(() => {
            this.messages.forEach(message => {
                message.read = true;
            });
            this.setUnreadCount(0);
        });
    }
    setReplyMessage(reply) {
        this.reply = reply;
    }
    updateDivider() {
        const message = this.messages.find(message => !message.read);
        this.dividerId = message ? message.id : null;
    }
    get unreadMessages() {
        return this.messages.reduce((count, message) => (message.read ? count : count + 1), 0);
    }
    get isDirect() {
        return this.type === 'direct';
    }
    get hasUnreadMessages() {
        return this.unreadMessages > 0;
    }
}
__decorate([
    observable
], Chat.prototype, "title", void 0);
__decorate([
    observable
], Chat.prototype, "type", void 0);
__decorate([
    observable
], Chat.prototype, "messages", void 0);
__decorate([
    observable
], Chat.prototype, "reply", void 0);
__decorate([
    observable
], Chat.prototype, "isActive", void 0);
__decorate([
    observable
], Chat.prototype, "isLoading", void 0);
__decorate([
    observable
], Chat.prototype, "isAll", void 0);
__decorate([
    observable
], Chat.prototype, "isNextAll", void 0);
__decorate([
    observable
], Chat.prototype, "employeesCount", void 0);
__decorate([
    observable
], Chat.prototype, "employees", void 0);
__decorate([
    observable
], Chat.prototype, "presences", void 0);
__decorate([
    observable
], Chat.prototype, "recipient", void 0);
__decorate([
    observable
], Chat.prototype, "dividerId", void 0);
__decorate([
    action.bound
], Chat.prototype, "setActive", null);
__decorate([
    action.bound
], Chat.prototype, "read", null);
__decorate([
    action.bound
], Chat.prototype, "readAll", null);
__decorate([
    action.bound
], Chat.prototype, "setReplyMessage", null);
__decorate([
    action
], Chat.prototype, "updateDivider", null);
__decorate([
    computed
], Chat.prototype, "unreadMessages", null);
__decorate([
    computed
], Chat.prototype, "isDirect", null);
__decorate([
    computed
], Chat.prototype, "hasUnreadMessages", null);
