import React, { PureComponent } from 'react';
import Raven from 'raven-js';
import { ErrorBoundaryFallback } from './components/error-boundary-fallback';
export class ErrorBoundary extends PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            error: null
        };
    }
    componentDidCatch(error, info) {
        this.setState({ error });
        if (process.env.NODE_ENV === 'production') {
            Raven.captureException(error, { extra: info });
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.children !== prevProps.children) {
            this.setState({ error: null });
        }
    }
    render() {
        const { children, fallback } = this.props;
        return this.state.error ? fallback : children;
    }
}
ErrorBoundary.defaultProps = {
    children: null,
    fallback: <ErrorBoundaryFallback />
};
