var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from 'mobx';
import { createContext } from 'react';
class Layout {
    constructor() {
        this.isSidebarOpen = false;
        this.isSidebarShrink = true;
        this.isLoading = false;
        this.notify = {};
        this.error = null;
        this.skipMainNotify = false;
    }
    openSidebar() {
        this.isSidebarOpen = true;
    }
    closeSidebar() {
        this.isSidebarOpen = false;
    }
    setSidebarShrink(isSidebarShrink) {
        this.isSidebarShrink = isSidebarShrink;
    }
    toggleSidebarShrink() {
        this.isSidebarShrink = !this.isSidebarShrink;
    }
    setLoading(isLoading) {
        this.isLoading = isLoading;
    }
    showNotify(props) {
        this.notify = props;
    }
    setSkipMainNotify(skipNotify) {
        this.skipMainNotify = skipNotify;
    }
}
__decorate([
    observable
], Layout.prototype, "isSidebarOpen", void 0);
__decorate([
    observable
], Layout.prototype, "isSidebarShrink", void 0);
__decorate([
    observable
], Layout.prototype, "isLoading", void 0);
__decorate([
    observable
], Layout.prototype, "notify", void 0);
__decorate([
    observable
], Layout.prototype, "error", void 0);
__decorate([
    observable
], Layout.prototype, "skipMainNotify", void 0);
__decorate([
    action.bound
], Layout.prototype, "openSidebar", null);
__decorate([
    action.bound
], Layout.prototype, "closeSidebar", null);
__decorate([
    action.bound
], Layout.prototype, "setSidebarShrink", null);
__decorate([
    action.bound
], Layout.prototype, "toggleSidebarShrink", null);
__decorate([
    action.bound
], Layout.prototype, "setLoading", null);
__decorate([
    action.bound
], Layout.prototype, "showNotify", null);
__decorate([
    action.bound
], Layout.prototype, "setSkipMainNotify", null);
const layoutStore = new Layout();
export default layoutStore;
export const LayoutStoreContext = createContext(layoutStore);
