import request from '~services/request';

export const UploadsLeadsService = {
  getAll: (
    { sectionName, suspicious, sources, groupByPhone, date, ...estateParams },
    page,
    pageSize,
    sort
  ) =>
    request
      .get('/upload_leads', {
        params: {
          sectionName,
          page,
          pageSize,
          sort,
          estateParams,
          filterParams: { suspicious, sources, groupByPhone, date }
        }
      })
      .then(response => response.data),

  getOne: id => request.get(`/upload_leads/${id}`).then(response => response.data),

  getDuplicate: id => request.get(`/upload_leads/${id}/duplicate`).then(response => response.data),

  getXlsx: id => request.get(`/upload_leads/${id}/show/xlsx`).then(response => response.data),

  resolveDuplicate: (id, { accepted }) =>
    request
      .post(`/upload_leads/${id}/resolve_duplicate`, { accepted })
      .then(response => response.data),

  resolveAddress: (id, { accepted, locationIds, locationObjects }) =>
    request
      .put(`/upload_leads/${id}/resolve_address`, {
        accepted,
        locationIds,
        locationObjects
      })
      .then(response => response.data),

  update: (id, body) => request.post(`/upload_leads/${id}`, body).then(response => response.data),
  getXLSX: (estateIds, filterParams) => {
    let fp = undefined;
    let ep = undefined;
    if (filterParams) {
      const { suspicious, sources, groupByPhone, ...estateParams } = filterParams;
      fp = JSON.stringify({ suspicious, sources, groupByPhone });
      ep = JSON.stringify(estateParams);
    }
    return request
      .post('/upload_leads/generate_xlsx', {
        estateIds,
        sectionName: filterParams ? filterParams.sectionName : undefined,
        filterParams: fp,
        estateParams: ep,
        isAllSelected: !!filterParams
      })
      .then(response => response.data);
  }
};
