import { LeadsService } from '~modules/features/leads';
import leadsStore from './stores/leads';
export const LeadsRoutes = [
    {
        name: 'list',
        path: '/',
        component: () => import('./views/List').then(m => m.default),
        onActivate: leadsStore.getInitialPage
    },
    {
        name: 'show',
        path: '/:id',
        component: () => import('./views/leads-show').then(m => m.default),
        onActivate: ({ id }) => LeadsService.getOne(id),
        afterActivate: (router, toState) => {
            if (toState.data.archivedAt) {
                router.cancel();
                router.navigate('leads.list');
                return null;
            }
            return toState;
        }
    },
    {
        name: 'edit',
        path: '/:id/edit',
        component: () => import('./views/leads-form').then(m => m.default),
        onActivate: ({ id }) => LeadsService.getOne(id)
    },
    {
        name: 'new',
        path: '/new?section',
        component: () => import('./views/leads-form').then(m => m.default)
    }
];
