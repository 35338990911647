import { OfficesService } from '~modules/features/offices';
import officesStore from './stores/offices';

export default [
  {
    name: 'list',
    path: '/',
    component: () => import('./views/List').then(m => m.default),
    onActivate: officesStore.getInitialPage
  },
  {
    name: 'edit',
    path: '/:id/edit',
    component: () => import('./views/Form').then(m => m.default),
    onActivate: ({ id }) => OfficesService.getOne(id)
  },
  {
    name: 'new',
    path: '/new',
    component: () => import('./views/Form').then(m => m.default)
  }
];
