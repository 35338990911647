import { createContext, useContext } from 'react';
import { FunnelsService } from '~modules/features/funnels';
import { DragDropStore } from '~modules/ui/drag-drop';
export class FunnelsTransfersEstatesStore extends DragDropStore {
    constructor() {
        super(...arguments);
        this.name = 'FUNNELS::TRANSFERS::ESTATES';
        this.service = FunnelsService.getAllTransferEstates;
        this.serviceCount = FunnelsService.getTransferEstatesCount;
        this.getOffsets = (lists, variant) => {
            if (variant === 'all') {
                return [
                    lists[0].items.length,
                    lists[1].items.length,
                    lists[2].items.length,
                    lists[3].items.length,
                    lists[4].items.length,
                    -1,
                    -1
                ];
            }
            else if (variant === 'deal_finished') {
                return [-1, -1, -1, -1, -1, lists[5].items.length, -1];
            }
            return [-1, -1, -1, -1, -1, -1, lists[6].items.length];
        };
        this.getLists = data => {
            const result = [
                {
                    id: 'not_processed',
                    title: 'Не обработаны',
                    items: []
                },
                {
                    id: 'first_contact',
                    title: 'Первичный контакт',
                    items: []
                },
                {
                    id: 'shows',
                    title: 'Просмотры',
                    items: []
                },
                {
                    id: 'assigned_deposite_date',
                    title: 'Задаток',
                    items: []
                },
                {
                    id: 'assigned_deal_date',
                    title: 'Сделка',
                    items: []
                },
                {
                    id: 'deal_finished',
                    title: 'Сделка проведена',
                    items: []
                },
                {
                    id: 'refuse',
                    title: 'Отказ',
                    items: []
                }
            ];
            result.forEach(list => {
                list.items = data.filter(e => e.general.status === list.id);
            });
            return result;
        };
    }
}
export const funnelsTransfersEstatesStore = new FunnelsTransfersEstatesStore();
const FunnelsTransfersEstatesStoreContext = createContext(funnelsTransfersEstatesStore);
export const useFunnelsTransfersEstatesStore = () => useContext(FunnelsTransfersEstatesStoreContext);
