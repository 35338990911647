import { EEstateExport } from '../../../../estates.types';
import * as SECTION_NAME from '~constants/types/section';
export const isValidExport = (estate, exportTo) => {
    const { sectionName, city, street, photos, adType, description } = estate.general;
    if (exportTo === EEstateExport.YANDEX_REALTY) {
        if (!(city && photos.length && description)) {
            return false;
        }
        if (sectionName === SECTION_NAME.APARTMENT) {
            const { propertyType, houseNumber, houseNumberUploading, fullSquare, livingSquare, kitchenSquare, houseStorey, floor, constructionYear, constructionQuater, buildingState, typeObject, wc, conditionObject, building, houseType } = estate.section;
            if (!(propertyType &&
                typeObject &&
                street &&
                houseNumber &&
                houseNumberUploading &&
                fullSquare &&
                livingSquare &&
                kitchenSquare &&
                houseStorey &&
                floor &&
                wc &&
                constructionYear &&
                conditionObject &&
                building &&
                houseType)) {
                return false;
            }
            if ((propertyType === 'new_building' || propertyType === 'shareholder') &&
                !(typeObject !== 'room' && constructionQuater && buildingState)) {
                return false;
            }
        }
        if (sectionName === SECTION_NAME.HOUSE) {
            const { propertyType, houseNumber, houseNumberUploading, fullSquare, livingSquare, kitchenSquare, houseStorey, constructionYear, constructionQuater, buildingState, wc, conditionObject } = estate.section;
            if (!(propertyType &&
                street &&
                houseNumber &&
                houseNumberUploading &&
                houseStorey &&
                fullSquare &&
                livingSquare &&
                kitchenSquare &&
                wc &&
                constructionYear &&
                conditionObject)) {
                return false;
            }
            if ((propertyType === 'new_building' || propertyType === 'shareholder') &&
                !(constructionQuater && buildingState)) {
                return false;
            }
        }
        if (sectionName === SECTION_NAME.STEAD) {
            const { landCategory, number: houseNumber, landSquare } = estate.section;
            if (!(landCategory && street && houseNumber && landSquare)) {
                return false;
            }
        }
        if (sectionName === SECTION_NAME.COMMERCIAL) {
            const { dealType } = estate.general;
            const { houseNumber, houseNumberUploading, fullSquare, floor, typeObject, conditionObject } = estate.section;
            if (!(street &&
                houseNumber &&
                houseNumberUploading &&
                fullSquare &&
                floor &&
                typeObject &&
                conditionObject &&
                dealType === 'sale')) {
                return false;
            }
        }
    }
    return true;
};
