import { MapService } from '~modules/features/map';
export default [
    {
        name: 'view',
        path: '/',
        component: () => import('./Map').then(m => m.default),
        onActivate: async () => {
            const cities = await MapService.getAll();
            const Rostov = cities.find(city => city.name === 'Ростов-на-Дону') || cities[0];
            const data = await MapService.getOne(Rostov.id);
            return { cities, city: Rostov.id, data };
        }
    }
];
