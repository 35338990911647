import './RadioToggle.module.scss';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import parseEvent from '~utils/parse-event';

export default class RadioToggle extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    margin: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    trueLabel: PropTypes.node,
    falseLabel: PropTypes.node,
    nullLabel: PropTypes.node,
    withNull: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool
  };

  static defaultProps = {
    label: null,
    value: null,
    margin: 'normal',
    trueLabel: 'Есть',
    falseLabel: 'Нет',
    nullLabel: 'Нет информации',
    withNull: false,
    isDisabled: false,
    isRequired: false
  };

  onChange = event => {
    const { name, value } = parseEvent(event);
    this.props.onChange({
      target: {
        name,
        value: this.fromString(value)
      }
    });
  };

  fromString = value => {
    const { withNull } = this.props;
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return withNull ? null : false;
    }
  };

  toString = value => {
    const { withNull } = this.props;
    switch (value) {
      case true:
        return 'true';
      case false:
        return 'false';
      default:
        return withNull ? 'null' : 'false';
    }
  };

  render() {
    /* eslint-disable */
    const {
      label,
      margin,
      name,
      value,
      onChange,
      trueLabel,
      falseLabel,
      nullLabel,
      withNull,
      isDisabled,
      isRequired,
      ...props
    } = this.props;
    /* eslint-enable */

    return (
      <FormControl
        styleName="radio-toggle"
        component="fieldset"
        fullWidth
        disabled={isDisabled || props.disabled}
      >
        {label && <FormLabel component="legend">{`${label}${isRequired ? ' *' : ''}`}</FormLabel>}
        <RadioGroup
          styleName="radio-toggle__group"
          aria-label={label}
          name={name}
          onChange={this.onChange}
          value={this.toString(value)}
          {...props}
        >
          <FormControlLabel value="true" control={<Radio />} label={trueLabel} />
          <FormControlLabel value="false" control={<Radio />} label={falseLabel} />
          {withNull && <FormControlLabel value="null" control={<Radio />} label={nullLabel} />}
        </RadioGroup>
      </FormControl>
    );
  }
}
