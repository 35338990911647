import './leads-form.module.scss';
import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Validators } from '~modules/forms';
import { EmployeesService } from '~modules/features/employees';
import { LocationsService } from '~modules/features/locations';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { AutocompleteFromAPI } from '~modules/ui/autocomplete-from-api';
import { AutocompleteFromDadata } from '~modules/ui/autocomplete-from-dadata';
import { Select } from '~modules/ui/select';
import { Icon } from '~modules/ui/icon';
import RadioToggle from '~ui/RadioToggle';
import FormattedTextField, { PriceFormatter } from '~ui/FormattedTextField';
import { useTranslation } from 'react-i18next';
import { useUserStore } from '~stores/user';
import SECTION_TYPES, * as SECTION_NAME from '~constants/types/section';
import CONDITION_TYPES from '~constants/types/condition';
import MATERIAL_TYPES from '~constants/types/material';
import WALL_MATERIAL_TYPES from '~constants/types/wall-material';
import WC_TYPES from '~constants/types/wc';
import APARTMENT_TYPES from '~constants/types/apartment';
import COMMERCIAL_TYPES from '~constants/types/commercial';
import DEAL_TYPES from '~constants/types/deal';
import HOUSE_TYPES from '~constants/types/house';
import PROPERTY_TYPES from '~constants/types/property';
import STEAD_TYPES from '~constants/types/stead';
import CALCULATION_TYPES from '~constants/types/calculations';
import CUSTOMER_SOURCE_TYPES from '~constants/types/lead-source';
import { scrollToError } from './helpers/scroll-to-error';
import { STATUS_NOT_PROCESSED, STATUS_FIRST_CONTACT, STATUS_INTERESTED, STATUS_SHOWS } from '../../leads.constants';
const STATUSES_CHANGEABLE = [STATUS_NOT_PROCESSED, STATUS_FIRST_CONTACT, STATUS_INTERESTED];
const SquareProps = {
    endAdornment: (<InputAdornment position="end">
      м<sup>2</sup>
    </InputAdornment>)
};
const LandSquareProps = {
    endAdornment: (<InputAdornment position="end">
      <span>сот</span>
    </InputAdornment>)
};
export const LeadsForm = memo(observer(({ onCancel, onSubmit, status, lead, withStatus, isSending, form, appendCustomerPhone, removeCustomerPhone, removeThirdPartyAgentPhone, appendThirdPartyAgentPhone }) => {
    const { t } = useTranslation('translations');
    const userStore = useUserStore();
    const status$ = form.$.status.value;
    const statuses = useMemo(() => {
        if (lead) {
            switch (lead.general.status) {
                case STATUS_NOT_PROCESSED:
                    return [STATUS_NOT_PROCESSED, STATUS_FIRST_CONTACT, STATUS_INTERESTED, STATUS_SHOWS];
                case STATUS_FIRST_CONTACT:
                    return [STATUS_FIRST_CONTACT, STATUS_INTERESTED, STATUS_SHOWS];
                case STATUS_INTERESTED:
                    return [STATUS_INTERESTED, STATUS_SHOWS];
            }
            return [lead.general.status];
        }
        return [STATUS_NOT_PROCESSED, STATUS_FIRST_CONTACT, STATUS_INTERESTED, STATUS_SHOWS];
    }, [lead]);
    const statusHelper = useMemo(() => {
        if (lead ? status$ !== lead.general.status : status$ !== STATUS_NOT_PROCESSED) {
            return 'При сохранении понадобится создать напоминание';
        }
        return '';
    }, [lead, status$]);
    const [step, setStep] = useState(status === STATUS_NOT_PROCESSED ? 0 : 1);
    const onStepClick = useCallback((index) => () => {
        setStep(index);
    }, []);
    const onFormSubmit = useCallback(async (event) => {
        event.preventDefault();
        if (form.validate()) {
            onSubmit(form);
        }
        else {
            scrollToError(step, setStep, form);
        }
    }, [form, step, setStep]);
    useEffect(() => {
        if (status !== STATUS_NOT_PROCESSED) {
            form.validate();
            form.setTouched(true);
            form.setDirty(true);
        }
    }, []);
    const onOptionsChange = useCallback((_, options) => {
        if (options.length && status$ !== STATUS_NOT_PROCESSED) {
            form.$.districts.setValidator(value => Validators.required(value));
        }
        else {
            form.$.districts.removeValidator();
        }
    }, [form]);
    const sectionNames = form.$.sectionName.value;
    const propertyTypes = form.$.propertyType.value;
    const isEmployeeVisible = useMemo(() => {
        const roles = Object.keys(userStore.roles);
        if ((roles.length === 1 && roles.includes('agent')) || lead) {
            return false;
        }
        return true;
    }, [lead]);
    const isHiddenDisabled = useMemo(() => {
        if (lead) {
            return !!(lead.transfers && lead.transfers.length);
        }
        return false;
    }, [lead]);
    return (<form onSubmit={onFormSubmit} noValidate>
          <Stepper nonLinear activeStep={step} orientation="vertical">
            <Step>
              <StepButton onClick={onStepClick(0)}>Создание</StepButton>

              <StepContent>
                {withStatus && (<TextField variant="outlined" margin="normal" select label="Статус *" {...form.$.status.input} error={!!form.$.status.error} helperText={form.$.status.error || statusHelper} disabled={!STATUSES_CHANGEABLE.includes(lead?.general.status || STATUS_NOT_PROCESSED)}>
                    {statuses.map(option => (<MenuItem key={option} value={option}>
                        {t(`lead.status.${option}`)}
                      </MenuItem>))}
                  </TextField>)}

                <Typography styleName="form__subtitle" variant="h6" gutterBottom>
                  Клиент
                </Typography>

                <FormControlLabel control={<Checkbox value="fromThirdPartyAgent" color="primary" onChange={form.$.fromThirdPartyAgent.onChange} checked={form.$.fromThirdPartyAgent.value}/>} label="Покупатель из другого агентства недвижимости"/>

                {form.$.fromThirdPartyAgent.value && (<>
                    <TextField variant="outlined" margin="normal" label="ФИО агента *" {...form.$.thirdPartyAgentName.input} error={!!form.$.thirdPartyAgentName.error} helperText={form.$.thirdPartyAgentName.error}/>

                    {form.$.thirdPartyAgentPhone.$.map((control, index) => (<div key={index} styleName="form__phone">
                        <TextField variant="outlined" margin="normal" label={`Телефон агента${form.$.fromThirdPartyAgent.value ? '' : ' *'}`} {...control.input} error={!!control.error} helperText={control.error}/>
                        {form.$.thirdPartyAgentPhone.$.length > 1 && (<IconButton styleName="form__remove" onClick={removeThirdPartyAgentPhone(index)}>
                            <Icon name="close-circle"/>
                          </IconButton>)}
                      </div>))}

                    <Button styleName="form__add" type="button" color="primary" variant="contained" onClick={appendThirdPartyAgentPhone}>
                      Добавить
                    </Button>
                  </>)}

                <TextField variant="outlined" margin="normal" label="ФИО покупателя *" {...form.$.customerName.input} error={!!form.$.customerName.error} helperText={form.$.customerName.error}/>
                {form.$.customerPhone.$.map((control, index) => (<div key={index} styleName="form__phone">
                    <TextField variant="outlined" margin="normal" label={`Телефон покупателя${form.$.fromThirdPartyAgent.value ? '' : ' *'}`} {...control.input} error={!!control.error} helperText={control.error}/>
                    {form.$.customerPhone.$.length > 1 && (<IconButton styleName="form__remove" onClick={removeCustomerPhone(index)}>
                        <Icon name="close-circle"/>
                      </IconButton>)}
                  </div>))}

                <Button styleName="form__add" type="button" color="primary" variant="contained" onClick={appendCustomerPhone}>
                  Добавить
                </Button>

                <TextField variant="outlined" margin="normal" label="Email покупателя" {...form.$.customerEmail.input} error={!!form.$.customerEmail.error} helperText={form.$.customerEmail.error}/>

                <TextField variant="outlined" margin="normal" label="Комментарий" multiline {...form.$.comment.input}/>

                <FormattedTextField variant="outlined" margin="normal" formatter={PriceFormatter} required label="Сумма сделки" {...form.$.price.input} error={form.$.price.error}/>

                <FormControlLabel control={<Checkbox value="hidden" color="primary" onChange={form.$.hidden.onChange} checked={form.$.hidden.value} disabled={isHiddenDisabled}/>} label="Скрыть покупателя"/>

                {isEmployeeVisible && (<>
                    <Typography styleName="form__subtitle" variant="h6" gutterBottom>
                      Агент
                    </Typography>

                    <AutocompleteFromAPI variant="outlined" margin="normal" api={EmployeesService.search} params={{ roles: ['agent'] }} label="Агент *" isClearable={false} {...form.$.employee.input} error={form.$.employee.error}/>
                  </>)}
              </StepContent>
            </Step>
            <Step>
              <StepButton onClick={onStepClick(1)}>Первичный контакт</StepButton>

              <StepContent>
                <Typography styleName="form__subtitle" variant="h6" gutterBottom>
                  Основная информация
                </Typography>

                <TextField variant="outlined" margin="normal" label={`Форма расчета${status$ !== STATUS_NOT_PROCESSED ? ' *' : ''}`} select {...form.$.calculationForm.input} error={!!form.$.calculationForm.error} helperText={form.$.calculationForm.error}>
                  {CALCULATION_TYPES.map(value => (<MenuItem key={value} value={value}>
                      {t(`lead.calculation.${value}`)}
                    </MenuItem>))}
                </TextField>

                <TextField select variant="outlined" margin="normal" label="Источник покупателя" {...form.$.leadSource.input} error={!!form.$.leadSource.error} helperText={form.$.leadSource.error}>
                  {CUSTOMER_SOURCE_TYPES.map(option => (<MenuItem key={option} value={option}>
                      {t(`lead.source.${option}`)}
                    </MenuItem>))}
                </TextField>

                {form.$.leadSource.value === 'other' && (<TextField variant="outlined" margin="normal" label={`Источник покупателя${status$ !== STATUS_NOT_PROCESSED ? ' *' : ''}`} {...form.$.leadSourceOther.input} error={!!form.$.leadSourceOther.error} helperText={form.$.leadSourceOther.error}/>)}

                <Select variant="outlined" margin="normal" isMultiple isFlat name="sectionName" label={`Раздел${status$ !== STATUS_NOT_PROCESSED ? ' *' : ''}`} options={SECTION_TYPES} translation="estate.section" max={2} {...form.$.sectionName.input} error={form.$.sectionName.error}/>
                {(sectionNames.includes(SECTION_NAME.APARTMENT) ||
        sectionNames.includes(SECTION_NAME.HOUSE)) && (<Select variant="outlined" margin="normal" isMultiple isFlat label={`Тип недвижимости${form.$.propertyType.validator ? ' *' : ''}`} options={PROPERTY_TYPES} translation="estate.property" {...form.$.propertyType.input} error={form.$.propertyType.error}/>)}

                {form.$.sectionName.value.includes(SECTION_NAME.COMMERCIAL) && (<TextField variant="outlined" margin="normal" select name="dealType" label={`Тип сделки${status$ !== STATUS_NOT_PROCESSED ? ' *' : ''}`} {...form.$.dealType.input} error={!!form.$.dealType.error} helperText={form.$.dealType.error}>
                    {DEAL_TYPES.map(option => (<MenuItem key={option} value={option}>
                        {t(`estate.deal.${option}`)}
                      </MenuItem>))}
                  </TextField>)}

                {sectionNames.includes(SECTION_NAME.APARTMENT) && (<Select variant="outlined" margin="normal" isMultiple isFlat label={`Тип квартиры${form.$.apartmentsType.validator ? ' *' : ''}`} options={APARTMENT_TYPES} translation="estate.apartment" {...form.$.apartmentsType.input} error={form.$.apartmentsType.error}/>)}

                {sectionNames.includes(SECTION_NAME.HOUSE) && (<Select variant="outlined" margin="normal" isMultiple isFlat label={`Вид дома${form.$.houseObjectType.validator ? ' *' : ''}`} options={HOUSE_TYPES} translation="estate.house" {...form.$.houseObjectType.input} error={form.$.houseObjectType.error}/>)}
                {sectionNames.includes(SECTION_NAME.COMMERCIAL) && (<Select variant="outlined" margin="normal" isMultiple isFlat label={`Вид коммерческой недвижимости${form.$.commertionalObjectType.validator ? ' *' : ''}`} options={COMMERCIAL_TYPES} translation="estate.commercial" {...form.$.commertionalObjectType.input} error={form.$.commertionalObjectType.error}/>)}
                {sectionNames.includes(SECTION_NAME.STEAD) && (<Select variant="outlined" margin="normal" isMultiple isFlat label={`Категория земель${form.$.landCategory.validator ? ' *' : ''}`} options={STEAD_TYPES} translation="estate.stead" {...form.$.landCategory.input} error={form.$.landCategory.error}/>)}

                <Typography styleName="form__subtitle" variant="h6" gutterBottom>
                  Площадь и технические характеристики
                </Typography>

                {(sectionNames.includes(SECTION_NAME.APARTMENT) ||
        sectionNames.includes(SECTION_NAME.HOUSE) ||
        sectionNames.includes(SECTION_NAME.COMMERCIAL)) && (<TextField variant="outlined" margin="normal" type="number" label="Общая площадь" {...form.$.fullSquare.input} error={!!form.$.fullSquare.error} helperText={form.$.fullSquare.error} InputProps={SquareProps}/>)}
                {(sectionNames.includes(SECTION_NAME.APARTMENT) ||
        sectionNames.includes(SECTION_NAME.HOUSE)) && (<TextField variant="outlined" margin="normal" type="number" label="Жилая площадь" {...form.$.livingSquare.input} error={!!form.$.livingSquare.error} helperText={form.$.livingSquare.error} InputProps={SquareProps}/>)}
                {(sectionNames.includes(SECTION_NAME.APARTMENT) ||
        sectionNames.includes(SECTION_NAME.HOUSE)) && (<TextField variant="outlined" margin="normal" type="number" label="Площадь кухни" {...form.$.kitchenSquare.input} error={!!form.$.kitchenSquare.error} helperText={form.$.kitchenSquare.error} InputProps={SquareProps}/>)}
                {(sectionNames.includes(SECTION_NAME.STEAD) ||
        sectionNames.includes(SECTION_NAME.HOUSE)) && (<TextField variant="outlined" margin="normal" type="number" label="Площадь участка" {...form.$.landSquare.input} error={!!form.$.landSquare.error} helperText={form.$.landSquare.error} InputProps={LandSquareProps}/>)}
                {(sectionNames.includes(SECTION_NAME.APARTMENT) ||
        sectionNames.includes(SECTION_NAME.COMMERCIAL)) && (<TextField variant="outlined" margin="normal" type="number" label="Этаж помещения" {...form.$.floor.input} error={!!form.$.floor.error} helperText={form.$.floor.error}/>)}
                {(sectionNames.includes(SECTION_NAME.APARTMENT) ||
        sectionNames.includes(SECTION_NAME.HOUSE) ||
        sectionNames.includes(SECTION_NAME.COMMERCIAL)) && (<TextField variant="outlined" margin="normal" type="number" label="Этажность дома" {...form.$.houseStorey.input} error={!!form.$.houseStorey.error} helperText={form.$.houseStorey.error}/>)}
                {sectionNames.includes(SECTION_NAME.APARTMENT) && (<Select variant="outlined" margin="normal" isMultiple isFlat label="Тип дома" options={MATERIAL_TYPES} translation="estate.material" {...form.$.houseType.input} error={form.$.houseType.error}/>)}
                {sectionNames.includes(SECTION_NAME.HOUSE) && (<Select variant="outlined" margin="normal" isMultiple isFlat label="Материал стен" options={WALL_MATERIAL_TYPES} translation="estate.material" {...form.$.wallMaterial.input} error={form.$.wallMaterial.error}/>)}
                {(sectionNames.includes(SECTION_NAME.HOUSE) ||
        sectionNames.includes(SECTION_NAME.APARTMENT) ||
        sectionNames.includes(SECTION_NAME.COMMERCIAL)) && (<Select variant="outlined" margin="normal" isMultiple isFlat label="Состояние объекта" options={CONDITION_TYPES} translation="estate.condition" {...form.$.conditionObject.input} error={form.$.conditionObject.error}/>)}
                {(sectionNames.includes(SECTION_NAME.APARTMENT) ||
        sectionNames.includes(SECTION_NAME.APARTMENT)) && (<TextField variant="outlined" margin="normal" select label="Санузел" {...form.$.wc.input}>
                    {WC_TYPES.map(option => (<MenuItem key={option} value={option}>
                        {t(`estate.wc.${option}`)}
                      </MenuItem>))}
                  </TextField>)}
                {sectionNames.includes(SECTION_NAME.APARTMENT) && (<RadioToggle withNull label="Балкон" nullLabel="Неважно" {...form.$.balcony.input}/>)}
                {(sectionNames.includes(SECTION_NAME.APARTMENT) ||
        sectionNames.includes(SECTION_NAME.HOUSE) ||
        sectionNames.includes(SECTION_NAME.COMMERCIAL)) && (<RadioToggle withNull label="Охрана" nullLabel="Неважно" {...form.$.security.input}/>)}
                {(sectionNames.includes(SECTION_NAME.HOUSE) ||
        sectionNames.includes(SECTION_NAME.STEAD)) && (<RadioToggle withNull label="Канализация" nullLabel="Неважно" {...form.$.sewerage.input}/>)}
                {(sectionNames.includes(SECTION_NAME.HOUSE) ||
        sectionNames.includes(SECTION_NAME.STEAD)) && (<RadioToggle withNull label="Газ" nullLabel="Неважно" {...form.$.gas.input}/>)}
                {(sectionNames.includes(SECTION_NAME.HOUSE) ||
        sectionNames.includes(SECTION_NAME.STEAD)) && (<RadioToggle withNull label="Вода" nullLabel="Неважно" {...form.$.water.input}/>)}
                {(sectionNames.includes(SECTION_NAME.HOUSE) ||
        sectionNames.includes(SECTION_NAME.STEAD)) && (<RadioToggle withNull label="Электричество" nullLabel="Неважно" {...form.$.electricity.input}/>)}

                <Typography styleName="form__subtitle" variant="h6" gutterBottom>
                  Местоположение
                </Typography>

                <AutocompleteFromAPI variant="outlined" margin="normal" api={LocationsService.getCountries} name="country" label={`Страна${form.$.country.validator ? ' *' : ''}`} {...form.$.country.input} error={form.$.country.error}/>
                <AutocompleteFromAPI variant="outlined" margin="normal" api={LocationsService.getRegions} params={{ countryId: form.$.country.value && form.$.country.value.id }} withParent parent={form.$.country.value && form.$.country.value.id} label={`Область${form.$.region.validator ? ' *' : ''}`} {...form.$.region.input} error={form.$.region.error}/>
                <AutocompleteFromAPI variant="outlined" margin="normal" api={LocationsService.getCities} params={{ regionId: form.$.region.value && form.$.region.value.id }} withParent parent={form.$.region.value && form.$.region.value.id} isMultiple name="cities" label={`Населенный пункт${form.$.cities.validator ? ' *' : ''}`} {...form.$.cities.input} error={form.$.cities.error}/>
                <AutocompleteFromAPI variant="outlined" margin="normal" api={LocationsService.getDistricts} params={{ cityIds: form.$.cities.value.map(city => city.id) }} withParent parent={form.$.cities.value.length ? form.$.cities.value : null} isMultiple name="districts" label={`Район${form.$.districts.validator ? ' *' : ''}`} {...form.$.districts.input} error={form.$.districts.error} onOptionsChange={onOptionsChange}/>
                <AutocompleteFromAPI variant="outlined" margin="normal" api={LocationsService.getSubdistricts} params={{ districtIds: form.$.districts.value.map(district => district.id) }} withParent parent={form.$.districts.value.length ? form.$.districts.value : null} isMultiple name="subdistricts" label="Подрайон" {...form.$.subdistricts.input}/>

                {(sectionNames.includes(SECTION_NAME.HOUSE) ||
        sectionNames.includes(SECTION_NAME.STEAD)) && (<TextField variant="outlined" margin="normal" type="number" label="Расстояние до города" {...form.$.distanceToCity.input} error={!!form.$.distanceToCity.error} helperText={form.$.distanceToCity.error}/>)}

                {(sectionNames.includes(SECTION_NAME.APARTMENT) ||
        sectionNames.includes(SECTION_NAME.HOUSE)) &&
        (propertyTypes.includes('new_building') ||
            propertyTypes.includes('shareholder')) && (<TextField variant="outlined" margin="normal" label="Жилой комплекс" {...form.$.residentialComplex.input}/>)}

                <AutocompleteFromDadata variant="outlined" margin="normal" label="Улица" bound="street" countries={form.$.country.value && [form.$.country.value.title]} regions={form.$.region.value && [form.$.region.value.title]} cities={form.$.cities.value.length
        ? form.$.cities.value.map(({ title }) => title)
        : null} {...form.$.street.input}/>
              </StepContent>
            </Step>
          </Stepper>

          <div styleName="form__actions">
            <Button styleName="form__action" type="button" color="primary" variant="outlined" size="medium" onClick={onCancel} disabled={isSending}>
              Отмена
            </Button>
            <Button styleName="form__action" type="submit" color="primary" variant="contained" size="medium" disabled={isSending}>
              Сохранить
            </Button>
          </div>
        </form>);
}));
LeadsForm.defaultProps = {
    withStatus: false,
    isSending: false
};
