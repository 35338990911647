import styles from './select-search.module.scss';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';

import { Icon } from '~modules/ui/icon';

const InputProps = {
  classes: {
    input: styles.search__input
  },
  endAdornment: (
    <InputAdornment position="end">
      <Icon name="magnify" />
    </InputAdornment>
  )
};

export default class SelectSearch extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isSearching: PropTypes.bool.isRequired,
    searchValue: PropTypes.string,
    placeholder: PropTypes.string
  };

  static defaultProps = {
    placeholder: ''
  };

  onChange = event => {
    event.stopPropagation();
    this.props.onChange(event);
  };

  render() {
    const { name, isSearching, searchValue, placeholder } = this.props;

    return (
      <ListItem styleName="search">
        {isSearching && <LinearProgress styleName="search__progress" />}
        <TextField
          autoComplete="off"
          styleName="search__field"
          name={`${name}-search`}
          value={searchValue}
          onChange={this.onChange}
          variant="outlined"
          InputProps={InputProps}
          placeholder={placeholder}
          autoFocus
        />
      </ListItem>
    );
  }
}
