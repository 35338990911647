import moment from 'moment';
export default (params) => {
    return Object.keys(params).reduce((res, key) => {
        const value = params[key];
        if (key === 'date' || key.includes('Date')) {
            const date = JSON.parse(value);
            if (date.hasOwnProperty('from') || date.hasOwnProperty('to')) {
                return { ...res, [key]: date };
            }
            else {
                return { ...res, [key]: moment.utc(moment(date).format('YYYY-MM-DD HH:mm:ss')) };
            }
        }
        else {
            try {
                const v = JSON.parse(value);
                const formatValue = Array.isArray(v) || typeof v === 'object'
                    ? v
                    : value === 'true'
                        ? true
                        : value === 'false'
                            ? false
                            : value;
                return { ...res, [key]: formatValue };
            }
            catch (error) {
                return { ...res, [key]: value === 'true' ? true : value === 'false' ? false : value };
            }
        }
    }, {});
};
