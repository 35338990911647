import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import MaterialTextField from '@material-ui/core/TextField';

import Validatable from '~hoc/Validatable';

import compose from '~utils/compose';

class TextField extends PureComponent {
  static propTypes = {
    error: PropTypes.string,
    helperText: PropTypes.node,
    margin: PropTypes.string,
    name: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    label: PropTypes.string
  };

  static defaultProps = {
    error: null,
    margin: 'normal',
    isDisabled: false,
    isRequired: false
  };

  static muiName = 'TextField';

  render() {
    const { error, helperText, name, isDisabled, label, isRequired, ...props } = this.props;

    return (
      <MaterialTextField
        id={name}
        name={name}
        error={!!error}
        helperText={error || helperText}
        disabled={isDisabled}
        label={`${label}${isRequired ? ' *' : ''}`}
        {...props}
      />
    );
  }
}

export default compose([Validatable])(TextField);
