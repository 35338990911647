function limit(val, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = `0${val}`;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '00';
      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

function date(value) {
  const day = limit(value.substring(0, 2), '31');
  const month = limit(value.substring(2, 4), '12');
  const year = value.substring(4, 8);

  return day + (month.length ? `.${month + (year.length ? `.${year}` : '')}` : '');
}

export default { format: date, mask: ['Д', 'Д', 'М', 'М', 'Г', 'Г', 'Г', 'Г'] };
