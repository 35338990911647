import './icon.module.scss';
import React, { memo } from 'react';
import classNames from 'classnames';
export const Icon = memo(({ className, name, size }) => {
    return (<i styleName="icon" className={classNames(className, `mdi mdi-${name} mdi-${size}px`)} style={{
        height: size,
        width: size
    }}/>);
});
Icon.defaultProps = {
    size: 24
};
// @ts-ignore
Icon.muiName = 'Icon';
