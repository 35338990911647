import { useState, useCallback } from 'react';
export const useDragDrop = (store, options) => {
    const [isDragging, setDragging] = useState(false);
    const [source, setSource] = useState(null);
    const [targets, setTargets] = useState([]);
    const [sending, setSending] = useState(null);
    const [dialog, setDialog] = useState(null);
    const [isFetching, setFetching] = useState(false);
    const onDialogClose = useCallback(() => {
        setDialog(null);
        if (sending) {
            store.moveItem(sending.destinationListId, sending.sourceListId, sending.destinationListIndex, sending.sourceListIndex);
        }
        setSending(null);
        store.setNextLoading(false);
    }, [store, sending]);
    const onDialogSubmit = useCallback(() => {
        setDialog(null);
        setSending(null);
        store.setNextLoading(false);
    }, []);
    const onDragStart = useCallback(() => {
        const tabs = document.querySelector('.MuiTabs-scroller');
        if (tabs && tabs.getBoundingClientRect().top < 0) {
            tabs.scrollIntoView();
        }
    }, []);
    const onDragEnd = useCallback(async (result, provided) => {
        setTargets([]);
        setSource(null);
        setDragging(false);
        if (!result.destination) {
            return null;
        }
        if (result.source.droppableId === result.destination.droppableId) {
            return null;
        }
        const index = result.destination.droppableId.indexOf('-clone');
        const destinationId = index === -1
            ? result.destination.droppableId
            : result.destination.droppableId.substring(0, index);
        const sourceList = store.lists.find(e => e.id === result.source.droppableId);
        const destinationList = store.lists.find(e => e.id === destinationId);
        if (!sourceList || !destinationList) {
            return null;
        }
        const item = sourceList.items[result.source.index];
        if (!store.moveItem(result.source.droppableId, destinationId, result.source.index)) {
            return null;
        }
        try {
            const withData = options.withData(destinationId);
            if (withData) {
                setFetching(true);
            }
            const data = withData ? await options.getData(item) : null;
            const newDialog = options.getDialog(result.source.droppableId, destinationId, item, data);
            if (newDialog) {
                setDialog(newDialog);
                setSending({
                    itemId: result.draggableId,
                    sourceListId: result.source.droppableId,
                    sourceListIndex: result.source.index,
                    destinationListId: destinationList.id,
                    destinationListIndex: 0
                });
            }
        }
        catch (error) {
            console.error(error);
            onDialogClose();
        }
        setFetching(false);
    }, [store, sending]);
    const onMouseDown = useCallback((listId, item, onDraggable) => (event) => {
        event.persist();
        event.stopPropagation();
        const newTargets = options.getTargets(store.lists, listId, item);
        setTargets(newTargets);
        setSource(listId);
        setDragging(true);
        setTimeout(() => {
            onDraggable(event);
        });
    }, [store]);
    const onMouseDownPrevent = useCallback((event) => {
        event.stopPropagation();
    }, []);
    const onMouseUp = useCallback(() => {
        setTimeout(() => {
            setTargets([]);
            setSource(null);
            setDragging(false);
        });
    }, []);
    return {
        source,
        targets,
        isFetching,
        dialog,
        setDialog,
        isDragging,
        onDragStart,
        onDragEnd,
        onMouseDown,
        onMouseDownPrevent,
        onMouseUp,
        onDialogClose,
        onDialogSubmit
    };
};
