import referralsStore from './stores/referrals';

export default [
  {
    name: 'list',
    path: '/',
    component: () => import('./Referrals').then(m => m.default),
    onActivate: referralsStore.getInitialPage
  }
];
