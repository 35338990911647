import request from '~services/request';
export const DealsService = {
    getAll: (filterParams, page, pageSize, sort) => request
        .get('/deals', { params: { page, pageSize, sort, filterParams } })
        .then(response => response.data),
    getOne: id => request.get(`/deals/${id}`).then(response => response.data),
    create: body => request.post('/deals', body).then(response => response.data),
    update: (id, body) => request.put(`/deals/${id}`, body),
    finish: id => request.post(`/deals/${id}/finish_deal`).then(response => response.data),
    refuse: (id, body) => request.post(`/deals/${id}/refuse_deal`, body).then(response => response.data),
    archive: (id, body) => request.post(`/deals/${id}/archive`, body).then(response => response.data)
};
