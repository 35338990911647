import { createContext, useContext } from 'react';
import { FunnelsService } from '~modules/features/funnels';
import { DragDropStore } from '~modules/ui/drag-drop';
export class FunnelsEstatesStore extends DragDropStore {
    constructor() {
        super(...arguments);
        this.name = 'FUNNELS::ESTATES';
        this.service = FunnelsService.getAllEstates;
        this.serviceCount = FunnelsService.getEstatesCount;
        this.getOffsets = (lists, variant, ignoreUnimportant, ignoreActive) => {
            const ignore = (id) => {
                return !(ignoreUnimportant.includes(id) || ignoreActive.includes(id));
            };
            if (variant === 'all') {
                return [
                    lists[0].items.filter(e => ignore(e.id)).length,
                    lists[1].items.filter(e => ignore(e.id)).length,
                    lists[2].items.filter(e => ignore(e.id)).length,
                    lists[3].items.filter(e => ignore(e.id)).length,
                    lists[4].items.filter(e => ignore(e.id)).length,
                    -1,
                    -1
                ];
            }
            else if (variant === 'deal_finished') {
                return [-1, -1, -1, -1, -1, lists[5].items.length.filter(e => ignore(e.id)), -1];
            }
            return [-1, -1, -1, -1, -1, -1, lists[6].items.filter(e => ignore(e.id)).length];
        };
        this.getLists = data => {
            const result = [
                {
                    id: 'not_processed',
                    title: 'Не обработаны',
                    items: []
                },
                {
                    id: 'first_contact',
                    title: 'Первичный контакт',
                    items: []
                },
                {
                    id: 'shows',
                    title: 'Просмотры',
                    items: []
                },
                {
                    id: 'assigned_deposite_date',
                    title: 'Задаток',
                    items: []
                },
                {
                    id: 'assigned_deal_date',
                    title: 'Сделка',
                    items: []
                },
                {
                    id: 'deal_finished',
                    title: 'Сделка проведена',
                    items: []
                },
                {
                    id: 'refuse',
                    title: 'Отказ',
                    items: []
                }
            ];
            result.forEach(list => {
                list.items = data.filter(e => e.general.status === list.id);
            });
            return result;
        };
    }
}
export const funnelsEstatesStore = new FunnelsEstatesStore();
const FunnelsEstatesStoreContext = createContext(funnelsEstatesStore);
export const useFunnelsEstatesStore = () => useContext(FunnelsEstatesStoreContext);
