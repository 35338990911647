import dataStoreFactory from '~utils/data-store-factory';
import { LeadsService } from '~modules/features/leads';
import userStore from '~stores/user';

import { NEW_FIRST, NEW_LAST, PRICE_ASC, PRICE_DESC } from '~constants/types/sort';
import ALL_STATUSES, { DEAL_FINISHED } from '~constants/types/lead-status';

const allStatuses = ALL_STATUSES.map(status => status.id).filter(
  statusId => statusId !== DEAL_FINISHED
);

const Leads = dataStoreFactory(LeadsService, {
  sortOptions: [NEW_FIRST, NEW_LAST, PRICE_ASC, PRICE_DESC],
  initialFilters: () => ({
    sectionName: [],
    customer: '',
    phone: '',
    statuses: [],
    dealFinished: false,
    price: { from: '', to: '' },
    cities: [{ id: userStore.user.city.id, title: userStore.user.city.name }],
    districts: [],
    subdistricts: [],
    apartmentsTypes: [],
    commertionalObjectTypes: [],
    houseObjectTypes: [],
    landCategories: [],
    street: '',
    agent: '',
    date: null,
    dealType: '',
    my: true
  }),
  transformFilters: ({
    cities,
    districts,
    subdistricts,
    apartmentsTypes,
    commertionalObjectTypes,
    houseObjectTypes,
    landCategories,
    statuses,
    dealFinished,
    ...filters
  }) => ({
    cityIds: cities.map(city => city.id),
    districtIds: districts.map(district => district.id),
    subdistrictIds: subdistricts.map(subdistrict => subdistrict.id),
    apartmentsTypes: apartmentsTypes.map(e => e.id),
    commertionalObjectTypes: commertionalObjectTypes.map(e => e.id),
    houseObjectTypes: houseObjectTypes.map(e => e.id),
    landCategories: landCategories.map(e => e.id),
    statuses: (!statuses.length ? allStatuses : statuses)
      .concat(dealFinished ? DEAL_FINISHED : null)
      .filter(e => !!e),
    ...filters
  })
});

export default new Leads();
