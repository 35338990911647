import { useEffect, useCallback } from 'react';
import { useForm, FormGroup, FormControl, FormArray, Validators } from '~modules/forms';
import { useUser } from '~modules/hooks/use-user';
import { MAX_INTEGER } from '~constants/max-integer';
import * as SECTION_NAME from '~constants/types/section';
import { STATUS_NOT_PROCESSED } from '../leads.constants';
export const useLeadsForm = (lead, status, initialData) => {
    const { roles, user } = useUser();
    const form = useForm(() => new FormGroup({
        status: new FormControl(status, value => Validators.required(value)),
        fromThirdPartyAgent: new FormControl(lead ? lead.general.fromThirdPartyAgent : false),
        thirdPartyAgentName: new FormControl(lead ? lead.general.thirdPartyAgentName || '' : ''),
        thirdPartyAgentPhone: new FormArray(lead && lead.general.thirdPartyAgentPhone.length
            ? lead.general.thirdPartyAgentPhone.map(phone => new FormControl(phone))
            : [new FormControl('')]),
        customerName: new FormControl(lead ? lead.general.customerName || '' : '', value => Validators.required(value)),
        customerPhone: new FormArray(lead && lead.general.customerPhone.length
            ? lead.general.customerPhone.map(phone => new FormControl(phone))
            : [new FormControl('')]),
        customerEmail: new FormControl(lead ? lead.general.customerEmail || '' : '', value => Validators.email(value)),
        comment: new FormControl(lead ? lead.general.comment || '' : ''),
        price: new FormControl(lead?.general.price ?? 0, value => Validators.required(value) ||
            Validators.minValue(value, 0) ||
            Validators.maxValue(value, MAX_INTEGER)),
        hidden: new FormControl(lead ? lead.general.hidden : false),
        sectionName: new FormControl(lead ? lead.general.sectionName : []),
        propertyType: new FormControl(lead ? lead.general.propertyType : []),
        dealType: new FormControl(lead?.general.dealType || 'sale'),
        calculationForm: new FormControl(lead ? lead.general.calculationForm || '' : ''),
        commertionalObjectType: new FormControl(lead ? lead.general.commertionalObjectType : []),
        houseObjectType: new FormControl(lead ? lead.general.houseObjectType : []),
        apartmentsType: new FormControl(lead ? lead.general.apartmentsType : []),
        conditionObject: new FormControl(lead ? lead.general.conditionObject : []),
        houseType: new FormControl(lead ? lead.general.houseType : []),
        wallMaterial: new FormControl(lead ? lead.general.wallMaterial : []),
        landSquare: new FormControl(lead ? lead.general.landSquare || '' : ''),
        landCategory: new FormControl(lead ? lead.general.landCategory : []),
        fullSquare: new FormControl(lead ? lead.general.fullSquare || '' : ''),
        livingSquare: new FormControl(lead ? lead.general.livingSquare || '' : ''),
        kitchenSquare: new FormControl(lead ? lead.general.kitchenSquare || '' : ''),
        floor: new FormControl(lead ? lead.general.floor || '' : ''),
        houseStorey: new FormControl(lead ? lead.general.houseStorey || '' : ''),
        wc: new FormControl(lead ? lead.general.wc || '' : ''),
        distanceToCity: new FormControl(lead ? lead.general.distanceToCity || '' : ''),
        sewerage: new FormControl(lead ? lead.general.sewerage : null),
        gas: new FormControl(lead ? lead.general.gas : null),
        water: new FormControl(lead ? lead.general.water : null),
        electricity: new FormControl(lead ? lead.general.electricity : null),
        security: new FormControl(lead ? lead.general.security : null),
        balcony: new FormControl(lead ? lead.general.balcony : null),
        country: new FormControl(lead && lead.general.country
            ? { id: lead.general.country.id, title: lead.general.country.name }
            : user.country
                ? { id: user.country.id, title: user.country.name }
                : null),
        region: new FormControl(lead && lead.general.region
            ? { id: lead.general.region.id, title: lead.general.region.name }
            : user.country
                ? { id: user.region.id, title: user.region.name }
                : null),
        cities: new FormControl(lead
            ? lead.general.cities.map(({ id, name }) => ({ id, title: name }))
            : user.country
                ? [{ id: user.city.id, title: user.city.name }]
                : []),
        districts: new FormControl(lead ? lead.general.districts.map(({ id, name }) => ({ id, title: name })) : []),
        subdistricts: new FormControl(lead ? lead.general.subdistricts.map(({ id, name }) => ({ id, title: name })) : []),
        street: new FormControl(lead ? lead.general.street || '' : ''),
        leadSource: new FormControl(lead ? lead.general.leadSource || '' : ''),
        leadSourceOther: new FormControl(lead ? lead.general.leadSourceOther || '' : ''),
        residentialComplex: new FormControl(lead ? lead.general.residentialComplex || '' : ''),
        employee: new FormControl(initialData?.employee || null, (roles.length === 1 && roles.includes('agent')) || lead
            ? () => null
            : Validators.requiredTrue)
    }));
    useEffect(() => {
        if (form.$.status.value !== STATUS_NOT_PROCESSED) {
            form.$.sectionName.setValidator(value => Validators.required(value));
            form.$.dealType.setValidator(value => Validators.required(value));
            form.$.calculationForm.setValidator(value => Validators.required(value));
            form.$.country.setValidator(value => Validators.requiredTrue(value));
            form.$.region.setValidator(value => Validators.requiredTrue(value));
            form.$.cities.setValidator(value => Validators.required(value));
            form.$.leadSource.setValidator(value => Validators.required(value));
        }
        else {
            form.$.sectionName.removeValidator();
            form.$.dealType.removeValidator();
            form.$.calculationForm.removeValidator();
            form.$.country.removeValidator();
            form.$.region.removeValidator();
            form.$.cities.removeValidator();
            form.$.leadSource.removeValidator();
        }
    }, [form.$.status.value]);
    useEffect(() => {
        if (!form.$.sectionName.value.includes(SECTION_NAME.COMMERCIAL)) {
            form.$.dealType.setValue('sale');
        }
    }, [form.$.sectionName.value]);
    useEffect(() => {
        if (form.$.fromThirdPartyAgent.value) {
            form.$.customerPhone.$.forEach(control => {
                control.removeValidator();
            });
            form.$.thirdPartyAgentName.setValidator(value => Validators.required(value));
            form.$.thirdPartyAgentPhone.$.forEach(control => {
                control.setValidator(value => Validators.required(value));
            });
        }
        else {
            form.$.customerPhone.$.forEach(control => {
                control.setValidator(value => Validators.required(value));
            });
            form.$.thirdPartyAgentName.removeValidator();
            form.$.thirdPartyAgentPhone.$.forEach(control => {
                control.removeValidator();
            });
        }
    }, [form.$.fromThirdPartyAgent.value]);
    useEffect(() => {
        if (form.$.status.value !== STATUS_NOT_PROCESSED) {
            const sectionNames = form.$.sectionName.value;
            if (sectionNames.includes(SECTION_NAME.APARTMENT) ||
                sectionNames.includes(SECTION_NAME.HOUSE)) {
                form.$.propertyType.setValidator(value => Validators.required(value));
                form.$.livingSquare.setValidator(value => Validators.minValue(value, 1));
                form.$.kitchenSquare.setValidator(value => Validators.minValue(value, 1));
            }
            else {
                form.$.propertyType.removeValidator();
                form.$.livingSquare.removeValidator();
                form.$.kitchenSquare.removeValidator();
            }
            if (sectionNames.includes(SECTION_NAME.APARTMENT) ||
                sectionNames.includes(SECTION_NAME.COMMERCIAL)) {
                form.$.floor.setValidator(value => Validators.minValue(value, 0));
            }
            else {
                form.$.floor.removeValidator();
            }
            if (sectionNames.includes(SECTION_NAME.HOUSE) || sectionNames.includes(SECTION_NAME.STEAD)) {
                form.$.landSquare.setValidator(value => Validators.minValue(value, 1));
            }
            else {
                form.$.landSquare.removeValidator();
            }
            if (sectionNames.includes(SECTION_NAME.APARTMENT)) {
                form.$.apartmentsType.setValidator(value => Validators.required(value));
            }
            else {
                form.$.apartmentsType.removeValidator();
            }
            if (sectionNames.includes(SECTION_NAME.HOUSE)) {
                form.$.houseObjectType.setValidator(value => Validators.required(value));
            }
            else {
                form.$.houseObjectType.removeValidator();
            }
            if (sectionNames.includes(SECTION_NAME.COMMERCIAL)) {
                form.$.commertionalObjectType.setValidator(value => Validators.required(value));
            }
            else {
                form.$.commertionalObjectType.removeValidator();
            }
            if (sectionNames.includes(SECTION_NAME.STEAD)) {
                form.$.landCategory.setValidator(value => Validators.required(value));
            }
            else {
                form.$.landCategory.removeValidator();
            }
        }
        else {
            form.$.propertyType.removeValidator();
            form.$.livingSquare.removeValidator();
            form.$.kitchenSquare.removeValidator();
            form.$.floor.removeValidator();
            form.$.landSquare.removeValidator();
            form.$.apartmentsType.removeValidator();
            form.$.houseObjectType.removeValidator();
            form.$.commertionalObjectType.removeValidator();
            form.$.commertionalObjectType.removeValidator();
            form.$.landCategory.removeValidator();
        }
    }, [form.$.status.value, form.$.sectionName.value]);
    useEffect(() => {
        if (status !== STATUS_NOT_PROCESSED) {
            const sectionNames = form.$.sectionName.value;
            if (sectionNames.includes(SECTION_NAME.APARTMENT) ||
                sectionNames.includes(SECTION_NAME.HOUSE) ||
                sectionNames.includes(SECTION_NAME.COMMERCIAL)) {
                const livingSquare = form.$.livingSquare.value;
                const kitchenSquare = form.$.kitchenSquare.value;
                const min = livingSquare > 0 && kitchenSquare > 0 ? +livingSquare + +kitchenSquare : 1;
                form.$.fullSquare.setValidator(value => Validators.minValue(value, min));
            }
            else {
                form.$.fullSquare.removeValidator();
            }
        }
        else {
            form.$.fullSquare.removeValidator();
        }
    }, [form.$.sectionName.value, form.$.livingSquare.value, form.$.kitchenSquare.value]);
    useEffect(() => {
        if (form.$.status.value !== STATUS_NOT_PROCESSED) {
            const sectionNames = form.$.sectionName.value;
            if (sectionNames.includes(SECTION_NAME.APARTMENT) ||
                sectionNames.includes(SECTION_NAME.COMMERCIAL)) {
                const floor = form.$.floor.value;
                const min = floor > 0 ? +floor : 1;
                form.$.houseStorey.setValidator(value => Validators.minValue(value, min));
            }
            else if (sectionNames.includes(SECTION_NAME.HOUSE)) {
                form.$.houseStorey.setValidator(value => Validators.minValue(value, 1));
            }
            else {
                form.$.houseStorey.removeValidator();
            }
        }
        else {
            form.$.houseStorey.removeValidator();
        }
    }, [form.$.status.value, form.$.sectionName.value, form.$.floor.value]);
    useEffect(() => {
        if (form.$.status.value !== STATUS_NOT_PROCESSED && form.$.leadSource.value === 'other') {
            form.$.leadSourceOther.setValidator(value => Validators.required(value));
        }
        else {
            form.$.leadSourceOther.removeValidator();
        }
    }, [form.$.status.value, form.$.leadSource.value]);
    const appendCustomerPhone = useCallback(() => {
        form.$.customerPhone.append(new FormControl('', value => form.$.fromThirdPartyAgent.value ? null : Validators.required(value)));
    }, [form]);
    const removeCustomerPhone = useCallback((index) => () => {
        form.$.customerPhone.remove(index);
    }, [form]);
    const appendThirdPartyAgentPhone = useCallback(() => {
        form.$.thirdPartyAgentPhone.append(new FormControl('', value => form.$.fromThirdPartyAgent.value ? Validators.required(value) : null));
    }, [form]);
    const removeThirdPartyAgentPhone = useCallback((index) => () => {
        form.$.thirdPartyAgentPhone.remove(index);
    }, [form]);
    return {
        form,
        appendCustomerPhone,
        removeCustomerPhone,
        appendThirdPartyAgentPhone,
        removeThirdPartyAgentPhone
    };
};
