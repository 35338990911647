export const getFormDataSimple = (form) => {
    const data = form.toJS();
    const body = new FormData();
    body.append('estate[price]', '' + data.estate.price);
    body.append('estate[sectionName]', data.estate.sectionName);
    body.append('estate[ownerName]', data.estate.ownerName);
    body.append(`estate[ownerPhone][0]`, data.estate.ownerPhone.filter(Boolean)[0]);
    body.append('estate[street]', data.estate.street);
    body.append('estate[dealType]', data.estate.dealType || 'sale');
    if (data.estate.country) {
        body.append('estate[countryId]', '' + data.estate.country.id);
    }
    if (data.estate.employee) {
        body.append('estate[employeeId]', '' + data.estate.employee.id);
    }
    if (data.estate.region) {
        body.append('estate[regionId]', '' + data.estate.region.id);
    }
    if (data.estate.city) {
        body.append('estate[cityId]', '' + data.estate.city.id);
    }
    if (data.estate.sectionName === 'apartment') {
        body.append('section[propertyType]', data.apartment.propertyType);
        body.append('section[houseNumber]', data.apartment.houseNumber);
        body.append('section[building]', data.apartment.building);
        body.append('section[apartmentNumber]', data.apartment.apartmentNumber);
    }
    else if (data.estate.sectionName === 'house') {
        body.append('section[propertyType]', data.house.propertyType);
        body.append('section[houseNumber]', data.house.houseNumber);
    }
    else if (data.estate.sectionName === 'stead') {
        body.append('section[landCategory]', data.stead.landCategory);
        body.append('section[number]', data.stead.number);
    }
    else if (data.estate.sectionName === 'commercial') {
        body.append('section[houseNumber]', data.commercial.houseNumber);
        body.append('section[building]', `${data.commercial.building}`);
    }
    return body;
};
