import { sessionStore } from '~modules/core/session';
import userStore from '~stores/user';
import hasAccessToRoute from '~utils/has-access-to-route';
export default async (router, toState) => {
    const isAuthorized = await sessionStore.isAuthorized();
    if (isAuthorized) {
        if (userStore.isAccountFilled() || toState.name === 'account.view') {
            if (hasAccessToRoute(toState.name)) {
                return toState;
            }
            router.cancel();
            router.navigate('home.view');
            return null;
        }
        else {
            router.cancel();
            router.navigate('account.view');
            return null;
        }
    }
    else {
        router.navigate('signin.view');
        return null;
    }
};
