import request from '~services/request';
export const LeadsService = {
    getAll: ({ sectionName, ...filterParams }, page, pageSize, sort) => request
        .get('/leads', { params: { sectionName, page, pageSize, sort, filterParams } })
        .then(response => response.data),
    getOne: id => request.get(`/leads/${id}`).then(response => response.data),
    create: body => request.post('/leads', body).then(response => response.data),
    update: (id, body) => request.put(`/leads/${id}`, body).then(response => response.data),
    archive: id => request.post(`/leads/${id}/archive`),
    search: (cancelToken, params) => request.get('/leads/search', { cancelToken, params }).then(response => response.data.data
        .map(({ id, customerName, customerPhone, thirdPartyAgentPhone }) => ({
        id,
        title: `${customerName}${customerPhone
            ? ` ${customerPhone}`
            : thirdPartyAgentPhone
                ? ` ${thirdPartyAgentPhone}`
                : ''}`
    }))
        .slice(0, 100))
};
