import { ChangePasswordRoutes } from './views/change-password/change-password.routes';
import { RestorePasswordRoutes } from './views/restore-password/restore-password.routes';
import { SignInRoutes } from './views/sing-in/sign-in.routes';
export const AuthorizationRoutes = [
    {
        name: 'signin',
        path: '/signin',
        component: () => import('./authorization').then(m => m.Authorization),
        children: SignInRoutes
    },
    {
        name: 'change-password',
        path: '/change-password/:code',
        component: () => import('./authorization').then(m => m.Authorization),
        children: ChangePasswordRoutes
    },
    {
        name: 'restore-password',
        path: '/restore-password',
        component: () => import('./authorization').then(m => m.Authorization),
        children: RestorePasswordRoutes
    }
];
