export var EEstateBuildingState;
(function (EEstateBuildingState) {
    EEstateBuildingState["BUILT"] = "built";
    EEstateBuildingState["HAND_OVER"] = "hand_over";
    EEstateBuildingState["UNFINISHED"] = "unfinished";
})(EEstateBuildingState || (EEstateBuildingState = {}));
export var EEstateExport;
(function (EEstateExport) {
    EEstateExport["YANDEX_REALTY"] = "yandex_realty";
})(EEstateExport || (EEstateExport = {}));
export var EEstateExportStatus;
(function (EEstateExportStatus) {
    EEstateExportStatus["NOT_UPLOADED"] = "not_uploaded";
    EEstateExportStatus["IN_PROGRESS"] = "in_progress";
    EEstateExportStatus["UPLOADED"] = "uploaded";
    EEstateExportStatus["REJECTED"] = "rejected";
})(EEstateExportStatus || (EEstateExportStatus = {}));
