var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action, runInAction, reaction } from 'mobx';
import userStore from '~stores/user';
import { UserNamePipe } from '~modules/pipes/user-name.pipe';
import { navigationStore } from '~stores/navigation';
function debounceEffect(effect, debounceMs) {
    let timer;
    return (arg, r) => {
        clearTimeout(timer);
        timer = setTimeout(() => effect(arg, r), debounceMs);
    };
}
const isExpired = (reminder) => reminder && (reminder.reminderStatus === 'very_expired' || reminder.reminderStatus === 'expired');
export class DragDropStore {
    constructor() {
        this.name = null;
        this.getLists = () => [];
        this.getOffsets = null;
        this.lists = this.getLists([]);
        this.counts = {};
        this.ignoreImportantceChange = [];
        this.ignoreUnimportant = [];
        this.ignoreActive = [];
        this.filters = {
            search: '',
            franchises: [],
            offices: [],
            employees: [],
            exportFlags: []
        };
        this.sorting = 'only_last_update';
        this.isNextLoading = false;
        this.r1 = null;
        this.cancelData = null;
        this.cancelCount = null;
        this.clear = () => {
            if (this.r1) {
                this.r1();
                this.r1 = null;
            }
            if (this.cancelData) {
                this.cancelData.cancel();
                this.cancelData = null;
            }
            if (this.cancelCount) {
                this.cancelCount.cancel();
                this.cancelCount = null;
            }
            this.lists = this.getLists([]);
            this.ignoreImportantceChange = [];
            this.ignoreUnimportant = [];
        };
        this.getData = () => {
            const filterParams = this.getFiltersParams();
            if (this.cancelData) {
                this.cancelData.cancel();
                this.cancelData = null;
            }
            if (this.cancelCount) {
                this.cancelCount.cancel();
                this.cancelCount = null;
            }
            const [cancelData, promiseData] = this.service(this.sorting, filterParams);
            const [cancelCount, promiseCount] = this.serviceCount(filterParams);
            this.cancelData = cancelData;
            this.cancelCount = cancelCount;
            return Promise.all([promiseData, promiseCount]).then(([data, counts]) => {
                runInAction(() => {
                    this.lists = this.getLists(data);
                    this.counts = counts;
                    this.ignoreImportantceChange = [];
                    this.ignoreUnimportant = [];
                    this.ignoreActive = [];
                });
            });
        };
        this.getFiltersParams = () => {
            const { search, franchises, offices, employees, exportFlags } = this.filters;
            const roles = Object.keys(userStore.roles);
            const isAgent = !(roles.includes('owner') || roles.includes('franchisee'));
            const isFranchisee = !roles.includes('owner') && roles.includes('franchisee');
            return {
                searchValue: search,
                franchiseIds: isFranchisee && !employees.length && !franchises.length
                    ? userStore.employee.employeesFranchises.map(e => e.franchise.id)
                    : franchises.map(({ id }) => id),
                officeIds: offices.map(({ id }) => id),
                employeeIds: isAgent ? [userStore.employee.id] : employees.map(({ id }) => id),
                my: isAgent,
                exportFlags
            };
        };
        this.getEmployees = () => {
            return [{ id: userStore.employee.id, title: UserNamePipe(userStore.employee.user) || '' }];
        };
    }
    getAll() {
        const filters = localStorage.getItem(`${this.name}::FILTERS`);
        if (filters) {
            const { search, franchises, offices, employees, exportFlags } = JSON.parse(filters);
            this.filters = {
                search: search || '',
                franchises: franchises || [],
                offices: offices || [],
                employees: employees || [],
                exportFlags: exportFlags || []
            };
        }
        else {
            this.filters = {
                search: '',
                franchises: [],
                offices: [],
                employees: this.getEmployees(),
                exportFlags: []
            };
        }
        if (this.r1) {
            this.r1();
        }
        this.r1 = reaction(() => this.filters, debounceEffect(() => {
            this.getData();
        }, 400));
        const sorting = localStorage.getItem(`${this.name}::SORTING`);
        if (sorting) {
            this.sorting = sorting || 'only_last_update';
        }
        if (navigationStore.isBack) {
            return Promise.resolve();
        }
        return this.getData();
    }
    setFilters(filters) {
        this.filters = filters;
        localStorage.setItem(`${this.name}::FILTERS`, JSON.stringify(filters));
    }
    setSorting(sorting) {
        this.sorting = sorting;
        this.getData();
        localStorage.setItem(`${this.name}::SORTING`, sorting);
    }
    clearFilters() {
        const filters = {
            search: '',
            franchises: [],
            offices: [],
            employees: [],
            exportFlags: []
        };
        this.filters = filters;
        localStorage.setItem(`${this.name}::FILTERS`, JSON.stringify(filters));
    }
    setLists(lists) {
        this.lists = lists;
    }
    setItemImportance(listId, itemId, isImportant) {
        const list = this.lists.find(e => e.id === listId);
        if (list) {
            const item = list.items.find(e => e.id === itemId);
            if (item) {
                if (this.sorting === 'hot_first_and_last_update' &&
                    !this.ignoreImportantceChange.includes(item.id)) {
                    if (isImportant && this.ignoreUnimportant.includes(item.id)) {
                        this.ignoreUnimportant = this.ignoreUnimportant.filter(e => e !== item.id);
                    }
                    else if (isImportant) {
                        this.ignoreImportantceChange.push(item.id);
                    }
                    else if (list.items
                        .filter(e => !this.ignoreUnimportant.includes(e.id) && !this.ignoreActive.includes(e.id))
                        .every(e => isExpired(item.reminder)
                        ? e.general.important && isExpired(e.reminder)
                        : e.general.important || isExpired(e.reminder))) {
                        this.ignoreUnimportant.push(item.id);
                    }
                }
                item.general.important = isImportant;
                this.lists = this.lists.slice();
            }
        }
    }
    addItem(item) {
        if (this.lists.length) {
            const isEveryImportantOrExpired = this.lists[0].items
                .filter(e => !this.ignoreUnimportant.includes(e.id) && !this.ignoreActive.includes(e.id))
                .every(e => e.general.important || isExpired(e.reminder));
            this.lists[0].items.unshift(item);
            if (this.sorting === 'hot_first_and_last_update' && isEveryImportantOrExpired) {
                this.ignoreUnimportant.push(item.id);
            }
            if (this.counts[this.lists[0].id]) {
                this.counts[this.lists[0].id]++;
            }
            else {
                this.counts[this.lists[0].id] = 0;
            }
        }
    }
    moveItem(sourceListId, destinationListId, sourceItemIndex, destinationItemIndex = 0) {
        const sourceList = this.lists.find(e => e.id === sourceListId);
        const destinationList = this.lists.find(e => e.id === destinationListId);
        if (sourceList && destinationList) {
            this.isNextLoading = true;
            destinationList.items.splice(destinationItemIndex, 0, sourceList.items[sourceItemIndex]);
            const item = sourceList.items.splice(sourceItemIndex, 1)[0];
            destinationList.items[destinationItemIndex].general.status = destinationList.id;
            if (this.counts[sourceListId]) {
                this.counts[sourceListId]--;
            }
            else {
                this.counts[sourceListId] = 0;
            }
            if (this.counts[destinationListId]) {
                this.counts[destinationListId]++;
            }
            else {
                this.counts[destinationListId] = 1;
            }
            return true;
        }
        return false;
    }
    setReminder(listId, index, reminder) {
        const list = this.lists.find(e => e.id === listId);
        if (list) {
            const isEveryImportantOrExpired = list.items
                .filter(e => !this.ignoreUnimportant.includes(e.id) && !this.ignoreActive.includes(e.id))
                .every(e => e.general.important || isExpired(e.reminder));
            const isEveryExpired = list.items
                .map(e => e.reminder)
                .filter(Boolean)
                .every(e => e.reminderStatus === 'very_expired' || e.reminderStatus === 'expired');
            const item = list.items[index];
            item.reminder = reminder;
            if (isEveryExpired) {
                this.ignoreActive.push(item.id);
            }
            if (this.sorting === 'hot_first_and_last_update') {
                if (isEveryImportantOrExpired) {
                    if (!item.general.important) {
                        this.ignoreUnimportant.push(item.id);
                    }
                }
                else {
                    this.ignoreImportantceChange.push(item.id);
                }
            }
        }
    }
    setNextLoading(isNextLoading) {
        this.isNextLoading = isNextLoading;
    }
    onNext(variant) {
        if (this.isNextLoading) {
            return;
        }
        this.isNextLoading = true;
        if (this.getOffsets) {
            if (this.cancelData) {
                this.cancelData.cancel();
                this.cancelData = null;
            }
            const [cancel, promise] = this.service(this.sorting, this.getFiltersParams(), this.getOffsets(this.lists, variant, this.ignoreUnimportant, this.ignoreActive));
            this.cancelData = cancel;
            promise
                .then(data => {
                runInAction(() => {
                    data.forEach(item => {
                        const indexIgnoreActive = this.ignoreActive.indexOf(item.id);
                        if (indexIgnoreActive !== -1) {
                            this.ignoreActive.splice(indexIgnoreActive, 1);
                        }
                        if (this.sorting === 'hot_first_and_last_update') {
                            const indexIgnoreUnimportant = this.ignoreUnimportant.indexOf(item.id);
                            if (indexIgnoreUnimportant !== -1) {
                                this.ignoreImportantceChange.push(item.id);
                                this.ignoreUnimportant.splice(indexIgnoreUnimportant, 1);
                            }
                        }
                    });
                    const newLists = this.getLists(data);
                    for (let i = 0; i < this.lists.length; i++) {
                        this.lists[i].items = this.lists[i].items.concat(newLists[i].items.filter(e => !this.lists[i].items.some(g => g.id === e.id)));
                    }
                    this.isNextLoading = false;
                });
            })
                .catch(error => {
                console.error(error);
                runInAction(() => {
                    this.isNextLoading = false;
                });
            });
        }
    }
    setCommentsCount(id, count) {
        const list = this.lists.find(e => e.items.find(i => i.id === id));
        if (list) {
            const item = list.items.find(i => i.id === id);
            if (item) {
                item.commentsCount = count;
            }
        }
    }
}
__decorate([
    observable
], DragDropStore.prototype, "lists", void 0);
__decorate([
    observable
], DragDropStore.prototype, "counts", void 0);
__decorate([
    observable
], DragDropStore.prototype, "filters", void 0);
__decorate([
    observable
], DragDropStore.prototype, "sorting", void 0);
__decorate([
    observable
], DragDropStore.prototype, "isNextLoading", void 0);
__decorate([
    action.bound
], DragDropStore.prototype, "getAll", null);
__decorate([
    action.bound
], DragDropStore.prototype, "setFilters", null);
__decorate([
    action.bound
], DragDropStore.prototype, "setSorting", null);
__decorate([
    action.bound
], DragDropStore.prototype, "clearFilters", null);
__decorate([
    action.bound
], DragDropStore.prototype, "setLists", null);
__decorate([
    action.bound
], DragDropStore.prototype, "setItemImportance", null);
__decorate([
    action.bound
], DragDropStore.prototype, "addItem", null);
__decorate([
    action.bound
], DragDropStore.prototype, "moveItem", null);
__decorate([
    action.bound
], DragDropStore.prototype, "setReminder", null);
__decorate([
    action.bound
], DragDropStore.prototype, "setNextLoading", null);
__decorate([
    action.bound
], DragDropStore.prototype, "onNext", null);
__decorate([
    action.bound
], DragDropStore.prototype, "setCommentsCount", null);
