import './dropzone.module.scss';
import classNames from 'classnames';
import React, { memo, useCallback, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Icon } from '~modules/ui/icon';
import loadImage from 'blueimp-load-image';
const getImageURL = image => {
    return new Promise(resolve => {
        loadImage(image, canvas => {
            resolve(canvas.toDataURL('image/png'));
        }, {
            orientation: true,
            maxHeight: 200
        });
    });
};
export const Dropzone = memo(({ name, label, value, onChange, isMultiple, error, required }) => {
    const input = useRef(null);
    const appendImages = useCallback((files) => {
        let acceptedFiles = files.filter(file => file.type.startsWith('image/'));
        if (!isMultiple && acceptedFiles.length) {
            acceptedFiles = [acceptedFiles[0]];
        }
        Promise.all(acceptedFiles.map(file => getImageURL(file).then(url => ({ file, url })))).then(newImages => {
            onChange({
                target: {
                    name,
                    value: [...value, ...newImages]
                }
            });
        });
    }, [value, isMultiple]);
    const removeImage = useCallback((index) => () => {
        const image = value[index];
        if ('id' in image) {
            image.remove = true;
            onChange({
                target: {
                    name,
                    value: [...value]
                }
            });
        }
        else {
            onChange({
                target: {
                    name,
                    value: value.filter((_, i) => i !== index)
                }
            });
        }
    }, [value]);
    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
    }, []);
    const onDrop = useCallback((event) => {
        event.preventDefault();
        appendImages([...event.dataTransfer.files]);
    }, [appendImages]);
    const onInputChange = useCallback((event) => {
        if (event.target.files) {
            appendImages([...event.target.files]);
            if (input.current) {
                input.current.value = '';
            }
        }
    }, [appendImages]);
    return (<div styleName="dropzone">
        <Typography styleName={classNames('dropzone__label', { dropzone__label_invalid: error })} variant="body1">
          {`${label}${required ? ' *' : ''}`}
        </Typography>
        {!!value.length && (<div styleName="dropzone__images">
            {value.map((image, index) => image.remove ? (<React.Fragment key={index}/>) : (<div key={index} styleName="dropzone__image" style={{
        backgroundImage: `url(${'id' in image ? image.files.preview : image.url})`
    }}>
                  <IconButton styleName="dropzone__remove" onClick={removeImage(index)}>
                    <Icon name="close"/>
                  </IconButton>
                </div>))}
          </div>)}
        {(isMultiple || !value.length) && (<label styleName={classNames('dropzone__container', { dropzone__container_invalid: error })} onDragOver={onDragOver} onDrop={onDrop}>
            <Typography styleName="dropzone__title" variant="body2" color="primary">
              <Icon styleName="dropzone__icon" name="upload"/>
              {`Выберите файл${isMultiple ? 'ы' : ''}`}
            </Typography>
            <Typography styleName="dropzone__subtitle" variant="caption">
              или переместите сюда
            </Typography>
            <input id={name} styleName="dropzone__input" type="file" multiple={isMultiple} accept="image/*" onChange={onInputChange} ref={input}/>
          </label>)}
      </div>);
});
Dropzone.defaultProps = {
    isMultiple: false
};
