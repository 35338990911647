import './application-snackbar.module.scss';
import React, { memo } from 'react';
import { SnackbarProvider } from 'notistack';
const snackbarConfig = {
    maxSnack: 3,
    hideIconVariant: false,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
    },
    iconVariant: {
        default: (<svg style={{ marginRight: '8px' }} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5829 3.37084C13.4217 1.40576 10.5786 1.40577 9.41737 3.37085L1.67472 16.4738C0.492994 18.4736 1.9346 21 4.2575 21H19.7428C22.0657 21 23.5073 18.4736 22.3256 16.4738L14.5829 3.37084ZM11.0002 8.99998H13.0002V13H11.0002V8.99998ZM11.0002 15H13.0002V17H11.0002V15Z" fill="white"/>
      </svg>)
    }
};
export const ApplicationSnackbar = memo(({ children }) => {
    return <SnackbarProvider {...snackbarConfig}>{children}</SnackbarProvider>;
});
