export const getFormDataSimple = (form) => {
    const { dealType, status, customerName, customerPhone, country, region, cities } = form.toJS();
    const body = {
        lead: {
            dealType: dealType || 'sale',
            status,
            countryId: country?.id || null,
            regionId: region?.id || null,
            cities: cities.map(city => city.id),
            customerName,
            customerPhone: [customerPhone[0]]
        }
    };
    return body;
};
