import './drag-drop-columns.module.scss';
import React, { memo, useRef, useCallback, useEffect } from 'react';
const getX = (event) => {
    if (event.pageX) {
        return event.pageX;
    }
    return 0;
};
export const DragDropColumns = memo(({ children }) => {
    const container = useRef(null);
    const sx = useRef(0);
    const mx = useRef(0);
    const isMoving = useRef(false);
    const onClick = useCallback((event) => {
        event.stopPropagation();
    }, []);
    const onMouseMove = useCallback((event) => {
        if (isMoving.current && container.current) {
            const mx2 = getX(event) - container.current.offsetLeft;
            if (mx.current) {
                container.current.scrollLeft = sx.current + mx.current - mx2;
            }
        }
    }, []);
    const onMouseDown = useCallback((event) => {
        if (container.current) {
            sx.current = container.current.scrollLeft;
            mx.current = getX(event) - container.current.offsetLeft;
            isMoving.current = true;
        }
    }, []);
    const onMouseUp = useCallback(() => {
        mx.current = 0;
        isMoving.current = false;
    }, []);
    useEffect(() => {
        document.addEventListener('mouseup', onMouseUp);
        document.addEventListener('mousemove', onMouseMove);
        return () => {
            document.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('mousemove', onMouseMove);
        };
    }, [onMouseUp, onMouseMove]);
    return (<div id="scroll-x-restore" styleName="columns" onClick={onClick} onMouseDown={onMouseDown} onMouseUp={onMouseUp} ref={container}>
      {children}
    </div>);
});
