import './estates-form-dialog.module.scss';
import React, { memo, useState, useCallback, useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { EstatesService } from '~modules/features/estates';
import { UserNamePipe } from '~modules/pipes/user-name.pipe';
import { useUserStore } from '~stores/user';
import { getFormData } from '../estates-form';
import Spinner from '~modules/ui/spinner';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import { EstatesFormDetailed } from '../estates-form-detailed';
export const EstatesFormDialog = memo(({ isOpen, onClose, onSubmit, estate, ...props }) => {
    const userStore = useUserStore();
    const initialData = useMemo(() => {
        const roles = Object.keys(userStore.roles);
        return {
            employee: !estate && roles.length > 1 && roles.includes('agent')
                ? {
                    id: userStore.employee.id,
                    title: UserNamePipe(userStore.employee.user, true)
                }
                : undefined
        };
    }, []);
    const [isSending, setSending] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const onCloseSnack = key => (<Button style={{ color: '#F48FB1' }} onClick={() => {
        closeSnackbar(key);
    }}>
        OK
      </Button>);
    const onFormSubmit = useCallback(async (form) => {
        setSending(true);
        const formData = getFormData(form);
        try {
            const response = await (estate
                ? EstatesService.update(estate.id, formData)
                : EstatesService.create(formData));
            onSubmit(response);
        }
        catch (error) {
            enqueueSnackbar('Произошла ошибка. Повторите попытку', {
                variant: 'default',
                action: onCloseSnack
            });
        }
        setSending(false);
    }, [onSubmit]);
    return (<>
        <Spinner isSending={isSending}/>
        <Dialog keepMounted={false} open={isOpen} scroll="body" fullWidth maxWidth="sm">
          <DialogTitle>Объект</DialogTitle>
          <DialogContent styleName="dialog__content">
            <EstatesFormDetailed estate={estate} status={props.status} initialData={initialData} onCancel={onClose} onSubmit={onFormSubmit} isSending={isSending}/>
          </DialogContent>
        </Dialog>
      </>);
});
