import axios from 'axios';
const request = axios.create({
    baseURL: process.env.DADATA_API_BASE_URL,
    headers: {
        Authorization: `Token ${process.env.DADATA_API_KEY}`
    }
});
export const AutocompleteFromDadataService = {
    address: (cancelToken, params) => request.post('/address', params, { cancelToken }).then(response => response.data.suggestions)
};
