import { createContext, useContext } from 'react';

import dataStoreFactory from '~utils/data-store-factory';
import { UploadsLeadsService } from '~modules/features/uploads';
import userStore from '~stores/user';

import FILTERS from '~constants/upload-leads/filters';
import { NEW_FIRST, NEW_LAST, PRICE_ASC, PRICE_DESC } from '~constants/types/sort';

const transformDistricts = value => {
  const districts = [];

  value.forEach(v => {
    const isSubdistict = !!v.parent;
    const district = isSubdistict ? v.parent : v;
    const e = districts.find(e => e.id === district.id);

    if (e) {
      if (isSubdistict) {
        e.subdistrictIds.push(v.id);
      }
    } else {
      const newDistrict = {
        id: district.id,
        subdistrictIds: []
      };

      if (isSubdistict) {
        newDistrict.subdistrictIds.push(v.id);
      }

      districts.push(newDistrict);
    }
  });

  return districts;
};

const UploadLeads = dataStoreFactory(UploadsLeadsService, {
  sortOptions: [NEW_FIRST, NEW_LAST, PRICE_ASC, PRICE_DESC],
  initialFilters: params => ({
    sectionName: params.sectionName || 'apartment',
    ...FILTERS[params.sectionName || 'apartment'],
    cities: [{ id: userStore.user.city.id, title: userStore.user.city.name }]
  }),
  transformFilters: ({ cities, districts, ...filters }) => {
    return {
      cityIds: cities.map(e => e.id),
      districts: transformDistricts(districts),
      ...filters
    };
  }
});

const uploadLeadsStore = new UploadLeads();

export default uploadLeadsStore;

export const UploadLeadsStoreContext = createContext(uploadLeadsStore);

export const useUploadLeadsStore = () => {
  const uploadLeadsStore = useContext(UploadLeadsStoreContext);
  return uploadLeadsStore;
};
