import './reminders-form.module.scss';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormattedTextField, { TimeFormatter } from '~ui/FormattedTextField';
import { AutocompleteFromAPI } from '~modules/ui/autocomplete-from-api';
import { DatePicker } from '~modules/ui/date-picker';
import { observer } from 'mobx-react-lite';
import { useForm, FormGroup, FormControl, Validators } from '~modules/forms';
import { useUser } from '~modules/hooks/use-user';
import moment from 'moment';
import { DatePipe } from '~modules/pipes/date.pipe';
import { EstatesService } from '~modules/features/estates';
import { LeadsService } from '~modules/features/leads';
import { EstateNamePipe } from '~modules/pipes/estate-name.pipe';
const STATUSES = ['not_processed', 'first_contact', 'interested', 'shows', 'active'];
const momentRequied = (date) => {
    if (!date) {
        return 'Это поле обязательно для заполнения';
    }
    if (!date.isValid()) {
        return 'Некорректная дата';
    }
    return null;
};
const momentMin = (date) => {
    if (date.diff(new Date(), 'days') >= 0) {
        return null;
    }
    return `Не может быть раньше ${DatePipe(new Date().toUTCString(), 'DD.MM.YYYY')}`;
};
const minDateTime = (date, time) => {
    const hours = time.substring(0, 2);
    const minutes = time.substring(2, 4);
    const d = moment.utc(date).local();
    d.set('hours', +hours);
    d.set('minutes', +minutes);
    return d.diff(new Date(), 'minutes') < 0
        ? `Не может быть раньше ${DatePipe(new Date().toUTCString(), 'DD.MM.YYYY HH:mm')}`
        : null;
};
const getClient = (variant, client, t) => {
    if (variant === 'estate') {
        const estate = client;
        return {
            id: estate.id,
            title: `${EstateNamePipe({
                general: {
                    sectionName: estate.general.sectionName
                },
                section: estate.section
            })}, ${(estate.general.subdistrict ? estate.general.subdistrict.name : null) ||
                (estate.general.district ? estate.general.district.name : null) ||
                (estate.general.city ? estate.general.city.name : null) ||
                ''} (№ ${estate.id})`
        };
    }
    const lead = client;
    return {
        id: lead.id,
        title: `${lead.general.customerName}${lead.general.customerPhone
            ? ` ${lead.general.customerPhone}`
            : lead.general.thirdPartyAgentPhone
                ? ` ${lead.general.thirdPartyAgentPhone}`
                : ''}`
    };
};
export const RemindersForm = memo(observer(({ variant, reminder, client, withClient, withStatus, isSending, onClose, onSubmit }) => {
    const { t } = useTranslation('translations');
    const { employee } = useUser();
    const form = useForm(() => new FormGroup({
        status: new FormControl('', value => (withStatus ? Validators.required(value) : null)),
        client: new FormControl(client ? getClient(variant, client, t) : null, value => withClient ? Validators.requiredTrue(value) : null),
        message: new FormControl(reminder ? reminder.message : '', value => Validators.required(value)),
        deadlineDay: new FormControl(reminder
            ? moment.utc(moment(reminder.deadlineTime).format('YYYY-MM-DD HH:mm:ss'))
            : null, value => momentRequied(value) || momentMin(value)),
        deadlineTime: new FormControl(reminder ? DatePipe(reminder.deadlineTime, 'HHmm') : '', value => Validators.required(value) ||
            Validators.pattern(value, /^\d{4}$/) ||
            minDateTime(form.$.deadlineDay.value, value))
    }));
    const onFormSubmit = useCallback((event) => {
        event.preventDefault();
        if (form.validate()) {
            const { status, client, message, deadlineDay, deadlineTime } = form.toJS();
            const hours = deadlineTime.substring(0, 2);
            const minutes = deadlineTime.substring(2, 4);
            const deadlineDate = moment.utc(deadlineDay).local();
            deadlineDate.set('hours', +hours);
            deadlineDate.set('minutes', +minutes);
            const data = { status, client, message, deadlineTime: deadlineDate.toISOString() };
            onSubmit(data);
        }
    }, []);
    const options = useMemo(() => {
        if (!client) {
            return [];
        }
        const statuses = variant === 'estate'
            ? ['not_processed', 'first_contact', 'shows']
            : ['not_processed', 'first_contact', 'interested', 'shows'];
        const status = client.general.status;
        if (['assigned_deposite_date', 'assigned_deal_date', 'deal_finished'].includes(status)) {
            return [];
        }
        const index = statuses.indexOf(status);
        return statuses.slice(index === -1 ? 0 : index + 1);
    }, [variant, client]);
    return (<form styleName="form" onSubmit={onFormSubmit}>
          {withStatus && (<>
              <Typography variant="h5" gutterBottom>
                Перенести в новый статус
              </Typography>
              <TextField variant="outlined" margin="normal" label="Статус" select {...form.$.status.input} error={!!form.$.status.error} helperText={form.$.status.error}>
                {options.map(option => (<MenuItem key={option} value={option}>
                    {t(`${variant}.status.${option}`)}
                  </MenuItem>))}
              </TextField>
            </>)}

          <Typography styleName="form__title" variant={withStatus ? 'h6' : 'h5'} gutterBottom>
            {reminder ? 'Редактировать' : 'Создать'} напоминание
          </Typography>

          {withClient && (<AutocompleteFromAPI variant="outlined" margin="normal" api={variant === 'estate' ? EstatesService.search : LeadsService.search} params={id => ({ id, employeeId: employee.id, statuses: STATUSES })} label={variant === 'estate' ? 'Объект' : 'Покупатель'} isClearable={false} disabled={!!client} {...form.$.client.input} error={form.$.client.error}/>)}

          <DatePicker variant="outlined" margin="normal" label="Дата" placeholder="ДД.ММ.ГГГГ" disablePast keyboard {...form.$.deadlineDay.input} error={form.$.deadlineDay.error}/>

          <FormattedTextField variant="outlined" margin="normal" formatter={TimeFormatter} label="Время" placeholder="ЧЧ:ММ" {...form.$.deadlineTime.input} error={form.$.deadlineTime.error}/>

          <TextField variant="outlined" margin="normal" label="Описание" placeholder="Минимальная информация, требуемая для следующего контакта" multiline {...form.$.message.input} error={!!form.$.message.error} helperText={form.$.message.error}/>

          <div styleName="form__buttons">
            <Button styleName="form__button" type="button" variant="outlined" color="primary" size="large" disabled={isSending} onClick={onClose}>
              Отмена
            </Button>
            <Button styleName="form__button" type="submit" variant="contained" color="primary" size="large" disabled={isSending}>
              Сохранить
            </Button>
          </div>
        </form>);
}));
RemindersForm.defaultProps = {
    withClient: true,
    withStatus: false,
    isSending: false
};
