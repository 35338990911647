import './error-boundary-fallback.module.scss';
import React, { memo, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import { Button } from '~modules/ui/button';
export const ErrorBoundaryFallback = memo(() => {
    const onReload = useCallback(() => {
        window.location.reload();
    }, []);
    return (<div styleName="fallback">
      <Typography variant="h5" gutterBottom>
        Что-то пошло не так
      </Typography>
      <Typography styleName="fallback__text" variant="body1">
        Попробуйте перезагрузить страницу
      </Typography>
      <Button variant="contained" color="primary" size="large" onClick={onReload}>
        Перезагрузить
      </Button>
    </div>);
});
