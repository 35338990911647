import { uploadsPermissionsEmployeesStore } from './share/stores/uploads-permissions-employees.store';
import { uploadsPermissionsFranchisesStore } from './share/stores/uploads-permissions-franchises.store';
import { uploadsPermissionsOfficesStore } from './share/stores/uploads-permissions-offices.store';
export const UploadsPermissionsRoutes = [
    {
        name: 'list',
        path: '/',
        component: () => import('./views/uploads-permissions-list').then(m => m.UploadsPermissionsList),
        onActivate: params => Promise.all([
            uploadsPermissionsEmployeesStore.getInitialPage(params),
            uploadsPermissionsFranchisesStore.getInitialPage(params),
            uploadsPermissionsOfficesStore.getInitialPage(params)
        ])
    },
    {
        name: 'new',
        path: '/new',
        component: () => import('./views/uploads-permissions-form').then(m => m.UploadsPermissionsForm)
    }
];
