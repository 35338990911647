import './DoubleSelect.module.scss';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Select } from '~modules/ui/select';

export default class DoubleSelect extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any.isRequired
      })
    ).isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.node.isRequired,
        id: PropTypes.any.isRequired,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.node.isRequired,
            id: PropTypes.any.isRequired
          })
        )
      })
    ).isRequired,
    onChange: PropTypes.func.isRequired
  };

  onChange = event => {
    const value = event.target.value;

    const { name, onChange } = this.props;
    onChange({ target: { name, value } });
  };

  getValue = () => {
    const { value } = this.props;
    return value;
  };

  render() {
    /* eslint-disable no-unused-vars */
    const { value, onChange, ...props } = this.props;
    /* eslint-enable no-unused-vars */

    return <Select isTrackable value={this.getValue()} onChange={this.onChange} {...props} />;
  }
}
