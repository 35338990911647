import { DealsService } from '~modules/features/deals';
import DealsGuard from './Deals.guard';
import dealsStore from './stores/deals';

export default [
  {
    name: 'list',
    path: '/',
    component: () => import('./views/List').then(m => m.default),
    onActivate: dealsStore.getInitialPage
  },
  {
    name: 'show',
    path: '/:id',
    component: () => import('./views/Show').then(m => m.default),
    onActivate: ({ id }) => DealsService.getOne(id)
  },
  {
    name: 'edit',
    path: '/:id/edit',
    component: () => import('./views/Edit').then(m => m.default),
    onActivate: ({ id }) => DealsService.getOne(id)
  },
  {
    name: 'new',
    path: '/new',
    component: () => import('./views/Form').then(m => m.default),
    beforeActivate: DealsGuard
  }
];
