import React from 'react';
import { withSnackbar } from 'notistack';
import { PropTypes } from 'prop-types';
import { observer, inject } from 'mobx-react';

import IconButton from '@material-ui/core/IconButton';
import { Icon } from '~modules/ui/icon';

import compose from '~utils/compose';

class Notifier extends React.Component {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    layoutStore: PropTypes.object.isRequired
  };

  componentDidUpdate() {
    const {
      layoutStore: {
        notify: { message, ...notifyProps }
      },
      enqueueSnackbar
    } = this.props;

    if (!notifyProps?.action) {
      notifyProps.action = (
        <IconButton color="inherit">
          <Icon name="close" />
        </IconButton>
      );
    }

    enqueueSnackbar(message, notifyProps);
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {
      layoutStore: { notify }
    } = this.props;
    /* eslint-enable no-unused-vars */

    return null;
  }
}

export default compose([withSnackbar, inject('layoutStore'), observer])(Notifier);
