import { calendarStore } from '~stores/calendar';
const date = new Date();
export default [
    {
        name: 'view',
        path: '/',
        component: () => import('./Calendar').then(m => m.default),
        onActivate: () => calendarStore.getEvents(date)
    }
];
