import request from '~services/request';

export const UploadsService = {
  getAll: (filterParams, page, pageSize, sort) =>
    request
      .get('/uploads', { params: { page, pageSize, sort, filterParams } })
      .then(response => response.data),
  create: body =>
    request
      .post('/uploads', body, {
        'Content-Type': 'multipart/form-data'
      })
      .then(response => response.data),
  createWithFile: body =>
    request
      .post('/uploads/file', body, {
        'Content-Type': 'multipart/form-data'
      })
      .then(response => response.data)
};
