var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, runInAction } from 'mobx';
import { createContext } from 'react';
import { ConfigService } from '~modules/features/config';
export class ConfigStore {
    constructor() {
        this.hideHiddenDeals = false;
        this.isLoaded = false;
        this.getConfig = async () => {
            if (this.isLoaded) {
                return;
            }
            try {
                const result = await ConfigService.getOne();
                runInAction(() => {
                    this.isLoaded = true;
                    this.hideHiddenDeals = result.config.hideHiddenDeals;
                });
            }
            catch (error) {
                //
            }
        };
        this.setConfig = async (data) => {
            const result = await ConfigService.update(data);
            this.hideHiddenDeals = result.config.hideHiddenDeals;
        };
    }
}
__decorate([
    observable
], ConfigStore.prototype, "hideHiddenDeals", void 0);
export const configStore = new ConfigStore();
export const ConfigStoreContext = createContext(configStore);
