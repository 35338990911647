import { useEffect, useCallback, useMemo } from 'react';
import { useForm, FormGroup, FormControl, FormArray, Validators } from '~modules/forms';
import { useUser } from '~modules/hooks/use-user';
import { MAX_INTEGER } from '~constants/max-integer';
import { EEstateExport } from '../estates.types';
import { SECTION_COMMERCIAL, STATUS_NOT_PROCESSED, STATUS_SHOWS } from '../estates.constants';
const notRoom = (value) => {
    if (value !== 'room') {
        return null;
    }
    return 'Нельзя выгрузить комнату в новостройке';
};
const notRent = (value) => {
    if (value !== 'rent') {
        return null;
    }
    return 'Нельзя выгрузить тип сделки "Аренда"';
};
const notExclusive = (value) => {
    if (value !== 'exclusive') {
        return null;
    }
    return 'Нельзя выгрузить эксклюзивный объект';
};
export const useEstatesForm = (estate, status, exportTo, initialData) => {
    const { employee, user, roles } = useUser();
    const form = useForm(() => new FormGroup({
        estate: new FormGroup({
            status: new FormControl(status, value => Validators.required(value)),
            sectionName: new FormControl(estate ? estate.general.sectionName || '' : initialData?.section || '', value => Validators.required(value)),
            dealType: new FormControl(estate?.general.dealType || '', value => Validators.required(value)),
            adType: new FormControl(estate?.general.adType || ''),
            price: new FormControl(estate?.general.price || '', value => Validators.required(value) ||
                Validators.minValue(value, 10000) ||
                Validators.maxValue(value, MAX_INTEGER)),
            cadastralNumber: new FormControl(estate ? estate.general.cadastralNumber || '' : ''),
            sewerage: new FormControl(estate ? estate.general.sewerage || false : false),
            gas: new FormControl(estate ? estate.general.gas || false : false),
            water: new FormControl(estate ? estate.general.water || false : false),
            electricity: new FormControl(estate ? estate.general.electricity || false : false),
            internet: new FormControl(estate ? estate.general.internet || false : false),
            security: new FormControl(estate ? estate.general.security || false : false),
            country: new FormControl(estate && estate.general.country
                ? { id: estate.general.country.id, title: estate.general.country.name }
                : user.country
                    ? { id: user.country.id, title: user.country.name }
                    : null),
            region: new FormControl(estate && estate.general.region
                ? { id: estate.general.region.id, title: estate.general.region.name }
                : user.country
                    ? { id: user.region.id, title: user.region.name }
                    : null),
            city: new FormControl(estate && estate.general.city
                ? { id: estate.general.city.id, title: estate.general.city.name }
                : user.country
                    ? { id: user.city.id, title: user.city.name }
                    : null),
            district: new FormControl(estate && estate.general.district
                ? { id: estate.general.district.id, title: estate.general.district.name }
                : null),
            subdistrict: new FormControl(estate && estate.general.subdistrict
                ? { id: estate.general.subdistrict.id, title: estate.general.subdistrict.name }
                : null),
            street: new FormControl(estate ? estate.general.street || '' : ''),
            description: new FormControl(estate ? estate.general.description || '' : ''),
            comment: new FormControl(estate ? estate.general.comment || '' : ''),
            ownerName: new FormControl(estate ? estate.general.ownerName || '' : '', value => estate && estate.general.fromThirdPartyAgent ? null : Validators.required(value)),
            ownerPhone: new FormArray(estate && estate.general.ownerPhone.length
                ? estate.general.ownerPhone.map(phone => new FormControl(phone, value => estate && estate.general.fromThirdPartyAgent
                    ? null
                    : Validators.required(value)))
                : [
                    new FormControl('', value => estate && estate.general.fromThirdPartyAgent ? null : Validators.required(value))
                ]),
            ownerEmail: new FormControl(estate ? estate.general.ownerEmail || '' : '', value => (estate && estate.general.fromThirdPartyAgent ? null : Validators.email(value))),
            fromThirdPartyAgent: new FormControl(estate ? estate.general.fromThirdPartyAgent : false),
            thirdPartyAgentName: new FormControl(estate ? estate.general.thirdPartyAgentName || '' : '', value => estate && estate.general.fromThirdPartyAgent ? Validators.required(value) : null),
            thirdPartyAgentPhone: new FormArray(estate && estate.general.thirdPartyAgentPhone.length
                ? estate.general.thirdPartyAgentPhone.map(phone => new FormControl(phone, value => estate && estate.general.fromThirdPartyAgent
                    ? Validators.required(value)
                    : null))
                : [
                    new FormControl('', value => estate && estate.general.fromThirdPartyAgent ? Validators.required(value) : null)
                ]),
            photos: new FormControl(estate ? estate.general.photos : []),
            stretchMarks: new FormControl(estate ? estate.general.stretchMarks : []),
            estateContracts: new FormControl(estate ? estate.general.estateContracts : []),
            link3d: new FormControl(estate ? estate.general.link3d || '' : '', value => Validators.maxLength(value, 255)),
            linkYoutube: new FormControl(estate ? estate.general.linkYoutube || '' : '', value => Validators.maxLength(value, 255)),
            closedTerritory: new FormControl(estate ? estate.general.closedTerritory || false : false),
            undergroundParking: new FormControl(estate ? estate.general.undergroundParking || false : false),
            developer: new FormControl(estate ? estate.general.developer || '' : ''),
            employee: new FormControl(initialData?.employee || (roles.length === 1 && roles.includes('agent'))
                ? { id: employee.id, title: '' }
                : null, (roles.length === 1 && roles.includes('agent')) || estate
                ? () => null
                : Validators.requiredTrue)
        }),
        apartment: new FormGroup({
            typeObject: new FormControl(estate ? estate.section.typeObject || '' : '', value => Validators.required(value)),
            propertyType: new FormControl(estate ? estate.section.propertyType || '' : '', value => Validators.required(value)),
            houseNumber: new FormControl(estate ? estate.section.houseNumber || '' : ''),
            houseNumberUploading: new FormControl(estate ? estate.section.houseNumberUploading || '' : ''),
            building: new FormControl(estate ? estate.section.building || '' : ''),
            apartmentNumber: new FormControl(estate ? estate.section.apartmentNumber || '' : ''),
            floor: new FormControl(estate ? estate.section.floor || '' : ''),
            houseStorey: new FormControl(estate ? estate.section.houseStorey || '' : ''),
            fullSquare: new FormControl(estate ? estate.section.fullSquare || '' : ''),
            livingSquare: new FormControl(estate ? estate.section.livingSquare || '' : ''),
            kitchenSquare: new FormControl(estate ? estate.section.kitchenSquare || '' : ''),
            conditionObject: new FormControl(estate ? estate.section.conditionObject || '' : ''),
            houseType: new FormControl(estate ? estate.section.houseType || '' : ''),
            constructionYear: new FormControl(estate ? estate.section.constructionYear || '' : '', value => Validators.required(value) ||
                Validators.minValue(value, 0) ||
                Validators.maxValue(value, 3000)),
            wc: new FormControl(estate ? estate.section.wc || '' : ''),
            balcony: new FormControl(estate ? estate.section.balcony || false : false),
            loggia: new FormControl(estate ? estate.section.loggia || false : false),
            mortgage: new FormControl(estate ? estate.section.mortgage || false : false),
            buildingState: new FormControl(estate ? estate.section.buildingState || '' : ''),
            constructionQuater: new FormControl(estate ? estate.section.constructionQuater || '' : '')
        }),
        house: new FormGroup({
            typeObject: new FormControl(estate ? estate.section.typeObject || '' : '', value => Validators.required(value)),
            propertyType: new FormControl(estate ? estate.section.propertyType || '' : '', value => Validators.required(value)),
            houseNumber: new FormControl(estate ? estate.section.houseNumber || '' : ''),
            houseStorey: new FormControl(estate ? estate.section.houseStorey || '' : ''),
            houseNumberUploading: new FormControl(estate ? estate.section.houseNumberUploading || '' : ''),
            roomsAmount: new FormControl(estate ? estate.section.roomsAmount || '' : ''),
            fullSquare: new FormControl(estate ? estate.section.fullSquare || '' : ''),
            livingSquare: new FormControl(estate ? estate.section.livingSquare || '' : ''),
            kitchenSquare: new FormControl(estate ? estate.section.kitchenSquare || '' : ''),
            landSquare: new FormControl(estate ? estate.section.landSquare || '' : ''),
            conditionObject: new FormControl(estate ? estate.section.conditionObject || '' : ''),
            wallMaterial: new FormControl(estate ? estate.section.wallMaterial || '' : ''),
            distanceToCity: new FormControl(estate ? estate.section.distanceToCity || '' : ''),
            constructionYear: new FormControl(estate ? estate.section.constructionYear || '' : '', value => Validators.required(value) ||
                Validators.minValue(value, 0) ||
                Validators.maxValue(value, 3000)),
            wc: new FormControl(estate ? estate.section.wc || '' : ''),
            mortgage: new FormControl(estate ? estate.section.mortgage || false : false),
            buildingState: new FormControl(estate ? estate.section.buildingState || '' : ''),
            constructionQuater: new FormControl(estate ? estate.section.constructionQuater || '' : '')
        }),
        stead: new FormGroup({
            landCategory: new FormControl(estate ? estate.section.landCategory || '' : '', value => Validators.required(value)),
            number: new FormControl(estate ? estate.section.number || '' : ''),
            landSquare: new FormControl(estate ? estate.section.landSquare || '' : ''),
            distanceToCity: new FormControl(estate ? estate.section.distanceToCity || '' : '')
        }),
        commercial: new FormGroup({
            typeObject: new FormControl(estate ? estate.section.typeObject || '' : '', value => Validators.required(value)),
            houseNumber: new FormControl(estate ? estate.section.houseNumber || '' : ''),
            houseNumberUploading: new FormControl(estate ? estate.section.houseNumberUploading || '' : ''),
            building: new FormControl(estate ? estate.section.building || '' : ''),
            fullSquare: new FormControl(estate ? estate.section.fullSquare || '' : ''),
            conditionObject: new FormControl(estate ? estate.section.conditionObject || '' : ''),
            floor: new FormControl(estate ? estate.section.floor || '' : ''),
            houseStorey: new FormControl(estate ? estate.section.houseStorey || '' : '')
        })
    }));
    const requiredMine = useMemo(() => {
        const e = form.$.estate.$.employee.value;
        return (value) => estate || (e && e.id === employee.id) ? Validators.required(value) : null;
    }, [form.$.estate.$.employee.value]);
    const isRequiredMine = useMemo(() => {
        return !!requiredMine('');
    }, [requiredMine]);
    useEffect(() => {
        if (form.$.estate.$.sectionName.value !== SECTION_COMMERCIAL) {
            form.$.estate.$.dealType.setValue('sale');
        }
    }, [form.$.estate.$.sectionName.value]);
    useEffect(() => {
        if (form.$.estate.$.status.value !== STATUS_NOT_PROCESSED) {
            form.$.estate.$.adType.setValidator(value => Validators.required(value));
            form.$.estate.$.country.setValidator(value => Validators.requiredTrue(value));
            form.$.estate.$.region.setValidator(value => Validators.requiredTrue(value));
            form.$.estate.$.city.setValidator(value => Validators.requiredTrue(value));
            form.$.estate.$.street.setValidator(value => Validators.required(value));
            form.$.estate.$.description.setValidator(value => requiredMine(value));
            form.$.apartment.$.houseNumber.setValidator(value => Validators.required(value));
            form.$.apartment.$.houseNumberUploading.setValidator(value => requiredMine(value));
            form.$.apartment.$.apartmentNumber.setValidator(value => Validators.required(value));
            form.$.apartment.$.floor.setValidator(value => requiredMine(value));
            form.$.apartment.$.houseStorey.setValidator(value => requiredMine(value));
            form.$.apartment.$.livingSquare.setValidator(value => requiredMine(value) || Validators.minValue(value, 1));
            form.$.apartment.$.kitchenSquare.setValidator(value => requiredMine(value) || Validators.minValue(value, 1));
            form.$.apartment.$.conditionObject.setValidator(value => requiredMine(value));
            form.$.apartment.$.houseType.setValidator(value => requiredMine(value));
            form.$.apartment.$.constructionYear.setValidator(value => requiredMine(value) || Validators.minValue(value, 0) || Validators.maxValue(value, 3000));
            form.$.apartment.$.wc.setValidator(value => requiredMine(value));
            form.$.house.$.houseNumber.setValidator(value => Validators.required(value));
            form.$.house.$.houseStorey.setValidator(value => requiredMine(value));
            form.$.house.$.houseNumberUploading.setValidator(value => requiredMine(value));
            form.$.house.$.roomsAmount.setValidator(value => requiredMine(value));
            form.$.house.$.livingSquare.setValidator(value => requiredMine(value) || Validators.minValue(value, 1));
            form.$.house.$.kitchenSquare.setValidator(value => requiredMine(value) || Validators.minValue(value, 1));
            form.$.house.$.landSquare.setValidator(value => requiredMine(value) || Validators.minValue(value, 1));
            form.$.house.$.conditionObject.setValidator(value => requiredMine(value));
            form.$.house.$.constructionYear.setValidator(value => requiredMine(value) || Validators.minValue(value, 0) || Validators.maxValue(value, 3000));
            form.$.house.$.wc.setValidator(value => requiredMine(value));
            form.$.stead.$.number.setValidator(value => Validators.required(value));
            form.$.stead.$.landSquare.setValidator(value => requiredMine(value) || Validators.minValue(value, 1));
            form.$.commercial.$.houseNumber.setValidator(value => Validators.required(value));
            form.$.commercial.$.houseNumberUploading.setValidator(value => requiredMine(value));
            form.$.commercial.$.fullSquare.setValidator(value => requiredMine(value) || Validators.minValue(value, 1));
            form.$.commercial.$.conditionObject.setValidator(value => requiredMine(value));
        }
        else {
            form.$.estate.$.adType.removeValidator();
            form.$.estate.$.country.removeValidator();
            form.$.estate.$.region.removeValidator();
            form.$.estate.$.city.removeValidator();
            form.$.estate.$.district.removeValidator();
            form.$.estate.$.subdistrict.removeValidator();
            form.$.estate.$.street.removeValidator();
            form.$.estate.$.description.removeValidator();
            form.$.apartment.$.houseNumber.removeValidator();
            form.$.apartment.$.houseNumberUploading.removeValidator();
            form.$.apartment.$.apartmentNumber.removeValidator();
            form.$.apartment.$.floor.removeValidator();
            form.$.apartment.$.houseStorey.removeValidator();
            form.$.apartment.$.livingSquare.setValidator(value => Validators.minValue(value, 1));
            form.$.apartment.$.kitchenSquare.setValidator(value => Validators.minValue(value, 1));
            form.$.apartment.$.conditionObject.removeValidator();
            form.$.apartment.$.houseType.removeValidator();
            form.$.apartment.$.constructionYear.setValidator(value => Validators.minValue(value, 0) || Validators.maxValue(value, 3000));
            form.$.apartment.$.wc.removeValidator();
            form.$.house.$.houseNumber.removeValidator();
            form.$.house.$.houseStorey.removeValidator();
            form.$.house.$.houseNumberUploading.removeValidator();
            form.$.house.$.roomsAmount.removeValidator();
            form.$.house.$.livingSquare.setValidator(value => Validators.minValue(value, 1));
            form.$.house.$.kitchenSquare.setValidator(value => Validators.minValue(value, 1));
            form.$.house.$.landSquare.setValidator(value => Validators.minValue(value, 1));
            form.$.house.$.conditionObject.removeValidator();
            form.$.house.$.constructionYear.setValidator(value => Validators.minValue(value, 0) || Validators.maxValue(value, 3000));
            form.$.house.$.wc.removeValidator();
            form.$.stead.$.number.removeValidator();
            form.$.stead.$.landSquare.setValidator(value => Validators.minValue(value, 1));
            form.$.commercial.$.houseNumber.removeValidator();
            form.$.commercial.$.houseNumberUploading.removeValidator();
            form.$.commercial.$.fullSquare.setValidator(value => Validators.minValue(value, 1));
            form.$.commercial.$.conditionObject.removeValidator();
        }
        if (form.$.estate.$.status.value === STATUS_SHOWS) {
            form.$.estate.$.photos.setValidator(value => requiredMine(value));
        }
        else {
            form.$.estate.$.photos.removeValidator();
        }
    }, [form.$.estate.$.status.value, requiredMine]);
    useEffect(() => {
        if (exportTo === EEstateExport.YANDEX_REALTY) {
            form.$.estate.$.country.setValidator(value => Validators.requiredTrue(value));
            form.$.estate.$.region.setValidator(value => Validators.requiredTrue(value));
            form.$.estate.$.city.setValidator(value => Validators.requiredTrue(value));
            form.$.estate.$.street.setValidator(value => Validators.required(value));
            form.$.estate.$.dealType.setValidator(value => Validators.required(value) || notRent(value));
            // form.$.estate.$.adType.setValidator(
            //   value => Validators.required(value) || notExclusive(value)
            // );
            form.$.estate.$.adType.setValidator(value => Validators.required(value));
            form.$.estate.$.description.setValidator(value => Validators.required(value));
            form.$.estate.$.photos.setValidator(value => Validators.required(value));
            // Apartment
            form.$.apartment.$.building.setValidator(value => Validators.required(value));
            form.$.apartment.$.houseType.setValidator(value => Validators.required(value));
            form.$.apartment.$.conditionObject.setValidator(value => Validators.required(value));
            form.$.apartment.$.houseNumberUploading.setValidator(value => Validators.required(value));
            form.$.apartment.$.livingSquare.setValidator(value => Validators.required(value) || Validators.minValue(value, 1));
            form.$.apartment.$.houseStorey.setValidator(value => Validators.required(value));
            form.$.apartment.$.floor.setValidator(value => Validators.required(value));
            form.$.apartment.$.constructionYear.setValidator(value => Validators.required(value) ||
                Validators.minValue(value, 0) ||
                Validators.maxValue(value, 3000));
            form.$.apartment.$.wc.setValidator(value => Validators.required(value));
            form.$.apartment.$.livingSquare.setValidator(value => Validators.required(value) || Validators.minValue(value, 1));
            form.$.apartment.$.kitchenSquare.setValidator(value => Validators.required(value) || Validators.minValue(value, 1));
            if (form.$.apartment.$.propertyType.value === 'new_building' ||
                form.$.apartment.$.propertyType.value === 'shareholder') {
                form.$.apartment.$.constructionQuater.setValidator(value => Validators.required(value));
                form.$.apartment.$.buildingState.setValidator(value => Validators.required(value));
                form.$.apartment.$.typeObject.setValidator(value => Validators.required(value) || notRoom(value));
            }
            else {
                form.$.apartment.$.constructionQuater.removeValidator();
                form.$.apartment.$.buildingState.removeValidator();
                form.$.apartment.$.typeObject.setValidator(value => Validators.required(value));
            }
            // House
            form.$.house.$.conditionObject.setValidator(value => Validators.required(value));
            form.$.house.$.houseNumberUploading.setValidator(value => Validators.required(value));
            form.$.house.$.livingSquare.setValidator(value => Validators.required(value) || Validators.minValue(value, 1));
            form.$.house.$.houseStorey.setValidator(value => Validators.required(value));
            form.$.house.$.constructionYear.setValidator(value => Validators.required(value) ||
                Validators.minValue(value, 0) ||
                Validators.maxValue(value, 3000));
            form.$.house.$.wc.setValidator(value => Validators.required(value));
            form.$.house.$.livingSquare.setValidator(value => Validators.required(value) || Validators.minValue(value, 1));
            form.$.house.$.kitchenSquare.setValidator(value => Validators.required(value) || Validators.minValue(value, 1));
            if (form.$.house.$.propertyType.value === 'new_building' ||
                form.$.house.$.propertyType.value === 'shareholder') {
                form.$.house.$.constructionQuater.setValidator(value => Validators.required(value));
                form.$.house.$.buildingState.setValidator(value => Validators.required(value));
            }
            else {
                form.$.house.$.constructionQuater.removeValidator();
                form.$.house.$.buildingState.removeValidator();
            }
            // Stead
            form.$.stead.$.number.setValidator(value => Validators.required(value));
            form.$.stead.$.landSquare.setValidator(value => Validators.required(value) || Validators.minValue(value, 1));
            // Commercial
            form.$.commercial.$.houseNumberUploading.setValidator(value => Validators.required(value));
            form.$.commercial.$.fullSquare.setValidator(value => Validators.required(value) || Validators.minValue(value, 1));
            form.$.commercial.$.floor.setValidator(value => Validators.required(value));
            form.$.commercial.$.conditionObject.setValidator(value => Validators.required(value));
        }
    }, [
        form.$.estate.$.sectionName.value,
        form.$.apartment.$.propertyType.value,
        form.$.house.$.propertyType.value
    ]);
    useEffect(() => {
        if (form.$.estate.$.fromThirdPartyAgent.value) {
            form.$.estate.$.ownerName.removeValidator();
            form.$.estate.$.ownerPhone.$.forEach(control => {
                control.removeValidator();
            });
            form.$.estate.$.thirdPartyAgentName.setValidator(value => Validators.required(value));
            form.$.estate.$.thirdPartyAgentPhone.$.forEach(control => {
                control.setValidator(value => Validators.required(value));
            });
        }
        else {
            form.$.estate.$.ownerName.setValidator(value => Validators.required(value));
            form.$.estate.$.ownerPhone.$.forEach(control => {
                control.setValidator(value => Validators.required(value));
            });
            form.$.estate.$.thirdPartyAgentName.removeValidator();
            form.$.estate.$.thirdPartyAgentPhone.$.forEach(control => {
                control.removeValidator();
            });
        }
    }, [form.$.estate.$.fromThirdPartyAgent.value]);
    useEffect(() => {
        const livingSquare = form.$.apartment.$.livingSquare.value;
        const kitchenSquare = form.$.apartment.$.kitchenSquare.value;
        const minFullSquare = livingSquare && kitchenSquare ? +livingSquare + +kitchenSquare : 1;
        const required = value => exportTo === EEstateExport.YANDEX_REALTY
            ? Validators.required(value)
            : form.$.estate.$.status.value !== STATUS_NOT_PROCESSED
                ? requiredMine(value)
                : null;
        form.$.apartment.$.fullSquare.setValidator(value => required(value) || Validators.minValue(value, minFullSquare));
    }, [
        form.$.estate.$.status.value,
        form.$.apartment.$.livingSquare.value,
        form.$.apartment.$.kitchenSquare.value,
        requiredMine
    ]);
    useEffect(() => {
        const livingSquare = form.$.house.$.livingSquare.value;
        const kitchenSquare = form.$.house.$.kitchenSquare.value;
        const minFullSquare = livingSquare && kitchenSquare ? +livingSquare + +kitchenSquare : 1;
        const required = value => exportTo === EEstateExport.YANDEX_REALTY
            ? Validators.required(value)
            : form.$.estate.$.status.value !== STATUS_NOT_PROCESSED
                ? requiredMine(value)
                : null;
        form.$.house.$.fullSquare.setValidator(value => required(value) || Validators.minValue(value, minFullSquare));
    }, [
        form.$.estate.$.status.value,
        form.$.house.$.livingSquare.value,
        form.$.house.$.kitchenSquare.value,
        requiredMine
    ]);
    useEffect(() => {
        if (form.$.estate.$.adType.value === 'exclusive') {
            form.$.estate.$.estateContracts.setValidator(value => Validators.required(value));
        }
        else {
            form.$.estate.$.estateContracts.removeValidator();
        }
    }, [form.$.estate.$.adType.value]);
    useEffect(() => {
        if (form.$.estate.$.status.value === STATUS_NOT_PROCESSED) {
            form.$.apartment.$.typeObject.removeValidator();
            form.$.house.$.typeObject.removeValidator();
            form.$.stead.$.landCategory.removeValidator();
            form.$.commercial.$.typeObject.removeValidator();
        }
        else {
            form.$.apartment.$.typeObject.setValidator(value => Validators.required(value));
            form.$.house.$.typeObject.setValidator(value => Validators.required(value));
            form.$.stead.$.landCategory.setValidator(value => Validators.required(value));
            form.$.commercial.$.typeObject.setValidator(value => Validators.required(value));
        }
    }, [form.$.estate.$.status.value]);
    const appendOwnerPhone = useCallback(() => {
        form.$.estate.$.ownerPhone.append(new FormControl('', value => form.$.estate.$.fromThirdPartyAgent.value ? null : Validators.required(value)));
    }, [form]);
    const removeOwnerPhone = useCallback((index) => () => {
        form.$.estate.$.ownerPhone.remove(index);
    }, [form]);
    const appendThirdPartyAgentPhone = useCallback(() => {
        form.$.estate.$.thirdPartyAgentPhone.append(new FormControl('', value => form.$.estate.$.fromThirdPartyAgent.value ? Validators.required(value) : null));
    }, [form]);
    const removeThirdPartyAgentPhone = useCallback((index) => () => {
        form.$.estate.$.thirdPartyAgentPhone.remove(index);
    }, [form]);
    return {
        form,
        isRequiredMine,
        appendOwnerPhone,
        removeOwnerPhone,
        appendThirdPartyAgentPhone,
        removeThirdPartyAgentPhone
    };
};
