import { reportsStore } from './pages/reports-list/pages/reports-list-agent/reports-list-agent.store';
import ReportsGuard from './Reports.guard';
const Reports = [
    {
        name: 'agent',
        path: '/agent',
        component: () => import('./pages/reports-list/pages/reports-list-agent').then(m => m.default),
        onActivate: reportsStore.getInitialPage,
        afterActivate: ReportsGuard
    }
];
export default Reports;
