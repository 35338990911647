var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action, runInAction, reaction } from 'mobx';
import { createContext, useContext } from 'react';
import { FunnelsService } from '~modules/features/funnels';
import userStore from '~stores/user';
import moment from 'moment';
function debounceEffect(effect, debounceMs) {
    let timer;
    return (arg, r) => {
        clearTimeout(timer);
        timer = setTimeout(() => effect(arg, r), debounceMs);
    };
}
const dateStartDefault = moment
    .utc()
    .startOf('month')
    .toISOString();
const dateEndDefault = moment
    .utc()
    .endOf('month')
    .startOf('day')
    .toISOString();
const NAME = 'FUNNELS::AGENTS';
export class FunnelsAgentsStore {
    constructor() {
        this.count = 0;
        this.data = [];
        this.page = 0;
        this.pageSize = 20;
        this.filters = {
            client: 'estates',
            franchises: [],
            offices: [],
            employees: [],
            dateStart: dateStartDefault,
            dateEnd: dateEndDefault
        };
        this.appliedFilters = {
            client: 'estates',
            franchises: [],
            offices: [],
            employees: [],
            dateStart: dateStartDefault,
            dateEnd: dateEndDefault
        };
        this.isLoading = false;
        this.r1 = null;
        this.cancel = null;
        this.clear = () => {
            if (this.r1) {
                this.r1();
                this.r1 = null;
            }
            if (this.cancel) {
                this.cancel.cancel();
                this.cancel = null;
            }
            this.data = [];
        };
        this.getData = () => {
            runInAction(() => {
                this.isLoading = true;
            });
            const service = this.filters.client === 'estates'
                ? FunnelsService.getAllAgentsEstates
                : FunnelsService.getAllAgentsLeads;
            const appliedFilters = this.filters;
            if (this.cancel) {
                this.cancel.cancel();
                this.cancel = null;
            }
            const [cancel, promise] = service(this.getFiltersParams(), this.page, this.pageSize);
            this.cancel = cancel;
            return promise
                .then(({ data, totalEntries }) => {
                runInAction(() => {
                    this.data = data;
                    this.count = totalEntries;
                    this.isLoading = false;
                    this.appliedFilters = appliedFilters;
                });
            })
                .catch(() => {
                runInAction(() => {
                    this.isLoading = false;
                });
            });
        };
        this.getFiltersParams = () => {
            const { franchises, offices, employees, dateStart, dateEnd } = this.filters;
            const roles = Object.keys(userStore.roles);
            const isFranchisee = !roles.includes('owner') && roles.includes('franchisee');
            return {
                franchiseIds: isFranchisee && !employees.length && !franchises.length
                    ? userStore.employee.employeesFranchises.map(e => e.franchise.id)
                    : franchises.map(({ id }) => id),
                officeIds: offices.map(({ id }) => id),
                employeeIds: employees.map(({ id }) => id),
                dateStart,
                dateEnd
            };
        };
        this.toProps = () => {
            const { count, data, isLoading, pageSize, page, onPageChange, onPageSizeChange } = this;
            return {
                count,
                data,
                isLoading,
                pageSize,
                page,
                onPageChange,
                onPageSizeChange
            };
        };
    }
    getAll() {
        const filters = localStorage.getItem(`${NAME}::FILTERS`);
        if (filters) {
            const { client, franchises, offices, employees, dateStart, dateEnd } = JSON.parse(filters);
            this.filters = {
                client: client || 'estates',
                franchises: franchises || [],
                offices: offices || [],
                employees: employees || [],
                dateStart: dateStart || dateStartDefault,
                dateEnd: dateEnd || dateEndDefault
            };
        }
        else {
            this.filters = {
                client: 'estates',
                franchises: [],
                offices: [],
                employees: [],
                dateStart: dateStartDefault,
                dateEnd: dateEndDefault
            };
        }
        this.appliedFilters = this.filters;
        if (this.r1) {
            this.r1();
        }
        this.r1 = reaction(() => this.filters, debounceEffect(() => {
            this.page = 0;
            this.getData();
        }, 200));
        return this.getData();
    }
    setFilters(filters) {
        this.filters = { ...this.filters, ...filters };
        localStorage.setItem(`${NAME}::FILTERS`, JSON.stringify(filters));
    }
    onTablePrevPage() {
        this.page -= 1;
        this.getData();
    }
    onTableNextPage() {
        this.page += 1;
        this.getData();
    }
    onPageChange(page) {
        this.page = page;
        this.getData();
    }
    onPageSizeChange(pageSize) {
        this.pageSize = pageSize;
        this.page = 0;
        this.getData();
    }
}
__decorate([
    observable
], FunnelsAgentsStore.prototype, "count", void 0);
__decorate([
    observable
], FunnelsAgentsStore.prototype, "data", void 0);
__decorate([
    observable
], FunnelsAgentsStore.prototype, "page", void 0);
__decorate([
    observable
], FunnelsAgentsStore.prototype, "pageSize", void 0);
__decorate([
    observable
], FunnelsAgentsStore.prototype, "filters", void 0);
__decorate([
    observable
], FunnelsAgentsStore.prototype, "appliedFilters", void 0);
__decorate([
    observable
], FunnelsAgentsStore.prototype, "isLoading", void 0);
__decorate([
    action.bound
], FunnelsAgentsStore.prototype, "getAll", null);
__decorate([
    action.bound
], FunnelsAgentsStore.prototype, "setFilters", null);
__decorate([
    action.bound
], FunnelsAgentsStore.prototype, "onTablePrevPage", null);
__decorate([
    action.bound
], FunnelsAgentsStore.prototype, "onTableNextPage", null);
__decorate([
    action.bound
], FunnelsAgentsStore.prototype, "onPageChange", null);
__decorate([
    action.bound
], FunnelsAgentsStore.prototype, "onPageSizeChange", null);
export const funnelsAgentsStore = new FunnelsAgentsStore();
const FunnelsAgentsStoreContext = createContext(funnelsAgentsStore);
export const useFunnelsAgentsStore = () => useContext(FunnelsAgentsStoreContext);
