import { default as createRouter5 } from 'router5';
import loggerPlugin from 'router5-plugin-logger';
import browserPlugin from 'router5-plugin-browser';
import listenersPlugin from 'router5-plugin-listeners';
import layoutStore from '~stores/layout';
import { navigationStore } from '~stores/navigation';
import routes from '../application/application.routes';
const guardMiddleWareFactory = (event, routes) => (router) => async (toState) => {
    const guards = [];
    let route = null;
    for (const segment of toState.name.split('.')) {
        route = ((route && route.children) || routes).find(r => r.name === segment);
        if (route && route[event]) {
            guards.push(route[event]);
        }
    }
    if (guards.length) {
        let result = toState;
        for (const guard of guards) {
            if (result) {
                result = await guard(router, toState);
            }
        }
        return result;
    }
    return true;
};
const dataMiddlewareFactory = routes => () => (toState) => {
    if (toState.name) {
        layoutStore.setLoading(true);
        let components = [];
        let data = [];
        let route = null;
        for (const segment of toState.name.split('.')) {
            route = ((route && route.children) || routes).find(r => r.name === segment);
            if (route) {
                components.push(route.component);
                data.push(route.onActivate);
            }
        }
        components = components.filter(Boolean).map(component => Promise.resolve(component()).catch(error => {
            console.error(error);
            return () => {
                throw new Error(error);
            };
        }));
        data = data.filter(Boolean).map(onActivate => onActivate(toState.params).catch(error => {
            return null;
        }));
        return Promise.all([Promise.all(components), Promise.all(data)])
            .then(([components, data]) => {
            layoutStore.setLoading(false);
            data = data.filter(Boolean);
            return {
                ...toState,
                components,
                data: data[data.length - 1]
            };
        })
            .catch(error => {
            layoutStore.setLoading(false);
        });
    }
    return true;
};
export function createRouter(routes) {
    const router = createRouter5(routes, {
        allowNotFound: true,
        queryParamsMode: 'loose'
    });
    router.usePlugin(loggerPlugin);
    router.usePlugin(browserPlugin({
        useHash: false
    }));
    router.usePlugin(listenersPlugin());
    router.useMiddleware(guardMiddleWareFactory('beforeActivate', routes));
    router.useMiddleware(dataMiddlewareFactory(routes));
    router.useMiddleware(guardMiddleWareFactory('afterActivate', routes));
    router.useMiddleware(navigationStore.middleware);
    router.start();
    return router;
}
export const router = createRouter(routes);
