import { EmployeesService } from '~modules/features/employees';
import employeesStore from './stores/employees';

export default [
  {
    name: 'list',
    path: '/',
    component: () => import('./views/List').then(m => m.default),
    onActivate: employeesStore.getInitialPage
  },
  {
    name: 'edit',
    path: '/:id/edit',
    component: () => import('./views/employees-form').then(m => m.default),
    onActivate: ({ id }) => EmployeesService.getOne(id)
  },
  {
    name: 'new',
    path: '/new',
    component: () => import('./views/employees-form').then(m => m.default)
  }
];
