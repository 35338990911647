import request from '~services/request';
import generateEstateName from '~utils/@transfer/generate-estate-name';

export const TransfersEstatesService = {
  getAll: (
    { sectionName, transferStatuses: statuses, my, ...estateParams },
    page,
    pageSize,
    sort
  ) =>
    request
      .get('/transfers/estates', {
        params: { filterParams: { sectionName, statuses, my }, page, pageSize, sort, estateParams }
      })
      .then(response => response.data),
  search: (cancelToken, params) => {
    const { searchValue } = params;
    const filterSearch = {
      my: true,
      id: searchValue,
      noActiveTransfers: true,
      statuses: ['not_processed', 'first_contact', 'shows', 'active']
    };
    return request.get('/estates/search', { cancelToken, params: filterSearch }).then(response =>
      response.data.data.map(({ section, id, maxAvailableTransferPercent, ...general }) => ({
        title: `${generateEstateName({ ...general, ...section })} (№${id})`,
        id: id,
        maxAvailableTransferPercent
      }))
    );
  },
  getOne: id => request.get(`/transfers/estates/${id}`).then(response => response.data),
  create: body => request.post('/transfers/estates', body).then(response => response.data),
  update: (id, body) => request.put(`/transfers/estates/${id}`, { params: body }),
  archive: id => request.post(`/transfers/estates/${id}/archive`),
  complete: id => request.post(`/transfers/estates/${id}/take_transfer`),
  getByEstate: estateId =>
    request
      .get('/transfers/get_transfer_by_estate', { params: { estateId } })
      .then(response => response.data.data)
};
