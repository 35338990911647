import request from '~services/request';
import { UserNamePipe } from '~modules/pipes/user-name.pipe';

function transformIndexResponse(data) {
  data.data.forEach(employee => {
    employee.employeesOffices.forEach(office => {
      const franchise = office.office.franchise;
      if (!employee.employeesFranchises.find(e => e.franchise.id === franchise.id)) {
        employee.employeesFranchises.push({ franchise, roles: [] });
      }
    });
  });

  return data;
}

function transformShowResponse(data) {
  data.roles = data.roles.map(role => ({
    role,
    franchises: [],
    offices: [],
    cities: []
  }));

  data.employeesFranchises.forEach(franchise => {
    franchise.roles.forEach(role => {
      const e = data.roles.find(e => e.role === role);
      if (e) {
        e.franchises.push(franchise.franchise);
      } else {
        data.roles.push({
          role,
          franchises: [franchise.franchise],
          offices: [],
          cities: []
        });
      }
    });
  });

  data.employeesOffices.forEach(office => {
    office.roles.forEach(role => {
      const e = data.roles.find(e => e.role === role);
      if (e) {
        e.offices.push(office.office);
      } else {
        data.roles.push({
          role,
          franchises: [],
          offices: [office.office],
          cities: []
        });
      }
    });
  });

  if (data.employeesCities) {
    data.employeesCities.forEach(city => {
      city.roles.forEach(role => {
        const e = data.roles.find(e => e.role === role);
        if (e) {
          e.cities.push({
            id: city.city.id,
            title: city.city.name
          });
        } else {
          data.roles.push({
            role,
            franchises: [],
            offices: [],
            cities: [
              {
                id: city.city.id,
                title: city.city.name
              }
            ]
          });
        }
      });
    });
  }

  return data;
}

function transformRoles(roles) {
  const employeesOffices = [];
  const employeesFranchises = [];
  const employeesCities = [];

  roles.forEach(({ role, offices, franchises, cities }) => {
    offices.forEach(office => {
      const e = employeesOffices.find(e => e.officeId === office.id);
      if (e) {
        e.roles.push(role);
      } else {
        employeesOffices.push({
          officeId: office.id,
          roles: [role]
        });
      }
    });

    franchises.forEach(franchise => {
      const e = employeesFranchises.find(e => e.franchiseId === franchise.id);
      if (e) {
        e.roles.push(role);
      } else {
        employeesFranchises.push({
          franchiseId: franchise.id,
          roles: [role]
        });
      }
    });

    cities.forEach(city => {
      const e = employeesCities.find(e => e.cityId === city.id);
      if (e) {
        e.roles.push(role);
      } else {
        employeesCities.push({
          cityId: city.id,
          roles: [role]
        });
      }
    });
  });

  return [
    roles
      .map(role => role.role)
      .filter(role => role === 'admin' || role === 'owner' || role === 'upload_lead_manager'),
    employeesOffices,
    employeesFranchises,
    employeesCities
  ];
}

function transformCreateRequest(data) {
  const {
    lastName,
    firstName,
    middleName,
    countryId,
    regionId,
    cityId,
    email,
    agentPosition,
    startPercent,
    parentEmployee,
    inGoldenList,
    lawyerPersonalPercent,
    lawyerDepartmentPercent
  } = data;

  const [roles, employeesOffices, employeesFranchises, employeesCities] = transformRoles(
    data.roles
  );

  const body = {
    employee: {
      agentPosition,
      roles,
      employeesOffices,
      employeesFranchises,
      employeesCities,
      inGoldenList
    },
    user: {
      lastName,
      firstName,
      middleName,
      countryId,
      regionId,
      cityId,
      email: `${email}`.toLowerCase(),
      password: 'testtest'
    },
    parentEmployeeId: parentEmployee && parentEmployee.id
  };

  if (startPercent && data.roles.find(v => v.role === 'agent') && agentPosition !== 'trainee') {
    body.employee.startPercent = startPercent;
  }

  if (data.roles.find(v => v.role === 'lawyer')) {
    body.employee.lawyerPersonalPercent = +lawyerPersonalPercent;
    body.employee.lawyerDepartmentPercent = +lawyerDepartmentPercent;
  }

  return body;
}

function transformUpdateRequest(data) {
  const [roles, employeesOffices, employeesFranchises, employeesCities] = transformRoles(
    data.roles
  );

  const body = {
    employee: {
      agentPosition: data.agentPosition,
      inGoldenList: data.inGoldenList,
      roles,
      employeesOffices,
      employeesFranchises,
      employeesCities
    }
  };

  if (
    data.startPercent &&
    data.roles.find(v => v.role === 'agent') &&
    data.agentPosition !== 'trainee'
  ) {
    body.employee.startPercent = data.startPercent;
  }

  if (data.roles.find(v => v.role === 'lawyer')) {
    body.employee.lawyerDepartmentPercent = +data.lawyerDepartmentPercent;
    body.employee.lawyerPersonalPercent = +data.lawyerPersonalPercent;
  }

  return body;
}

export const EmployeesService = {
  getAll: (filterParams, page, pageSize, sort) =>
    request
      .get('/employees', { params: { filterParams, page, pageSize, sort } })
      .then(response => transformIndexResponse(response.data)),
  getOne: id =>
    request.get(`/employees/${id}`).then(response => transformShowResponse(response.data)),
  create: body => request.post('/employees', transformCreateRequest(body)),
  update: (id, body) => request.put(`/employees/${id}`, transformUpdateRequest(body)),
  updateEmail: (id, email) => request.put('/employees/update_email', { id, email }),
  search: (cancelToken, params) =>
    request.get('/employees/search', { cancelToken, params }).then(response =>
      response.data.data.map(e => ({
        id: e.id,
        title: `${UserNamePipe(e.user, true)}${e.archivedAt ? ' (архивированный)' : ''}`,
        inGoldenList: e.inGoldenList,
        user: e.user
      }))
    ),
  searchFunnels: (cancelToken, params) =>
    request
      .get('/funnel_params/employees', {
        cancelToken,
        params
      })
      .then(response =>
        response.data.data.map(e => ({
          id: e.id,
          title: UserNamePipe(e.user, true)
        }))
      ),
  archive: id => request.post(`/employees/${id}/archive`),
  promote: id => request.post(`/employees/${id}/promote_to_partner`)
};
