/* tslint:disable */
// prettier-ignore
export default {
    // Просмотр календаря
    'calendar.view': ['agent', 'lawyer', 'dispatcher', 'cashier', 'admin', 'franchisee', 'city', 'owner', 'irs'],
    // Просмотр объектов (хозяин объекта - в полном виде, остальные - в ограниченном)
    // Просмотр отчетов
    'reports.view': ['agent', 'cashier', , 'admin', 'franchisee', 'city', 'owner', 'irs'],
    // Просмотр всех отчетов агентов
    'reports.view-admin': ['admin', 'owner'],
    // Просмотр своих отчетов агентов
    'reports.view-agent': ['agent'],
    // Просмотр отчетов агентов своего оффиса
    'reports.view-office': [],
    // Просмотр отчетов агентов своей франшизы
    'reports.view-franchise': ['cashier', 'franchisee'],
    // Просмотр отчетов агентов своего города
    'reports.view-city': [],
    'estates.view': ['agent', 'dispatcher', 'cashier', 'office_manager', 'admin', 'franchisee', 'city', 'owner', 'irs', 'upload_lead_manager'],
    // Добавление и редактирование (своих) объектов
    'estates.new': ['agent', 'dispatcher', 'office_manager', 'owner', 'irs', 'upload_lead_manager'],
    // Отправка и восстановление в/из архива (своих)
    'estates.archive': ['agent', 'office_manager', 'upload_lead_manager'],
    // Отправка и восстановление в/из архива любых
    'estates.archive-every': ['admin', 'owner'],
    // Просмотр адреса объекта, не будучи владельцем объекта
    'estate.show_address': ['dispatcher'],
    // Просмотр покупателей (покупателя объекта - в полном виде, остальные - в ограниченном)
    'leads.view': ['agent', 'dispatcher', 'cashier', 'office_manager', 'admin', 'franchisee', 'city', 'owner', 'irs', 'upload_lead_manager'],
    // Добавление и редактирование (своих) покупателей
    'leads.new': ['agent', 'dispatcher', 'office_manager', 'owner', 'irs', 'upload_lead_manager'],
    // Отправка и восстановление в/из архива (своих)
    'leads.archive': ['agent', 'office_manager', 'upload_lead_manager'],
    // Отправка и восстановление в/из архива любых
    'leads.archive-every': ['admin', 'owner'],
    // Просмотр сделок
    'deals.view': ['agent', 'lawyer', 'dispatcher', 'cashier', 'franchisee', 'city', 'owner'],
    // Создавать сделки
    'deals.new': ['dispatcher', 'owner'],
    // Возможность редактировать по статусам
    'deals.edit.deal_created': ['agent', 'lawyer', 'dispatcher', 'admin', 'owner'],
    'deals.edit.assigned_deposite_date': ['agent', 'lawyer', 'dispatcher', 'admin', 'owner'],
    'deals.edit.assigned_deal_date': ['lawyer', 'dispatcher', 'owner'],
    'deals.edit.deal_finished': [],
    'deals.vrk': ['lawyer'],
    'deals.edit.refuse': [],
    // Возможность ставить отказ по статусам
    'deals.refuse.deal_created': ['agent', 'dispatcher', 'admin', 'owner'],
    'deals.refuse.assigned_deposite_date': ['agent', 'dispatcher', 'admin', 'owner'],
    // TODO: убрать dispatcher как только сузятся его права (фича 310)
    'deals.refuse.assigned_deal_date': ['lawyer', 'dispatcher', 'owner'],
    'deals.refuse.deal_finished': [],
    'deals.refuse.refuse': [],
    // Заполнять поля Юридическое сопровождение и расходы, Проводить задаток и закрывать сделку
    // TODO: убрать dispatcher как только сузятся его права (фича 310)
    'deals.edit-lawyer': ['lawyer', 'dispatcher'],
    // Просмотр списка базы собственников
    'uploads.view': ['agent', 'office_manager', 'admin', 'franchisee', 'city', 'owner', 'upload_lead_manager'],
    // База собственников объектов, поиск и редактирование дублей
    'uploads.edit': ['owner', 'upload_lead_manager'],
    // Возможность забрать объект из базы собственников к себе
    'uploads.accept': ['agent', 'owner'],
    // Настройка доступов к разделу база собственников
    'uploads.permissions': ['admin', 'owner', 'upload_lead_manager'],
    // Просмотр справочника по районам
    'map.view': ['agent', 'lawyer', 'dispatcher', 'cashier', 'office_manager', 'admin', 'franchisee', 'city', 'owner', 'irs', 'upload_lead_manager'],
    // Просмотр клиентов на бирже
    'transfers.view': ['agent', 'dispatcher', 'cashier', 'office_manager', 'admin', 'franchisee', 'city', 'owner', 'upload_lead_manager'],
    // Передача клиентов на биржу
    'transfers.create': ['agent'],
    // Передача клиента лично агенту
    'transfers.create-target': ['agent'],
    // Принимать передачу
    'transfers.accept': ['agent'],
    // Отправка и восстановление в/из архива (своих)
    'transfers.archive': ['agent'],
    // Отправка и восстановление в/из архива любых
    'transfers.archive-every': ['admin', 'owner'],
    // Просмотр списка всех сотрудников
    'employees.view': ['agent', 'lawyer', 'dispatcher', 'cashier', 'office_manager', 'admin', 'franchisee', 'city', 'owner', 'upload_lead_manager'],
    // Создание, редактирование и удаление пользователей
    'employees.new': ['admin', 'owner'],
    // Повышение статуса до партнера
    'employees.promote': ['office_manager', 'franchisee', 'owner', 'upload_lead_manager'],
    'employees.metrika': ['owner'],
    // Просмотр списка всех франшиз
    'franchises.view': ['agent', 'lawyer', 'dispatcher', 'cashier', 'office_manager', 'admin', 'franchisee', 'city', 'owner', 'upload_lead_manager'],
    // Создание, редактирование и удаление новых франшиз
    'franchises.new': ['admin', 'owner'],
    // Просмотр списка всех офисов
    'offices.view': ['agent', 'lawyer', 'dispatcher', 'cashier', 'office_manager', 'admin', 'franchisee', 'city', 'owner', 'upload_lead_manager'],
    // Создание, редактирование и удаление офисов
    'offices.new': ['admin', 'owner'],
    // Просмотр списка рефералов агента
    'referrals.view': ['agent', 'cashier', 'office_manager', 'admin', 'franchisee', 'city', 'owner', 'upload_lead_manager'],
    // Напоминания
    'reminders.view': ['agent', 'franchisee', 'owner'],
    // Воронка
    'funnels.view': ['agent', 'franchisee', 'owner'],
    // Воронка для всех агентов
    'funnels.view.all': ['franchisee', 'owner'],
};
/* tslint:enable */
/*
  agent - Агент
  lawyer - Юрист
  dispatcher - Диспетчер
  cahier - Кассир
  office_manager - Менеджер офиса
  admin - Администратор
  franchisee - Управляющий франшизой
  onwer - Владелец компании
  upload lead manager - Менеджер по базе собственников
*/
