import userStore from '~stores/user';
export default async (router, toState) => {
    const roles = Object.keys(userStore.roles);
    router.cancel();
    if (roles.includes('lawyer')) {
        router.navigate('deals.list');
    }
    else {
        router.navigate('estates.list');
    }
    return null;
};
