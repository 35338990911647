import './drag-drop-item.module.scss';
import React, { forwardRef } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Link } from '~modules/ui/link';
export const DragDropItem = forwardRef(({ children, variant, routeName, routeId, ...props }, ref) => {
    return (<Card styleName={`item item_variant_${variant}`} ref={ref} {...props}>
      {!!(routeName && routeId) ? (<CardActionArea styleName="item__padding" component={Link} routeName={routeName} routeParams={{ id: routeId }}>
          {children}
        </CardActionArea>) : (<div styleName="item__padding">{children}</div>)}
    </Card>);
});
DragDropItem.defaultProps = {
    variant: 'default'
};
