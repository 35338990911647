import { UploadsService } from '~modules/features/uploads';
export const UploadsGuard = async (router, toState) => {
    try {
        await UploadsService.getAll({}, 1, 1, 'asdsad');
        return toState;
    }
    catch (error) {
        if (error.response.status === 403) {
            router.cancel();
            router.navigate('uploads.forbidden');
            return null;
        }
    }
    return toState;
};
