export default [
    'other',
    'cian',
    'avito',
    'yandex',
    'youla',
    'recommendation',
    'stretching',
    'posting',
    'life_realty'
];
