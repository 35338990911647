import request from '~services/request';
import { EstateNamePipe } from '~modules/pipes/estate-name.pipe';
export const EstatesService = {
    getAll: ({ sectionName, ...filterParams }, page, pageSize, sort) => request
        .get('/estates', { params: { sectionName, page, pageSize, sort, filterParams } })
        .then(response => response.data),
    getOne: (id) => request.get(`/estates/${id}`).then(response => response.data),
    create: body => request
        .post('/estates', body, {
        'Content-Type': 'multipart/form-data'
    })
        .then(response => response.data),
    update: (id, body) => request.put(`/estates/${id}`, body).then(response => response.data),
    archive: id => request.post(`/estates/${id}/archive`),
    search: (cancelToken, params) => request
        .get('/estates/search', {
        cancelToken,
        params
    })
        .then(response => response.data.data
        .map(({ price, ...estate }) => {
        return {
            id: estate.id,
            title: `${EstateNamePipe({
                general: {
                    sectionName: estate.sectionName
                },
                section: estate.section
            })}, ${(estate.subdistrict ? estate.subdistrict.name : null) ||
                (estate.district ? estate.district.name : null) ||
                (estate.city ? estate.city.name : null) ||
                ''} (№ ${estate.id})`,
            price
        };
    })
        .slice(0, 100)),
    getExports: (id) => request
        .get('/funnel/show_estate_exports', { params: { id } })
        .then(response => response.data),
    setExports: (id, exportFlags) => request
        .put(`/funnel/update_estate_exports`, { id, exportFlags })
        .then(response => response.data),
    getPDF: (id) => {
        return request
            .post('/estates/generate_pdf', { estateId: id })
            .then(response => response.data);
    },
    getXLSX: (estateIds, filterParams) => {
        return request
            .post('/estates/generate_xlsx', {
            estateIds,
            sectionName: filterParams ? filterParams.sectionName : undefined,
            filterParams: filterParams ? JSON.stringify(filterParams) : undefined,
            isAllSelected: !!filterParams
        })
            .then(response => response.data);
    }
};
