import HomeRoutes from './views/Home/Home.routes';
import AccountRoutes from './views/Account/Account.routes';
import CalendarRoutes from './views/Calendar/Calendar.routes';
import ChatsRoutes from './views/Chats/Chats.routes';
import DealsRoutes from './views/Deals/Deals.routes';
import { EstatesRoutes } from './views/estates/estates.routes';
import FunnelsRoutes from './views/funnels/funnels.routes';
import { LeadsRoutes } from './views/leads/leads.routes';
import MapRoutes from './views/Map/Map.routes';
import TransferRoutes from './views/@Transfer/Transfer.routes';
import UploadsRoutes from './views/Uploads/Uploads.routes';
import HelpRoutes from './views/Help/Help.routes';
import { RemindersRoutes } from './views/reminders/reminders.routes';
import Reports from './views/Reports/Reports.routes';
import EmployeesRoutes from './views/@Organization/Employees/Employees.routes';
import FranchisesRoutes from './views/@Organization/Franchises/Franchises.routes';
import OfficesRoutes from './views/@Organization/Offices/Offices.routes';
import ReferralsRoutes from './views/@Organization/Referrals/Referrals.routes';
import PrivateGuard from './private.guard';
import { configStore } from '~stores/config';
import { notificationsStore } from '~stores/notifications';
const onActivate = async () => {
    await Promise.all([notificationsStore.getNotifications(), configStore.getConfig()]);
    return null;
};
const PrivateRoutes = [
    {
        name: 'home',
        path: '/',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: HomeRoutes
    },
    {
        name: 'chats',
        path: '/chats',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: ChatsRoutes
    },
    {
        name: 'account',
        path: '/account',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: AccountRoutes
    },
    {
        name: 'calendar',
        path: '/calendar',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: CalendarRoutes
    },
    {
        name: 'map',
        path: '/map',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: MapRoutes
    },
    {
        name: 'deals',
        path: '/deals',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: DealsRoutes
    },
    {
        name: 'estates',
        path: '/estates',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: EstatesRoutes
    },
    {
        name: 'funnels',
        path: '/funnels',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: FunnelsRoutes
    },
    {
        name: 'reminders',
        path: '/reminders',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: RemindersRoutes
    },
    {
        name: 'leads',
        path: '/leads',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: LeadsRoutes
    },
    {
        name: 'franchises',
        path: '/franchises',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: FranchisesRoutes
    },
    {
        name: 'offices',
        path: '/offices',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: OfficesRoutes
    },
    {
        name: 'employees',
        path: '/employees',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: EmployeesRoutes
    },
    {
        name: 'referrals',
        path: '/referrals',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: ReferralsRoutes
    },
    {
        name: 'reports',
        path: '/reports',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: Reports
    },
    {
        name: 'transfer',
        path: '/transfers',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: TransferRoutes
    },
    {
        name: 'uploads',
        path: '/uploads',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: UploadsRoutes
    },
    {
        name: 'help',
        path: '/help',
        component: () => import('./private').then(m => m.Private),
        beforeActivate: PrivateGuard,
        onActivate,
        children: HelpRoutes
    }
];
export { PrivateRoutes };
