var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action, runInAction } from 'mobx';
import { createContext } from 'react';
import request from '~services/request';
import { SessionService } from '~modules/core/session';
import { UserNamePipe } from '~modules/pipes/user-name.pipe';
import { socketStore } from '~stores/socket';
import userStore from '~stores/user';
import { chatsStore } from '~stores/chats';
import { notificationsStore } from '~stores/notifications';
class SessionStore {
    constructor() {
        this.isLoading = true;
        this.userId = undefined;
        this.user = {};
        this.isAuthorizedMemo = null;
        this.isAuthorized = async () => {
            if (this.isAuthorizedMemo !== null) {
                return this.isAuthorizedMemo;
            }
            else {
                return this.checkAuthorization();
            }
        };
    }
    async checkAuthorization() {
        try {
            const token = localStorage.getItem('jwtToken');
            if (token) {
                request.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('jwtToken')}`;
            }
            else {
                return false;
            }
            const sessions = await SessionService.getOne();
            await userStore.fetchEmployee(sessions.employeeId);
            socketStore.connect(token);
            runInAction(() => {
                this.userId = sessions.id;
                this.user = sessions;
                this.isLoading = false;
                this.isAuthorizedMemo = true;
                this.setYM();
            });
            return true;
        }
        catch (error) {
            runInAction(() => {
                if (error.response && error.response.status === 401) {
                    this.signout();
                }
                this.isLoading = false;
                this.isAuthorizedMemo = false;
            });
            return false;
        }
    }
    signin(email, password) {
        return SessionService.create(`${email}`.toLowerCase(), password).then(async (user) => {
            const { token, id, employeeId } = user;
            request.defaults.headers.common.Authorization = `Bearer ${token}`;
            await userStore.fetchEmployee(employeeId);
            socketStore.connect(token);
            localStorage.setItem('jwtToken', token);
            runInAction(() => {
                this.userId = id;
                this.user = user;
                this.isAuthorizedMemo = true;
                this.setYM();
            });
        });
    }
    signout() {
        this.isAuthorizedMemo = false;
        this.userId = undefined;
        this.user = {};
        request.defaults.headers.common.Authorization = undefined;
        localStorage.removeItem('jwtToken');
        userStore.clearStore();
        socketStore.disconnect();
        chatsStore.clearStore();
        notificationsStore.clearStore();
        // Clear funnels filters
        localStorage.removeItem('FUNNELS::ESTATES::FILTERS');
        localStorage.removeItem('FUNNELS::ESTATES::SORTING');
        localStorage.removeItem('FUNNELS::LEADS::FILTERS');
        localStorage.removeItem('FUNNELS::LEADS::SORTING');
        localStorage.removeItem('FUNNELS::TRANSFERS::ESTATES::FILTERS');
        localStorage.removeItem('FUNNELS::TRANSFERS::ESTATES::SORTING');
        localStorage.removeItem('FUNNELS::TRANSFERS::LEADS::FILTERS');
        localStorage.removeItem('FUNNELS::TRANSFERS::LEADS::SORTING');
        localStorage.removeItem('FUNNELS::AGENTS::FILTERS');
    }
    setYM() {
        const counter = process.env.YANDEX_METRIKA_COUNTER;
        if (counter && window.ym) {
            window.ym(+counter, 'setUserID', `${this.user.employeeId}`);
            window.ym(+counter, 'params', {
                params: {
                    EmployeeId: this.user.employeeId
                }
            });
            window.ym(+counter, 'userParams', {
                EmployeeId: this.user.employeeId,
                FIO: UserNamePipe(this.user, true)
            });
        }
    }
}
__decorate([
    observable
], SessionStore.prototype, "isLoading", void 0);
__decorate([
    observable
], SessionStore.prototype, "userId", void 0);
__decorate([
    observable
], SessionStore.prototype, "user", void 0);
__decorate([
    observable
], SessionStore.prototype, "isAuthorizedMemo", void 0);
__decorate([
    action.bound
], SessionStore.prototype, "checkAuthorization", null);
__decorate([
    action.bound
], SessionStore.prototype, "signin", null);
__decorate([
    action.bound
], SessionStore.prototype, "signout", null);
export const sessionStore = new SessionStore();
export const SessionStoreContext = createContext(sessionStore);
