import './drag-drop-fetching.module.scss';
import React, { memo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
export const DragDropFetching = memo(() => {
    return (<Dialog open={true}>
      <div styleName="fetching">
        <CircularProgress color="primary" size={96}/>
      </div>
    </Dialog>);
});
