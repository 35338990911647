import axios from 'axios';
import request from '~services/request';
const LIMIT = 10;
export const FunnelsService = {
    getAllEstates: (sorting, filterParams, offsets = [0, 0, 0, 0, 0, 0, 0], limit = LIMIT) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const req = request
            .get('/funnel/index_estate', {
            cancelToken: source.token,
            params: {
                sort: sorting,
                filterParams: JSON.stringify(filterParams),
                offsets: JSON.stringify(offsets),
                limit
            }
        })
            .then(response => response.data);
        return [source, req];
    },
    getAllLeads: (sorting, filterParams, offsets = [0, 0, 0, 0, 0, 0, 0, 0], limit = LIMIT) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const req = request
            .get('/funnel/index_lead', {
            params: {
                sort: sorting,
                filterParams: JSON.stringify(filterParams),
                offsets: JSON.stringify(offsets),
                limit
            }
        })
            .then(response => response.data);
        return [source, req];
    },
    getAllTransferEstates: (sorting, { searchValue, ...filterParams }, offsets = [0, 0, 0, 0, 0, 0, 0], limit = LIMIT) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const req = request
            .get('/funnel/index_transfer_estate', {
            params: {
                sort: sorting,
                filterParams: JSON.stringify(filterParams),
                objectParams: JSON.stringify({ searchValue }),
                offsets: JSON.stringify(offsets),
                limit
            }
        })
            .then(response => response.data);
        return [source, req];
    },
    getAllTransferLeads: (sorting, { searchValue, ...filterParams }, offsets = [0, 0, 0, 0, 0, 0, 0, 0], limit = LIMIT) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const req = request
            .get('/funnel/index_transfer_lead', {
            params: {
                sort: sorting,
                filterParams: JSON.stringify(filterParams),
                objectParams: JSON.stringify({ searchValue }),
                offsets: JSON.stringify(offsets),
                limit
            }
        })
            .then(response => response.data);
        return [source, req];
    },
    getEstatesCount: (filterParams) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const req = request
            .get('/funnel/count_estates', {
            params: {
                filterParams: JSON.stringify(filterParams)
            }
        })
            .then(response => response.data.reduce((acc, value) => {
            acc[value.status] = value.count;
            return acc;
        }, {}));
        return [source, req];
    },
    getLeadsCount: (filterParams) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const req = request
            .get('/funnel/count_leads', {
            params: {
                filterParams: JSON.stringify(filterParams)
            }
        })
            .then(response => response.data.reduce((acc, value) => {
            acc[value.status] = value.count;
            return acc;
        }, {}));
        return [source, req];
    },
    getTransferEstatesCount: ({ searchValue, ...filterParams }) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const req = request
            .get('/funnel/count_transfer_estates', {
            params: {
                filterParams: JSON.stringify(filterParams),
                objectParams: JSON.stringify({ searchValue })
            }
        })
            .then(response => response.data.reduce((acc, value) => {
            acc[value.status] = value.count;
            return acc;
        }, {}));
        return [source, req];
    },
    getTransferLeadsCount: ({ searchValue, ...filterParams }) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const req = request
            .get('/funnel/count_transfer_leads', {
            params: {
                filterParams: JSON.stringify(filterParams),
                objectParams: JSON.stringify({ searchValue })
            }
        })
            .then(response => response.data.reduce((acc, value) => {
            acc[value.status] = value.count;
            return acc;
        }, {}));
        return [source, req];
    },
    getAllAgentsEstates: ({ dateStart, dateEnd, ...filterParams }, page, pageSize) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const params = {
            ...filterParams,
            dateStart: dateStart || null,
            dateEnd: dateEnd || null
        };
        const req = request
            .get('/funnel/summary_estate', {
            params: {
                page: page + 1,
                pageSize,
                filterParams: JSON.stringify(params)
            }
        })
            .then(response => response.data);
        return [source, req];
    },
    getAllAgentsLeads: ({ dateStart, dateEnd, ...filterParams }, page, pageSize) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const params = {
            ...filterParams,
            dateStart: dateStart || null,
            dateEnd: dateEnd || null
        };
        const req = request
            .get('/funnel/summary_lead', {
            params: {
                page: page + 1,
                pageSize,
                filterParams: JSON.stringify(params)
            }
        })
            .then(response => response.data);
        return [source, req];
    }
};
