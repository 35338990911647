import request from '~services/request';

export const AccountService = {
  getOne: () => request.get('/user/profile').then(response => response.data),
  getEmployee: id => request.get(`/employees/${id}`).then(response => response.data),
  update: body => request.put('/user/profile', body).then(response => response.data.user),
  changePassword: ({ password, passwordConfirmation, recoveryKey }) =>
    request
      .post('/user/set_new_password', { password, passwordConfirmation, recoveryKey })
      .then(response => response.data),
  recoverPassword: email =>
    request.put('/user/recover_password', { email }).then(response => response.data),
  checkRecoverKey: recoveryKey =>
    request
      .get('/user/check_recover_key', { params: { recoveryKey } })
      .then(response => response.data)
      .catch(error => error?.response?.data)
};
