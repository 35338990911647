export default {
    apartment: {
        id: '',
        propertyType: [],
        typeObject: [],
        price: { from: '', to: '' },
        phone: '',
        cities: [],
        districts: [],
        street: '',
        fullSquare: { from: '', to: '' },
        livingSquare: { from: '', to: '' },
        kitchenSquare: { from: '', to: '' },
        floor: '',
        houseStorey: '',
        houseType: [],
        employee: null,
        date: null,
        dealType: '',
        adTypes: [],
        my: true,
        statuses: [],
        showSelled: false
    },
    house: {
        id: '',
        propertyType: [],
        typeObject: [],
        price: { from: '', to: '' },
        phone: '',
        cities: [],
        districts: [],
        street: '',
        fullSquare: { from: '', to: '' },
        livingSquare: { from: '', to: '' },
        kitchenSquare: { from: '', to: '' },
        landSquare: { from: '', to: '' },
        floor: '',
        houseStorey: '',
        wallMaterial: [],
        employee: null,
        date: null,
        dealType: '',
        adTypes: [],
        my: true,
        statuses: [],
        showSelled: false
    },
    stead: {
        id: '',
        landCategory: [],
        price: { from: '', to: '' },
        phone: '',
        cities: [],
        districts: [],
        street: '',
        landSquare: { from: '', to: '' },
        employee: null,
        date: null,
        infrastructure: [],
        adTypes: [],
        my: true,
        statuses: [],
        showSelled: false
    },
    commercial: {
        id: '',
        typeObject: [],
        price: { from: '', to: '' },
        phone: '',
        cities: [],
        districts: [],
        street: '',
        fullSquare: { from: '', to: '' },
        employee: null,
        date: null,
        dealType: '',
        adTypes: [],
        my: true,
        statuses: [],
        showSelled: false
    }
};
