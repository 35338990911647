import dataStoreFactory from '~utils/data-store-factory';
import { EmployeesService } from '~modules/features/employees';
import userStore from '~stores/user';

import { createContext } from 'react';

const Employees = dataStoreFactory(EmployeesService, {
  initialFilters: () => ({
    searchValue: '',
    cities: [{ id: userStore.user.city.id, title: userStore.user.city.name }],
    franchises: [],
    offices: [],
    roles: []
  }),
  transformFilters: ({ roles, cities, franchises, offices, ...filters }) => ({
    ...filters,
    roles: roles
      .filter(role => role !== 'partner' && role !== 'trainee')
      .concat(roles.includes('partner') || roles.includes('trainee') ? ['agent'] : []),
    agentPositions: roles
      .filter(role => role === 'partner' || role === 'trainee')
      .map(role => (role === 'partner' ? ['partner'] : ['base', 'trainee']))
      .flat(),
    cityIds: cities.map(e => e.id),
    franchiseIds: franchises.map(e => e.id),
    officeIds: offices.map(e => e.id)
  })
});

const employeesStore = new Employees();
export default employeesStore;
export const EmployeesStoreContext = createContext(employeesStore);
