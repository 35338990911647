import request from '~services/request';

export const FranchisesService = {
  getAll: (filterParams, page, pageSize, sort) =>
    request
      .get('/company_management/franchises', { params: { filterParams, page, pageSize, sort } })
      .then(response => response.data),
  getOne: id => request.get(`/company_management/franchises/${id}`).then(response => response.data),
  create: body => request.post('/company_management/franchises', body),
  update: (id, body) => request.put(`/company_management/franchises/${id}`, { params: body }),
  search: (cancelToken, { withOffices, ...filterParams }) =>
    request
      .get('/company_management/franchises/search', {
        cancelToken,
        params: { withOffices, filterParams }
      })
      .then(response => {
        const data = response.data.data;
        if (withOffices) {
          return data
            .filter(e => e.offices.length !== 0)
            .map(e => ({
              title: e.title,
              children: e.offices
            }));
        }
        return data;
      }),
  searchFunnels: (cancelToken, params) =>
    request
      .get('/funnel_params/franchises', {
        cancelToken,
        params
      })
      .then(response => {
        const data = response.data.data;
        return data;
      })
};
