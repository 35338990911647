import dataStoreFactory from '~utils/data-store-factory';
import { FranchisesService } from '~modules/features/franchises';
import userStore from '~stores/user';

const Franchises = dataStoreFactory(FranchisesService, {
  initialFilters: () => ({
    title: '',
    cities: [{ id: userStore.user.city.id, title: userStore.user.city.name }]
  }),
  transformFilters: ({ cities, ...filters }) => ({
    ...filters,
    cityIds: cities.map(e => e.id)
  })
});

export default new Franchises();
