import request from '~services/request';
export const OfficesService = {
    getAll: (filterParams, page, pageSize, sort) => request
        .get('/company_management/offices', { params: { filterParams, page, pageSize, sort } })
        .then(response => response.data),
    getOne: id => request.get(`/company_management/offices/${id}`).then(response => response.data),
    create: body => request.post('/company_management/offices', body),
    update: (id, body) => request.put(`/company_management/offices/${id}`, { params: body }),
    search: (cancelToken, params) => request
        .get('/company_management/offices/search', { cancelToken, params })
        .then(response => response.data.data.map(({ id, title }) => ({ id, title })))
};
