import dataStoreFactory from '~utils/data-store-factory';
import { OfficesService } from '~modules/features/offices';
import userStore from '~stores/user';

const Offices = dataStoreFactory(OfficesService, {
  initialFilters: () => ({
    title: '',
    cities: [{ id: userStore.user.city.id, title: userStore.user.city.name }],
    franchises: []
  }),
  transformFilters: ({ cities, franchises, ...filters }) => ({
    ...filters,
    cityIds: cities.map(e => e.id),
    franchiseIds: franchises.map(e => e.id)
  })
});

export default new Offices();
