export const getFormData = (form) => {
    const data = form.toJS();
    const body = new FormData();
    // body.append('estate[status]', data.estate.status);
    body.append('estate[sectionName]', data.estate.sectionName);
    if (data.estate.employee) {
        body.append('estate[employeeId]', '' + data.estate.employee.id);
    }
    body.append('estate[dealType]', data.estate.dealType || 'sale');
    body.append('estate[adType]', data.estate.adType);
    body.append('estate[price]', '' + data.estate.price);
    body.append('estate[cadastralNumber]', data.estate.cadastralNumber);
    body.append('estate[sewerage]', '' + data.estate.sewerage);
    body.append('estate[gas]', '' + data.estate.gas);
    body.append('estate[water]', '' + data.estate.water);
    body.append('estate[electricity]', '' + data.estate.electricity);
    body.append('estate[internet]', '' + data.estate.internet);
    body.append('estate[security]', '' + data.estate.security);
    if (data.estate.country) {
        body.append('estate[countryId]', '' + data.estate.country.id);
    }
    if (data.estate.region) {
        body.append('estate[regionId]', '' + data.estate.region.id);
    }
    if (data.estate.city) {
        body.append('estate[cityId]', '' + data.estate.city.id);
    }
    if (data.estate.district) {
        body.append('estate[districtId]', '' + data.estate.district.id);
    }
    if (data.estate.subdistrict) {
        body.append('estate[subdistrictId]', '' + data.estate.subdistrict.id);
    }
    body.append('estate[street]', data.estate.street);
    body.append('estate[description]', data.estate.description);
    body.append('estate[comment]', data.estate.comment);
    body.append('estate[ownerName]', data.estate.ownerName);
    data.estate.ownerPhone.filter(Boolean).forEach((phone, index) => {
        body.append(`estate[ownerPhone][${index}]`, phone);
    });
    body.append('estate[ownerEmail]', data.estate.ownerEmail);
    if (data.estate.fromThirdPartyAgent) {
        body.append('estate[fromThirdPartyAgent]', '' + data.estate.fromThirdPartyAgent);
        body.append('estate[thirdPartyAgentName]', data.estate.thirdPartyAgentName);
        data.estate.thirdPartyAgentPhone.filter(Boolean).forEach((phone, index) => {
            body.append(`estate[thirdPartyAgentPhone][${index}]`, phone);
        });
    }
    data.estate.photos.forEach((file, index) => {
        if ('id' in file) {
            body.append(`estate[photos][${index}][id]`, '' + file.id);
            if (file.remove) {
                body.append(`estate[photos][${index}][delete]`, 'true');
            }
        }
        else {
            body.append(`estate[photos][${index}][file]`, file.file);
        }
    });
    data.estate.stretchMarks.forEach((file, index) => {
        if ('id' in file) {
            body.append(`estate[stretchMarks][0][photos][${index}][id]`, '' + file.id);
            if (file.remove) {
                body.append(`estate[stretchMarks][0][photos][${index}][delete]`, 'true');
            }
        }
        else {
            body.append(`estate[stretchMarks][0][photos][${index}][file]`, file.file);
        }
    });
    data.estate.estateContracts.forEach((file, index) => {
        if ('id' in file) {
            body.append(`estate[estateContracts][0][photos][${index}][id]`, '' + file.id);
            if (file.remove) {
                body.append(`estate[estateContracts][0][photos][${index}][delete]`, 'true');
            }
        }
        else {
            body.append(`estate[estateContracts][0][photos][${index}][file]`, file.file);
        }
    });
    if (data.estate.link3d &&
        !(data.estate.link3d.startsWith('http://') || data.estate.link3d.startsWith('https://'))) {
        body.append('estate[link3d]', `http://${data.estate.link3d}`);
    }
    else {
        body.append('estate[link3d]', data.estate.link3d || '');
    }
    if (data.estate.linkYoutube &&
        !(data.estate.linkYoutube.startsWith('http://') ||
            data.estate.linkYoutube.startsWith('https://'))) {
        body.append('estate[linkYoutube]', `http://${data.estate.linkYoutube}`);
    }
    else {
        body.append('estate[linkYoutube]', data.estate.linkYoutube || '');
    }
    body.append('estate[closedTerritory]', '' + data.estate.closedTerritory);
    body.append('estate[undergroundParking]', '' + data.estate.undergroundParking);
    body.append('estate[developer]', data.estate.developer);
    if (data.estate.sectionName === 'apartment') {
        body.append('section[typeObject]', data.apartment.typeObject);
        body.append('section[propertyType]', data.apartment.propertyType);
        body.append('section[houseNumber]', data.apartment.houseNumber);
        body.append('section[houseNumberUploading]', data.apartment.houseNumberUploading);
        body.append('section[building]', data.apartment.building);
        body.append('section[apartmentNumber]', data.apartment.apartmentNumber);
        body.append('section[floor]', '' + data.apartment.floor);
        body.append('section[houseStorey]', '' + data.apartment.houseStorey);
        body.append('section[fullSquare]', '' + data.apartment.fullSquare);
        body.append('section[livingSquare]', '' + data.apartment.livingSquare);
        body.append('section[kitchenSquare]', '' + data.apartment.kitchenSquare);
        body.append('section[conditionObject]', data.apartment.conditionObject);
        body.append('section[houseType]', data.apartment.houseType);
        body.append('section[constructionYear]', '' + data.apartment.constructionYear);
        body.append('section[wc]', data.apartment.wc);
        body.append('section[balcony]', '' + data.apartment.balcony);
        body.append('section[loggia]', '' + data.apartment.loggia);
        body.append('section[mortgage]', '' + data.apartment.mortgage);
        if (data.apartment.propertyType === 'new_building' ||
            data.apartment.propertyType === 'shareholder') {
            body.append('section[buildingState]', '' + data.apartment.buildingState);
            body.append('section[constructionQuater]', '' + data.apartment.constructionQuater);
        }
        else {
            body.append('section[buildingState]', '');
            body.append('section[constructionQuater]', '');
        }
    }
    else if (data.estate.sectionName === 'house') {
        body.append('section[typeObject]', data.house.typeObject);
        body.append('section[propertyType]', data.house.propertyType);
        body.append('section[houseNumber]', data.house.houseNumber);
        body.append('section[houseNumberUploading]', data.house.houseNumberUploading);
        body.append('section[houseStorey]', '' + data.house.houseStorey);
        body.append('section[roomsAmount]', '' + data.house.roomsAmount);
        body.append('section[fullSquare]', '' + data.house.fullSquare);
        body.append('section[livingSquare]', '' + data.house.livingSquare);
        body.append('section[kitchenSquare]', '' + data.house.kitchenSquare);
        body.append('section[landSquare]', '' + data.house.landSquare);
        body.append('section[conditionObject]', data.house.conditionObject);
        body.append('section[wallMaterial]', data.house.wallMaterial);
        body.append('section[distanceToCity]', '' + data.house.distanceToCity);
        body.append('section[constructionYear]', '' + data.house.constructionYear);
        body.append('section[wc]', data.house.wc);
        body.append('section[mortgage]', '' + data.house.mortgage);
        if (data.house.propertyType === 'new_building' || data.house.propertyType === 'shareholder') {
            body.append('section[buildingState]', '' + data.house.buildingState);
            body.append('section[constructionQuater]', '' + data.house.constructionQuater);
        }
        else {
            body.append('section[buildingState]', '');
            body.append('section[constructionQuater]', '');
        }
    }
    else if (data.estate.sectionName === 'stead') {
        body.append('section[landCategory]', data.stead.landCategory);
        body.append('section[number]', data.stead.number);
        body.append('section[landSquare]', '' + data.stead.landSquare);
        body.append('section[distanceToCity]', '' + data.stead.distanceToCity);
    }
    else if (data.estate.sectionName === 'commercial') {
        body.append('section[typeObject]', data.commercial.typeObject);
        body.append('section[houseNumber]', data.commercial.houseNumber);
        body.append('section[houseNumberUploading]', data.commercial.houseNumberUploading);
        body.append('section[building]', '' + data.commercial.building);
        body.append('section[fullSquare]', '' + data.commercial.fullSquare);
        body.append('section[conditionObject]', data.commercial.conditionObject);
        body.append('section[floor]', '' + data.commercial.floor);
        body.append('section[houseStorey]', '' + data.commercial.houseStorey);
    }
    return body;
};
