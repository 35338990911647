function limit(val, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = `0${val}`;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '00';
      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

function time(value) {
  const month = limit(value.substring(0, 2), '23');
  const year = limit(value.substring(2, 4), '59');

  return month + (year.length ? `:${year}` : '');
}

export default { format: time, mask: ['Ч', 'Ч', 'М', 'М'] };
