var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from 'mobx';
export class FormArray {
    constructor(items = []) {
        this.setName = (name) => {
            this.name = name;
            this.$.forEach((item, index) => item.setName(`${name}[${index}]`));
        };
        this.toJS = () => {
            return this.$.map(item => item.toJS());
        };
        this.$ = items;
        this.name = '';
    }
    append(item) {
        this.$.push(item);
        item.setName(`${this.name}[${this.$.length - 1}]`);
    }
    remove(index) {
        this.$.splice(index, 1);
        this.$.forEach((item, index) => item.setName(`${this.name}[${index}]`));
    }
    validate() {
        return this.$.map(item => item.validate()).every(Boolean);
    }
    setTouched(isTouched) {
        this.$.forEach(item => item.setTouched(isTouched));
    }
    setDirty(isDirty) {
        this.$.forEach(item => item.setDirty(isDirty));
    }
}
__decorate([
    observable
], FormArray.prototype, "$", void 0);
__decorate([
    action.bound
], FormArray.prototype, "append", null);
__decorate([
    action.bound
], FormArray.prototype, "remove", null);
__decorate([
    action.bound
], FormArray.prototype, "setTouched", null);
__decorate([
    action.bound
], FormArray.prototype, "setDirty", null);
