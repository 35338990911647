import { createContext, useContext } from 'react';

import { socketStore } from '~stores/socket';

class UploadsStore {
  channel = null;

  connect = id => {
    this.channel = socketStore.socket.channel(`uploads:employee_${id}`, {});

    return new Promise((resolve, reject) => {
      this.channel
        .join()
        .receive('ok', () => {
          resolve(this);
        })
        .receive('error', res => {
          console.log(res);
          reject(res);
        });
    });
  };
}

export const uploadsStore = new UploadsStore();
export const UploadsStoreContext = createContext(uploadsStore);

export const useUploadsStore = () => {
  const uploadsStore = useContext(UploadsStoreContext);
  return { uploadsStore };
};
