export const getFormData = (form) => {
    const { dealType, status, employee, country, region, cities, districts, subdistricts, ...data } = form.toJS();
    const body = {
        lead: {
            dealType: dealType || 'sale',
            countryId: country?.id || null,
            regionId: region?.id || null,
            cities: cities.map(city => city.id),
            districts: districts.map(district => district.id),
            subdistricts: subdistricts.map(subdistrict => subdistrict.id),
            ...data
        }
    };
    if (employee) {
        body.lead.employeeId = employee.id;
    }
    return body;
};
