import 'core-js';
import 'intersection-observer';
import './assets/styles/normalize.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Application } from './application';
import '~services/i18n';
if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
}
ReactDOM.render(<Application />, document.getElementById('root'));
if (process.env.NODE_ENV === 'production') {
    // tslint:disable-next-line:no-var-requires
    require('offline-plugin/runtime').install();
}
