import styles from './select-chips.module.scss';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import Chip from '@material-ui/core/Chip';

import compose from '~utils/compose';

const classes = {
  deleteIcon: styles.chips__delete
};

function getX(event) {
  if (event.pageX) {
    return event.pageX;
  }
  if (event.touches) {
    return event.touches[0].pageX;
  }
  return 0;
}

class SelectChips extends PureComponent {
  static propTypes = {
    value: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({
          title: PropTypes.node.isRequired,
          id: PropTypes.any.isRequired
        })
      ])
    ).isRequired,
    onDelete: PropTypes.func,
    isClearable: PropTypes.bool,
    isFlat: PropTypes.bool,
    isOutlined: PropTypes.bool,
    t: PropTypes.func.isRequired,
    translation: PropTypes.string
  };

  static defaultProps = {
    isFlat: false,
    isOutlined: false
  };

  constructor(props) {
    super(props);
    this.mx = 0;
  }

  componentDidMount() {
    document.addEventListener('mouseup', this.onMouseUp);
    document.addEventListener('mousemove', this.onMouseMove);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('mousemove', this.onMouseMove);
  }

  onMouseDownStopProp(event) {
    event.stopPropagation();
  }

  onMouseDown = event => {
    this.sx = this.chips.scrollLeft;
    this.mx = getX(event) - this.chips.offsetLeft;
    this.isMoving = true;
    document.addEventListener('mousemove', this.onMouseMove);
  };

  onMouseUp = () => {
    this.mx = 0;
    this.isMoving = false;
    document.removeEventListener('mousemove', this.onMouseMove);
  };

  onMouseMove = event => {
    if (this.isMoving) {
      const mx2 = getX(event) - this.chips.offsetLeft;
      if (this.mx) {
        this.chips.scrollLeft = this.sx + this.mx - mx2;
      }
    }
  };

  render() {
    const { value, onDelete, isFlat, isClearable, isOutlined, t, translation } = this.props;

    return (
      <>
        <div styleName="chips__spacer">
          <div
            styleName={classNames('chips', {
              chips_clearable: isClearable,
              chips_outlined: isOutlined
            })}
            ref={ref => (this.chips = ref)}
            onMouseDown={this.onMouseDown}
            onMouseUp={this.onMouseUp}
            onTouchStart={this.onMouseDown}
            onTouchEnd={this.onMouseUp}
            onTouchMove={this.onMouseMove}
          >
            {value.map(v => {
              return (
                <Chip
                  onMouseDown={this.onMouseDownStopProp}
                  styleName="chips__chip"
                  classes={classes}
                  key={isFlat ? v : v.id}
                  label={
                    translation ? t(`${translation}.${isFlat ? v : v.title}`) : isFlat ? v : v.title
                  }
                  onDelete={onDelete(isFlat ? v : v.id)}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default compose([withTranslation('translations')])(SelectChips);
