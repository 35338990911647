import './application-offline.module.scss';
import React, { memo, useContext, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { ApplicationOfflineStoreContext } from './application-offline.store';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { Icon } from '~modules/ui/icon';
export const ApplicationOffline = memo(observer(() => {
    const applicationOfflineStoreContext = useContext(ApplicationOfflineStoreContext);
    useEffect(() => {
        const onOffline = () => {
            applicationOfflineStoreContext.setOffline(true);
        };
        const onOnline = () => {
            applicationOfflineStoreContext.setOffline(false);
        };
        window.addEventListener('offline', onOffline);
        window.addEventListener('online', onOnline);
        return () => {
            window.removeEventListener('offline', onOffline);
            window.removeEventListener('online', onOnline);
        };
    }, []);
    const onClose = useCallback(() => {
        applicationOfflineStoreContext.setOffline(false);
    }, []);
    return (<Snackbar styleName="offline" anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
    }} open={applicationOfflineStoreContext.isOffline} message="Отсутствует интернет-соединение" action={<IconButton aria-label="Закрыть" color="inherit" onClick={onClose}>
            <Icon name="close"/>
          </IconButton>}/>);
}));
