import * as SECTION_NAME from '~constants/types/section';
export const isValid = (lead) => {
    const { sectionName, propertyType, dealType, calculationForm, commertionalObjectType, houseObjectType, apartmentsType, landCategory, price, country, region, cities, districts, fromThirdPartyAgent, thirdPartyAgentName, thirdPartyAgentPhone, customerName, customerPhone, leadSource, leadSourceOther } = lead.general;
    if (!(price &&
        sectionName.length &&
        dealType &&
        calculationForm &&
        country &&
        region &&
        cities.length &&
        districts.length)) {
        return false;
    }
    if ((sectionName.includes(SECTION_NAME.APARTMENT) || sectionName.includes(SECTION_NAME.HOUSE)) &&
        !propertyType) {
        return false;
    }
    if (sectionName.includes(SECTION_NAME.APARTMENT) && !apartmentsType.length) {
        return false;
    }
    if (sectionName.includes(SECTION_NAME.HOUSE) && !houseObjectType.length) {
        return false;
    }
    if (sectionName.includes(SECTION_NAME.COMMERCIAL) && !commertionalObjectType.length) {
        return false;
    }
    if (sectionName.includes(SECTION_NAME.STEAD) && !landCategory.length) {
        return false;
    }
    if (fromThirdPartyAgent) {
        if (!(thirdPartyAgentName && thirdPartyAgentPhone.length)) {
            return false;
        }
    }
    else {
        if (!(customerName && customerPhone.length)) {
            return false;
        }
    }
    if (!leadSource || (leadSource === 'other' && !leadSourceOther)) {
        return false;
    }
    return true;
};
