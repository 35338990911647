export default function mapRoles(data) {
  const roles = {};

  data.roles.forEach(role => (roles[role] = { franchises: [], offices: [], cities: [] }));

  data.employeesFranchises.forEach(franchise => {
    franchise.roles.forEach(role => {
      if (roles[role]) {
        roles[role].franchises.push(franchise.franchise);
      } else {
        roles[role] = {
          franchises: [franchise.franchise],
          offices: [],
          cities: []
        };
      }
    });
  });

  data.employeesOffices.forEach(office => {
    office.roles.forEach(role => {
      if (roles[role]) {
        roles[role].offices.push(office.office);
      } else {
        roles[role] = {
          franchises: [],
          offices: [office.office],
          cities: []
        };
      }
    });
  });

  data.employeesCities.forEach(city => {
    city.roles.forEach(role => {
      if (roles[role]) {
        roles[role].cities.push(city.city);
      } else {
        roles[role] = {
          franchises: [],
          offices: [],
          cities: [city.city]
        };
      }
    });
  });

  return roles;
}
