import { FormControl } from '~modules/forms';
const STEP0 = [
    'estate.price',
    'estate.sectionName',
    'estate.dealType',
    'apartment.propertyType',
    'apartment.typeObject',
    'house.propertyType',
    'house.typeObject',
    'stead.landCategory',
    'commercial.typeObject',
    'estate.adType',
    'estate.estateContracts',
    'estate.cadastralNumber',
    'estate.ownerName',
    'estate.ownerEmail',
    'estate.thirdPartyAgentName',
    'estate.country',
    'estate.region',
    'estate.city',
    'estate.district',
    'estate.subdistrict',
    'house.distanceToCity',
    'estate.developer',
    'estate.street',
    'apartment.houseNumber',
    'apartment.houseNumberUploading',
    'apartment.building',
    'apartment.apartmentNumber',
    'house.houseNumber',
    'house.houseNumberUploading',
    'stead.distanceToCity',
    'stead.number',
    'commercial.houseNumber',
    'commercial.houseNumberUploading',
    'commercial.building',
    'estate.employee'
];
const STEP1 = [
    'apartment.fullSquare',
    'apartment.livingSquare',
    'apartment.kitchenSquare',
    'apartment.floor',
    'apartment.houseStorey',
    'apartment.houseType',
    'apartment.conditionObject',
    'apartment.constructionYear',
    'apartment.wc',
    'apartment.constructionQuater',
    'apartment.buildingState',
    'house.fullSquare',
    'house.livingSquare',
    'house.kitchenSquare',
    'house.landSquare',
    'house.houseStorey',
    'house.roomsAmount',
    'house.wallMaterial',
    'house.conditionObject',
    'house.constructionYear',
    'house.wc',
    'house.constructionQuater',
    'house.buildingState',
    'stead.landSquare',
    'commercial.fullSquare',
    'commercial.floor',
    'commercial.houseStorey',
    'commercial.conditionObject',
    'estate.description',
    'estate.link3d',
    'estate.linkYoutube',
    'estate.comment'
];
const STEP2 = ['estate.photos', 'estate.stretchMarks'];
const STEPS = [STEP0, STEP1, STEP2];
export const getErrors = (step, form) => {
    const stepNames = STEPS[step].slice();
    if (step === 0) {
        form.$.estate.$.ownerPhone.$.forEach(c => {
            stepNames.push(c.name);
        });
        form.$.estate.$.thirdPartyAgentPhone.$.forEach(c => {
            stepNames.push(c.name);
        });
    }
    const names = [];
    Object.keys(form.$.estate.$).forEach(key => {
        const control = form.$.estate.$[key];
        if (control instanceof FormControl) {
            if (stepNames.indexOf(control.name) !== -1 && control.error) {
                names.push(control.name);
            }
        }
        else {
            control.$.forEach(c => {
                if (stepNames.indexOf(c.name) !== -1 && c.error) {
                    names.push(c.name);
                }
            });
        }
    });
    if (form.$.estate.$.sectionName.value) {
        const group = form.$[form.$.estate.$.sectionName.value];
        Object.keys(group.$).forEach(key => {
            const control = group.$[key];
            if (stepNames.indexOf(control.name) !== -1 && control.error) {
                names.push(control.name);
            }
        });
    }
    return names;
};
export const scrollToError = (step, setStep, form) => {
    const errors = {
        0: getErrors(0, form),
        1: getErrors(1, form),
        2: getErrors(2, form)
    };
    const errorsStep = errors[0].length ? 0 : errors[1].length ? 1 : 2;
    if (step !== errorsStep) {
        setStep(errorsStep);
    }
    setTimeout(() => {
        const names = errors[errorsStep];
        let min = null;
        let minInput = null;
        for (const name of names) {
            const input = document.getElementById(name);
            if (input) {
                const parrent = input.parentElement;
                if (parrent) {
                    const top = parrent.getBoundingClientRect().top;
                    if (min === null || top < min) {
                        min = top;
                        minInput = parrent;
                    }
                }
            }
        }
        if (minInput) {
            const top = minInput.getBoundingClientRect().top;
            const scrollBy = top - window.innerHeight / 2;
            const containers = document.querySelectorAll('.MuiDialog-scrollBody');
            const container = containers[containers.length - 1];
            if (container) {
                container.scrollTop += scrollBy;
            }
            else {
                window.scrollTo(0, window.scrollY + scrollBy);
            }
        }
    }, step !== errorsStep ? 700 : 0);
};
