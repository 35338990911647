var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action, runInAction } from 'mobx';
import qs from 'querystringify';
import deserializeQuery from '~utils/deserialize-query';
import { router } from '~services/router';
import axios from 'axios';
export default function (api, options = {}) {
    options = {
        sortOptions: [],
        sortInitial: 0,
        initialFilters: () => ({}),
        transformFilters: (filters) => filters,
        ...options
    };
    class DataStore {
        constructor() {
            this.count = 0;
            this.data = [];
            this.restData = {};
            this.isLoading = false;
            this.page = 0;
            this.pageSize = 20;
            this.sort = null;
            this.filters = {};
            this.appliedFilters = {};
            this.sortOptions = options.sortOptions;
            this.options = options;
            this.cancel = null;
            this.getInitialPage = async (params) => {
                this.deserialize(params);
                await this.getPage(true);
            };
            this.toProps = () => {
                const { count, data, isLoading, pageSize, page, onPageChange, onPageSizeChange } = this;
                return {
                    count,
                    data,
                    isLoading,
                    pageSize,
                    page,
                    onPageSizeChange,
                    onPageChange
                };
            };
        }
        resetFilters() {
            this.filters = options.initialFilters(this.filters);
            this.page = 0;
            this.getPage();
            this.appliedFilters = { ...this.filters };
        }
        applyFilters() {
            this.page = 0;
            this.getPage();
            this.appliedFilters = { ...this.filters };
        }
        setFilters(filters, silent = false) {
            this.filters = filters;
            if (!silent) {
                this.page = 0;
                this.getPage();
                this.appliedFilters = { ...filters };
            }
        }
        setFilter(name, value) {
            this.filters[name] = value;
        }
        setSort(sort) {
            this.sort = sort;
            this.page = 0;
            this.getPage();
        }
        onTablePrevPage() {
            this.page -= 1;
            this.getPage();
        }
        onTableNextPage() {
            this.page += 1;
            this.getPage();
        }
        onPageChange(page) {
            if (!this.isLoading) {
                this.page = page;
                this.getPage();
            }
        }
        onPageSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.page = 0;
            this.getPage();
        }
        async create(body) {
            const response = await api.create(body);
            runInAction(() => {
                this.page = 0;
            });
            return response;
        }
        async update(id, body) {
            const response = await api.update(id, body);
            runInAction(() => {
                this.page = 0;
            });
            return response;
        }
        async getPage(isInitial) {
            this.isLoading = true;
            const { page, pageSize, sort, filters } = this;
            if (!isInitial) {
                window.history.replaceState({}, '', `?${this.toQueryString()}`);
                router.replaceHistoryState(router.getState().name, this.toJSON());
            }
            try {
                if (this.cancel !== null) {
                    this.cancel('cancelRequest');
                }
                const filterParams = {};
                Object.keys(filters).forEach(key => {
                    if (filters[key] !== null) {
                        filterParams[key] = filters[key];
                    }
                });
                const result = api.getAll(options.transformFilters(filters), page + 1, pageSize, options.sortOptions.length ? sort : null);
                const promise = Array.isArray(result) ? result[0] : result;
                Array.isArray(result) ? (this.cancel = result[1]) : (this.cancel = null);
                const { totalEntries, data, ...restData } = await promise;
                runInAction(() => {
                    this.count = totalEntries;
                    this.data = data;
                    this.restData = restData;
                    this.isLoading = false;
                    this.appliedFilters = { ...this.filters };
                });
            }
            catch (error) {
                runInAction(() => {
                    if (error.message !== 'cancelRequest') {
                        this.data = [];
                        this.isLoading = false;
                    }
                });
                if (!axios.isCancel(error)) {
                    throw error;
                }
            }
        }
        toJSON() {
            const { page, pageSize, sort, filters } = this;
            const params = {};
            if (options.sortOptions.length) {
                params.sort = sort;
            }
            if (page !== 0) {
                params.page = page;
            }
            if (pageSize !== 20) {
                params.pageSize = pageSize;
            }
            Object.keys(filters).forEach(key => {
                const value = filters[key];
                if (value === '' || value === null) {
                    return;
                }
                if (typeof value === 'object') {
                    if (Array.isArray(value)) {
                        if (value.length) {
                            params[key] = JSON.stringify(value);
                        }
                        return;
                    }
                    else if (!(value instanceof Date)) {
                        if (!value.from && !value.to) {
                            params[key] = JSON.stringify(value);
                            return;
                        }
                        params[key] = JSON.stringify(value);
                    }
                    else {
                        params[key] = JSON.stringify(value);
                    }
                }
                else {
                    params[key] = value;
                }
            });
            return params;
        }
        toQueryString() {
            return qs.stringify(this.toJSON());
        }
        deserialize(params) {
            const { page, pageSize, sort, ...filters } = params;
            this.page = +page || 0;
            this.pageSize = +pageSize || 20;
            if (options.sortOptions.length) {
                this.sort = sort || options.sortOptions[options.sortInitial];
            }
            const deserializeFilters = deserializeQuery(filters);
            this.filters = {
                ...options.initialFilters(deserializeFilters),
                ...deserializeFilters
            };
        }
    }
    __decorate([
        observable
    ], DataStore.prototype, "count", void 0);
    __decorate([
        observable
    ], DataStore.prototype, "data", void 0);
    __decorate([
        observable
    ], DataStore.prototype, "restData", void 0);
    __decorate([
        observable
    ], DataStore.prototype, "isLoading", void 0);
    __decorate([
        observable
    ], DataStore.prototype, "page", void 0);
    __decorate([
        observable
    ], DataStore.prototype, "pageSize", void 0);
    __decorate([
        observable
    ], DataStore.prototype, "sort", void 0);
    __decorate([
        observable
    ], DataStore.prototype, "filters", void 0);
    __decorate([
        observable
    ], DataStore.prototype, "appliedFilters", void 0);
    __decorate([
        observable
    ], DataStore.prototype, "sortOptions", void 0);
    __decorate([
        action.bound
    ], DataStore.prototype, "resetFilters", null);
    __decorate([
        action.bound
    ], DataStore.prototype, "applyFilters", null);
    __decorate([
        action.bound
    ], DataStore.prototype, "setFilters", null);
    __decorate([
        action.bound
    ], DataStore.prototype, "setFilter", null);
    __decorate([
        action.bound
    ], DataStore.prototype, "setSort", null);
    __decorate([
        action.bound
    ], DataStore.prototype, "onTablePrevPage", null);
    __decorate([
        action.bound
    ], DataStore.prototype, "onTableNextPage", null);
    __decorate([
        action.bound
    ], DataStore.prototype, "onPageChange", null);
    __decorate([
        action.bound
    ], DataStore.prototype, "onPageSizeChange", null);
    __decorate([
        action.bound
    ], DataStore.prototype, "create", null);
    __decorate([
        action.bound
    ], DataStore.prototype, "update", null);
    __decorate([
        action.bound
    ], DataStore.prototype, "getPage", null);
    __decorate([
        action.bound
    ], DataStore.prototype, "deserialize", null);
    return DataStore;
}
