import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
export const useEstatesTable = (estates, totalEntries, filters, store, service) => {
    const [ids, setIds] = useState([]);
    const [isInverse, setInverse] = useState(false);
    const [isPending, setPending] = useState(false);
    const inverse = useRef(false);
    useEffect(() => {
        inverse.current = isInverse;
    }, [isInverse]);
    useEffect(() => {
        if (inverse.current) {
            setInverse(false);
            setIds([]);
        }
    }, [filters]);
    const isChecked = (id) => {
        if (isInverse) {
            return !ids.includes(id);
        }
        return ids.includes(id);
    };
    const isMainChecked = useMemo(() => {
        if (isInverse) {
            return ids.length === 0;
        }
        return estates.every(estate => ids.includes(estate.id));
    }, [estates, ids, isInverse]);
    const isMainIndeterminate = useMemo(() => {
        if (isInverse) {
            return ids.length > 0 && ids.length < totalEntries;
        }
        return (estates.some(estate => ids.includes(estate.id)) &&
            !estates.every(estate => ids.includes(estate.id)));
    }, [estates, ids, isInverse]);
    const count = useMemo(() => {
        if (isInverse) {
            return totalEntries - ids.length;
        }
        return ids.length;
    }, [ids, isInverse, totalEntries]);
    const onChange = (event) => {
        event.stopPropagation();
        const id = +event.target.value;
        const index = ids.indexOf(id);
        if (index === -1) {
            const newIds = ids.concat(id);
            setIds(newIds);
        }
        else {
            const newIds = ids.slice();
            newIds.splice(index, 1);
            setIds(newIds);
        }
    };
    const onMainChange = useCallback(() => {
        if (isInverse) {
            if (isMainChecked) {
                setIds([]);
                setInverse(false);
            }
            else {
                setIds([]);
            }
        }
        else {
            if (isMainChecked) {
                const newIds = ids.filter(id => !estates.some(estate => estate.id === id));
                setIds(newIds);
            }
            else {
                const newIds = ids.concat(estates.filter(estate => !ids.includes(estate.id)).map(estate => estate.id));
                setIds(newIds);
            }
        }
    }, [estates, isMainChecked, ids, setIds, setInverse, isInverse]);
    const onCancel = useCallback(() => {
        setIds([]);
        setInverse(false);
    }, [setIds, setInverse]);
    const onCheckAll = useCallback(() => {
        setIds([]);
        setInverse(true);
    }, [setIds, setInverse]);
    const onExport = useCallback(async () => {
        setPending(true);
        try {
            const { files: { original } } = await service.getXLSX(ids, isInverse ? store.options.transformFilters(store.filters) : undefined);
            const link = document.createElement('a');
            link.download = original;
            link.href = original;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        catch (error) {
            console.error(error);
        }
        setPending(false);
    }, [ids, isInverse]);
    return {
        ids,
        count,
        isChecked,
        isMainChecked,
        isMainIndeterminate,
        onChange,
        onMainChange,
        onCheckAll,
        onCancel,
        onExport,
        isPending
    };
};
