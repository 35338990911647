import React, { useEffect, useRef } from 'react';
import { useStyles } from './spinner.styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
const Spinner = ({ isSending }) => {
    const classes = useStyles();
    const body = useRef(document.querySelector('body'));
    const wrapper = useRef(null);
    useEffect(() => {
        body.current.style.overflow = isSending ? 'hidden' : '';
        wrapper.current.style.display = isSending ? 'block' : 'none';
        return () => {
            body.current.style.overflow = '';
        };
    }, [isSending]);
    return (<div ref={wrapper} className={classes.wrapper}>
      <Paper className={classes.paper}>
        <Typography align="center" gutterBottom variant="h6">
          Подождите, пожалуйста
        </Typography>
        <CircularProgress />
      </Paper>
    </div>);
};
export default Spinner;
