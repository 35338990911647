var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, runInAction } from 'mobx';
import { createContext } from 'react';
import { NotificationsService } from '~modules/features/notifications';
import userStore from '~stores/user';
import { chatsStore } from '~stores/chats';
import { socketStore } from '~stores/socket';
export class NotificationsStore {
    constructor() {
        this.notifications = [];
        this.isLoaded = false;
        this.channel = null;
        this.getNotifications = async () => {
            if (this.isLoaded) {
                return null;
            }
            this.isLoaded = true;
            try {
                const result = await NotificationsService.getAll();
                runInAction(() => {
                    this.notifications = result;
                });
                const channel = socketStore.socket.channel(`notifications:employee_${userStore.employee.id}`, {});
                channel
                    .join()
                    .receive('ok', () => {
                    console.log('Joined to notifications channel');
                    this.channel = channel;
                    channel.on('new_notice', (notification) => {
                        runInAction(() => {
                            if (!this.notifications.find(e => e.id === notification.id)) {
                                this.notifications.unshift(notification);
                            }
                        });
                    });
                    channel.on('new_chat', (chat) => {
                        chatsStore.addChat(chat);
                    });
                })
                    .receive('error', response => {
                    console.error('Unable to join to notifications channel', response);
                });
            }
            catch (error) {
                //
            }
        };
        this.closeNotification = (id) => {
            if (this.channel) {
                this.channel
                    .push('close', {
                    id
                })
                    .receive('ok', () => {
                    this.notifications = this.notifications.filter(notification => notification.id !== id);
                })
                    .receive('error', response => {
                    console.error(response);
                });
            }
        };
        this.clearStore = () => {
            this.isLoaded = false;
            this.notifications = [];
        };
    }
}
__decorate([
    observable
], NotificationsStore.prototype, "notifications", void 0);
export const notificationsStore = new NotificationsStore();
export const NotificationsStoreContext = createContext(notificationsStore);
