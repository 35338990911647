import React from 'react';
import { constants } from 'router5';
import { RouterProvider, useRoute } from 'react-router5';
import { ErrorBoundary } from '~modules/ui/error-boundary';
import { router } from '~services/router';
import { ApplicationRouter404 } from './components/application-router-404';
const RenderComponents = (components, index) => {
    const Component = components[index];
    if (index < components.length - 1) {
        return (<ErrorBoundary>
        <Component>{RenderComponents(components, index + 1)}</Component>
      </ErrorBoundary>);
    }
    return (<ErrorBoundary>
      <Component />
    </ErrorBoundary>);
};
const Route = () => {
    const { route } = useRoute();
    if (!route) {
        return null;
    }
    if (route.name === constants.UNKNOWN_ROUTE) {
        return <ApplicationRouter404 />;
    }
    const components = route.components;
    return RenderComponents(components, 0);
};
export const ApplicationRouter = () => (<RouterProvider router={router}>
    <Route />
  </RouterProvider>);
