import request from '~services/request';
import userStore from '~stores/user';
export const RemindersService = {
    getAll: (offsets, limit) => request
        .get('/client_feed/index_reminders', {
        params: {
            employeeId: userStore.employee.id,
            limit,
            offsets: JSON.stringify(offsets)
        }
    })
        .then(response => response.data),
    getCount: () => request
        .get('/client_feed/count_reminders', {
        params: {
            employeeId: userStore.employee.id
        }
    })
        .then(response => response.data)
        .then(data => data.reduce((acc, value) => {
        acc[value.column] = value.count;
        return acc;
    }, [])),
    create: ({ estateId, leadId, deadlineTime, message }) => request
        .post(`/client_feed/records`, {
        feedRecord: {
            estateId,
            leadId
        },
        reminder: {
            deadlineTime,
            message
        },
        comment: {}
    })
        .then(response => response.data),
    createWithStatus: ({ status, estateId, leadId, deadlineTime, message }) => request
        .post(`/client_feed/create_with_object_status_update`, {
        objectStatus: status,
        feedRecord: {
            estateId,
            leadId
        },
        reminder: {
            deadlineTime,
            message
        }
    })
        .then(response => response.data),
    update: (id, { estateId, leadId, deadlineTime, message }) => request
        .put(`/client_feed/records/${id}`, {
        feedRecord: {
            estateId,
            leadId
        },
        reminder: {
            deadlineTime,
            message
        },
        comment: {}
    })
        .then(response => response.data)
};
