import { DealsSettingsRoutes } from './views/deals-settings/deals-settings.routes';
import { configStore } from '~stores/config';
export const PublicRoutes = [
    {
        name: 'dealssettings',
        path: `/${process.env.CONFIG_ROUTE}`,
        component: () => import('./public').then(m => m.Public),
        onActivate: configStore.getConfig,
        children: DealsSettingsRoutes
    }
];
