var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action } from 'mobx';
export class FormGroup {
    constructor(group) {
        this.setName = (name) => {
            Object.keys(this.$).forEach(key => {
                this.$[key].setName(`${name ? `${name}.` : name}${key}`);
            });
        };
        this.toJS = () => {
            const result = {};
            Object.keys(this.$).forEach(key => {
                result[key] = this.$[key].toJS();
            });
            return result;
        };
        this.$ = group;
    }
    validate() {
        let result = true;
        Object.keys(this.$).forEach(key => {
            const isValid = this.$[key].validate();
            result = result && isValid;
        });
        return result;
    }
    setTouched(isTouched) {
        Object.keys(this.$).forEach(key => {
            this.$[key].setTouched(isTouched);
        });
    }
    setDirty(isDirty) {
        Object.keys(this.$).forEach(key => {
            this.$[key].setDirty(isDirty);
        });
    }
}
__decorate([
    observable
], FormGroup.prototype, "$", void 0);
__decorate([
    action.bound
], FormGroup.prototype, "setTouched", null);
__decorate([
    action.bound
], FormGroup.prototype, "setDirty", null);
