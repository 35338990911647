import './application-router-404.module.scss';
import React, { memo } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from '~modules/ui/link';
export const ApplicationRouter404 = memo(() => {
    return (<main styleName="not-found">
      <Typography variant="h1" align="center">
        404
      </Typography>
      <Typography variant="h4" align="center">
        Страница не найдена
      </Typography>
      <Button styleName="not-found__button" color="primary" component={Link} routeName="home">
        Перейти на главную
      </Button>
    </main>);
});
