import React, { memo } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import locale from './ru';
locale(moment);
moment.locale('ru');
class UTCUtils extends MomentUtils {
    format(value, formatString) {
        return moment(value).format(formatString);
    }
}
export const ApplicationDatePicker = memo(({ children }) => {
    return (<MuiPickersUtilsProvider utils={UTCUtils} libInstance={moment} locale="ru">
      {children}
    </MuiPickersUtilsProvider>);
});
