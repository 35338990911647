import { createStyles, makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(() => createStyles({
    wrapper: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: 999999,
        backgroundColor: 'rgba(0, 0, 0, 0.16)',
        display: 'none'
    },
    paper: {
        maxWidth: '300px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '32px',
        '& .MuiTypography-gutterBottom': {
            marginBottom: '16px'
        },
        '& .MuiCircularProgress-root': {
            margin: '0 auto',
            display: 'block'
        }
    }
}));
