import './estates-form.module.scss';
import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserStore } from '~stores/user';
import { observer } from 'mobx-react-lite';
import { Validators } from '~modules/forms';
import { EmployeesService } from '~modules/features/employees';
import { LocationsService } from '~modules/features/locations';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Icon } from '~modules/ui/icon';
import { Dropzone } from '~modules/ui/dropzone';
import { AutocompleteFromAPI } from '~modules/ui/autocomplete-from-api';
import RadioToggle from '~ui/RadioToggle';
import { AutocompleteFromDadata } from '~modules/ui/autocomplete-from-dadata';
import FormattedTextField, { PriceFormatter } from '~ui/FormattedTextField';
import AD_TYPES from '~constants/types/ad';
import APARTMENT_TYPES from '~constants/types/apartment';
import COMMERCIAL_TYPES from '~constants/types/commercial';
import CONDITION_TYPES from '~constants/types/condition';
import DEAL_TYPES from '~constants/types/deal';
import HOUSE_TYPES from '~constants/types/house';
import MATERIAL_TYPES from '~constants/types/material';
import PROPERTY_TYPES from '~constants/types/property';
import SECTION_TYPES from '~constants/types/section';
import STEAD_TYPES from '~constants/types/stead';
import WALL_MATERIAL_TYPES from '~constants/types/wall-material';
import WC_TYPES from '~constants/types/wc';
import { getErrors, scrollToError } from './helpers/scroll-to-error';
import { EEstateBuildingState, EEstateExport } from '../../estates.types';
const BUILDING_STATE = Object.values(EEstateBuildingState);
import { STATUS_NOT_PROCESSED, STATUS_FIRST_CONTACT, STATUS_SHOWS } from '../../estates.constants';
const STATUSES_CHANGEABLE = [STATUS_NOT_PROCESSED, STATUS_FIRST_CONTACT];
const SquareProps = {
    endAdornment: (<InputAdornment position="end">
      м<sup>2</sup>
    </InputAdornment>)
};
const LandSquareProps = {
    endAdornment: (<InputAdornment position="end">
      <span>сот</span>
    </InputAdornment>)
};
export const EstatesForm = memo(observer(({ onCancel, onSubmit, estate, withStatus, isSending, exportTo, form, isRequiredMine, appendOwnerPhone, removeOwnerPhone, removeThirdPartyAgentPhone, appendThirdPartyAgentPhone }) => {
    const { t } = useTranslation('translations');
    const userStore = useUserStore();
    const status$ = form.$.estate.$.status.value;
    const sectionName$ = form.$.estate.$.sectionName.value;
    const statuses = useMemo(() => {
        if (estate) {
            switch (estate.general.status) {
                case STATUS_NOT_PROCESSED:
                    return [STATUS_NOT_PROCESSED, STATUS_FIRST_CONTACT, STATUS_SHOWS];
                case STATUS_FIRST_CONTACT:
                    return [STATUS_FIRST_CONTACT, STATUS_SHOWS];
            }
            return [estate.general.status];
        }
        return [STATUS_NOT_PROCESSED, STATUS_FIRST_CONTACT, STATUS_SHOWS];
    }, [estate]);
    const [step, setStep] = useState(status$ === STATUS_NOT_PROCESSED ? 0 : status$ === STATUS_FIRST_CONTACT ? 1 : 2);
    const statusHelper = useMemo(() => {
        if (estate ? status$ !== estate.general.status : status$ !== STATUS_NOT_PROCESSED) {
            return 'При сохранении понадобится создать напоминание';
        }
        return '';
    }, [estate, status$]);
    const [options, setOptions] = useState({
        district: false,
        subdistrict: false
    });
    const onOptionsChange = useCallback((name) => (_, o) => {
        setOptions({
            ...options,
            [name]: o.length > 0
        });
    }, [options]);
    useEffect(() => {
        Object.keys(options).forEach(key => {
            if (options[key] && status$ !== STATUS_NOT_PROCESSED) {
                form.$.estate.$[key].setValidator(value => Validators.requiredTrue(value));
            }
            else {
                form.$.estate.$[key].removeValidator();
            }
        });
    }, [form, sectionName$, status$, options]);
    useEffect(() => {
        if (!form.$.estate.$.country.value) {
            form.$.estate.$.region.setValue(null);
            setOptions(options => ({
                ...options,
                region: false
            }));
        }
    }, [form.$.estate.$.country.value]);
    useEffect(() => {
        if (!form.$.estate.$.region.value) {
            form.$.estate.$.city.setValue(null);
            setOptions(options => ({
                ...options,
                city: false
            }));
        }
    }, [form.$.estate.$.region.value]);
    useEffect(() => {
        if (!form.$.estate.$.city.value) {
            form.$.estate.$.district.setValue(null);
            setOptions(options => ({
                ...options,
                district: false
            }));
        }
    }, [form.$.estate.$.city.value]);
    useEffect(() => {
        if (!form.$.estate.$.district.value) {
            form.$.estate.$.subdistrict.setValue(null);
            setOptions(options => ({
                ...options,
                subdistrict: false
            }));
        }
    }, [form.$.estate.$.district.value]);
    useEffect(() => {
        if (status$ !== STATUS_NOT_PROCESSED) {
            form.validate();
            const errors = {
                0: getErrors(0, form),
                1: getErrors(1, form),
                2: getErrors(2, form)
            };
            const errorsStep = errors[0].length
                ? 0
                : errors[1].length
                    ? 1
                    : errors[2].length
                        ? 2
                        : null;
            if (errorsStep !== null) {
                setStep(errorsStep);
            }
        }
    }, []);
    const onStepClick = useCallback((index) => () => {
        setStep(index);
    }, []);
    const onFormSubmit = useCallback(async (event) => {
        event.preventDefault();
        form.validate();
        if (form.$.estate.validate() && form.$[form.$.estate.$.sectionName.value].validate()) {
            onSubmit(form);
        }
        else {
            scrollToError(step, setStep, form);
        }
    }, [form, step, setStep]);
    const isEmployeeVisible = useMemo(() => {
        const roles = Object.keys(userStore.roles);
        if ((roles.length === 1 && roles.includes('agent')) || estate) {
            return false;
        }
        return true;
    }, [estate]);
    return (<form onSubmit={onFormSubmit} noValidate>
          <Stepper nonLinear activeStep={step} orientation="vertical">
            <Step>
              <StepButton onClick={onStepClick(0)}>Создание</StepButton>

              <StepContent>
                {withStatus && (<TextField variant="outlined" margin="normal" select label="Статус" required {...form.$.estate.$.status.input} error={!!form.$.estate.$.status.error} helperText={form.$.estate.$.status.error || statusHelper} disabled={!STATUSES_CHANGEABLE.includes(estate?.general.status || STATUS_NOT_PROCESSED)}>
                    {statuses.map(option => (<MenuItem key={option} value={option}>
                        {t(`estate.status.${option}`)}
                      </MenuItem>))}
                  </TextField>)}

                <FormattedTextField variant="outlined" margin="normal" formatter={PriceFormatter} label="Цена" required {...form.$.estate.$.price.input} error={form.$.estate.$.price.error}/>

                <Typography styleName="form__subtitle" variant="h6" gutterBottom>
                  Тип недвижимости
                </Typography>

                <TextField variant="outlined" margin="normal" select label="Раздел" required {...form.$.estate.$.sectionName.input} error={!!form.$.estate.$.sectionName.error} helperText={form.$.estate.$.sectionName.error}>
                  {SECTION_TYPES.map(option => (<MenuItem key={option} value={option}>
                      {t(`estate.section.${option}`)}
                    </MenuItem>))}
                </TextField>
                {sectionName$ === 'commercial' && (<TextField variant="outlined" margin="normal" select label="Тип сделки" required {...form.$.estate.$.dealType.input} error={!!form.$.estate.$.dealType.error} helperText={form.$.estate.$.dealType.error}>
                    {DEAL_TYPES.map(option => (<MenuItem key={option} value={option}>
                        {t(`estate.deal.${option}`)}
                      </MenuItem>))}
                  </TextField>)}
                {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" select label="Тип недвижимости" required {...form.$.apartment.$.propertyType.input} error={!!form.$.apartment.$.propertyType.error} helperText={form.$.apartment.$.propertyType.error}>
                    {PROPERTY_TYPES.map(option => (<MenuItem key={option} value={option}>
                        {t(`estate.property.${option}`)}
                      </MenuItem>))}
                  </TextField>)}
                {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" select label="Тип квартиры" required={form.$.estate.$.status.value !== STATUS_NOT_PROCESSED} {...form.$.apartment.$.typeObject.input} error={!!form.$.apartment.$.typeObject.error} helperText={form.$.apartment.$.typeObject.error}>
                    {APARTMENT_TYPES.map(option => (<MenuItem key={option} value={option}>
                        {t(`estate.apartment.${option}`)}
                      </MenuItem>))}
                  </TextField>)}
                {sectionName$ === 'house' && (<TextField variant="outlined" margin="normal" select label="Тип недвижимости" required {...form.$.house.$.propertyType.input} error={!!form.$.house.$.propertyType.error} helperText={form.$.house.$.propertyType.error}>
                    {PROPERTY_TYPES.map(option => (<MenuItem key={option} value={option}>
                        {t(`estate.property.${option}`)}
                      </MenuItem>))}
                  </TextField>)}
                {sectionName$ === 'house' && (<TextField variant="outlined" margin="normal" select label="Вид объекта" required={form.$.estate.$.status.value !== STATUS_NOT_PROCESSED} {...form.$.house.$.typeObject.input} error={!!form.$.house.$.typeObject.error} helperText={form.$.house.$.typeObject.error}>
                    {HOUSE_TYPES.map(option => (<MenuItem key={option} value={option}>
                        {t(`estate.house.${option}`)}
                      </MenuItem>))}
                  </TextField>)}
                {sectionName$ === 'stead' && (<TextField variant="outlined" margin="normal" select label="Категория земель" required={form.$.estate.$.status.value !== STATUS_NOT_PROCESSED} {...form.$.stead.$.landCategory.input} error={!!form.$.stead.$.landCategory.error} helperText={form.$.stead.$.landCategory.error}>
                    {STEAD_TYPES.map(option => (<MenuItem key={option} value={option}>
                        {t(`estate.stead.${option}`)}
                      </MenuItem>))}
                  </TextField>)}
                {sectionName$ === 'commercial' && (<TextField variant="outlined" margin="normal" select label="Вид объекта" required={form.$.estate.$.status.value !== STATUS_NOT_PROCESSED} {...form.$.commercial.$.typeObject.input} error={!!form.$.commercial.$.typeObject.error} helperText={form.$.commercial.$.typeObject.error}>
                    {COMMERCIAL_TYPES.map(option => (<MenuItem key={option} value={option}>
                        {t(`estate.commercial.${option}`)}
                      </MenuItem>))}
                  </TextField>)}
                <TextField variant="outlined" margin="normal" select label="Тип объявления" required={status$ !== STATUS_NOT_PROCESSED || exportTo === EEstateExport.YANDEX_REALTY} {...form.$.estate.$.adType.input} error={!!form.$.estate.$.adType.error} helperText={form.$.estate.$.adType.error}>
                  {AD_TYPES.map(option => (<MenuItem key={option} value={option}>
                      {t(`estate.ad.${option}`)}
                    </MenuItem>))}
                </TextField>
                {form.$.estate.$.adType.value === 'exclusive' && (<Dropzone label="Фото договора" required name={form.$.estate.$.estateContracts.name} value={form.$.estate.$.estateContracts.value} onChange={form.$.estate.$.estateContracts.onChange} isMultiple error={!!form.$.estate.$.estateContracts.error}/>)}
                <TextField variant="outlined" margin="normal" label="Кадастровый номер" name="estate.cadastralNumber" {...form.$.estate.$.cadastralNumber.input}/>

                

                <Typography styleName="form__subtitle" variant="h6" gutterBottom>
                  Клиент
                </Typography>

                <FormControlLabel control={<Checkbox value="fromThirdPartyAgent" color="primary" onChange={form.$.estate.$.fromThirdPartyAgent.onChange} checked={form.$.estate.$.fromThirdPartyAgent.value}/>} label="Объект агента из другого агентства недвижимости"/>

                {form.$.estate.$.fromThirdPartyAgent.value && (<>
                    <TextField variant="outlined" margin="normal" label="ФИО агента" required={form.$.estate.$.fromThirdPartyAgent.value} {...form.$.estate.$.thirdPartyAgentName.input} error={!!form.$.estate.$.thirdPartyAgentName.error} helperText={form.$.estate.$.thirdPartyAgentName.error}/>

                    {form.$.estate.$.thirdPartyAgentPhone.$.map((control, index) => (<div key={index} styleName="form__phone">
                        <TextField variant="outlined" margin="normal" label="Телефон агента" required={form.$.estate.$.fromThirdPartyAgent.value} {...control.input} error={!!control.error} helperText={control.error}/>
                        {form.$.estate.$.thirdPartyAgentPhone.$.length > 1 && (<IconButton styleName="form__remove" onClick={removeThirdPartyAgentPhone(index)}>
                            <Icon name="close-circle"/>
                          </IconButton>)}
                      </div>))}

                    <Button styleName="form__add" type="button" color="primary" variant="contained" onClick={appendThirdPartyAgentPhone}>
                      Добавить
                    </Button>
                  </>)}

                <TextField variant="outlined" margin="normal" label="ФИО собственника" required={!form.$.estate.$.fromThirdPartyAgent.value} {...form.$.estate.$.ownerName.input} error={!!form.$.estate.$.ownerName.error} helperText={form.$.estate.$.ownerName.error}/>
                {form.$.estate.$.ownerPhone.$.map((control, index) => (<div key={index} styleName="form__phone">
                    <TextField variant="outlined" margin="normal" label="Телефон собственника" required={!form.$.estate.$.fromThirdPartyAgent.value} {...control.input} error={!!control.error} helperText={control.error}/>
                    {form.$.estate.$.ownerPhone.$.length > 1 && (<IconButton styleName="form__remove" onClick={removeOwnerPhone(index)}>
                        <Icon name="close-circle"/>
                      </IconButton>)}
                  </div>))}

                <Button styleName="form__add" type="button" color="primary" variant="contained" onClick={appendOwnerPhone}>
                  Добавить
                </Button>

                <TextField variant="outlined" margin="normal" label="Email собственника" {...form.$.estate.$.ownerEmail.input} error={!!form.$.estate.$.ownerEmail.error} helperText={form.$.estate.$.ownerEmail.error}/>

                

                <Typography styleName="form__subtitle" variant="h6" gutterBottom>
                  Местоположение
                </Typography>

                <AutocompleteFromAPI variant="outlined" margin="normal" api={LocationsService.getCountries} label="Страна" required={status$ !== STATUS_NOT_PROCESSED} {...form.$.estate.$.country.input} error={form.$.estate.$.country.error}/>

                <AutocompleteFromAPI variant="outlined" margin="normal" api={LocationsService.getRegions} params={{
        countryId: form.$.estate.$.country.value && form.$.estate.$.country.value.id
    }} withParent parent={form.$.estate.$.country.value && form.$.estate.$.country.value.id} label="Область" required={!!form.$.estate.$.region.validator} disabled={!form.$.estate.$.country.value} {...form.$.estate.$.region.input} error={form.$.estate.$.region.error}/>

                <AutocompleteFromAPI variant="outlined" margin="normal" api={LocationsService.getCities} params={{
        regionId: form.$.estate.$.region.value && form.$.estate.$.region.value.id
    }} withParent parent={form.$.estate.$.region.value && form.$.estate.$.region.value.id} label="Населенный пункт" required={!!form.$.estate.$.city.validator} disabled={!form.$.estate.$.region.value} {...form.$.estate.$.city.input} error={form.$.estate.$.city.error}/>

                <AutocompleteFromAPI variant="outlined" margin="normal" api={LocationsService.getDistricts} params={{ cityId: form.$.estate.$.city.value && form.$.estate.$.city.value.id }} withParent parent={form.$.estate.$.city.value && form.$.estate.$.city.value.id} label="Район" required={!!form.$.estate.$.district.validator} disabled={!form.$.estate.$.city.value && !options.district} {...form.$.estate.$.district.input} error={form.$.estate.$.district.error} onOptionsChange={onOptionsChange('district')}/>

                <AutocompleteFromAPI variant="outlined" margin="normal" api={LocationsService.getSubdistricts} params={{
        districtId: form.$.estate.$.district.value && form.$.estate.$.district.value.id
    }} withParent parent={form.$.estate.$.district.value && form.$.estate.$.district.value.id} label="Подрайон" required={!!form.$.estate.$.subdistrict.validator} disabled={!form.$.estate.$.district.value && !options.subdistrict} {...form.$.estate.$.subdistrict.input} error={form.$.estate.$.subdistrict.error} onOptionsChange={onOptionsChange('subdistrict')}/>

                {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" label="Жилой комплекс" {...form.$.estate.$.developer.input} helperText={exportTo === EEstateExport.YANDEX_REALTY
        ? 'Заполните поле, если знаете название ЖК. В противном случае объект может не попасть в автовыгрузку.'
        : undefined}/>)}

                {sectionName$ === 'house' && (<TextField variant="outlined" margin="normal" type="number" label="Расстояние до города" {...form.$.house.$.distanceToCity.input} error={!!form.$.house.$.distanceToCity.error} helperText={form.$.house.$.distanceToCity.error}/>)}
                {sectionName$ === 'house' &&
        (form.$.house.$.propertyType.value === 'new_building' ||
            form.$.house.$.propertyType.value === 'shareholder') && (<TextField variant="outlined" margin="normal" label="Жилой комплекс" required={exportTo === EEstateExport.YANDEX_REALTY} {...form.$.estate.$.developer.input} error={!!form.$.estate.$.developer.error} helperText={form.$.estate.$.developer.error}/>)}

                <AutocompleteFromDadata variant="outlined" margin="normal" label="Улица" required={status$ !== STATUS_NOT_PROCESSED || exportTo === EEstateExport.YANDEX_REALTY} bound="street" countries={form.$.estate.$.country.value && [form.$.estate.$.country.value.title]} regions={form.$.estate.$.region.value && [form.$.estate.$.region.value.title]} cities={form.$.estate.$.city.value && [form.$.estate.$.city.value.title]} {...form.$.estate.$.street.input} error={form.$.estate.$.street.error}/>

                {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" label="Номер дома" required={status$ !== STATUS_NOT_PROCESSED || exportTo === EEstateExport.YANDEX_REALTY} {...form.$.apartment.$.houseNumber.input} error={!!form.$.apartment.$.houseNumber.error} helperText={form.$.apartment.$.houseNumber.error}/>)}
                {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" label="Номер дома для выгрузки" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.apartment.$.houseNumberUploading.input} error={!!form.$.apartment.$.houseNumberUploading.error} helperText={form.$.apartment.$.houseNumberUploading.error}/>)}

                {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" label="Корпус / строение" required={exportTo === EEstateExport.YANDEX_REALTY} {...form.$.apartment.$.building.input} error={!!form.$.apartment.$.building.error} helperText={form.$.apartment.$.building.error}/>)}
                {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" label="Номер квартиры" required={status$ !== STATUS_NOT_PROCESSED} {...form.$.apartment.$.apartmentNumber.input} error={!!form.$.apartment.$.apartmentNumber.error} helperText={form.$.apartment.$.apartmentNumber.error}/>)}

                {sectionName$ === 'house' && (<TextField variant="outlined" margin="normal" label="Номер дома" required={status$ !== STATUS_NOT_PROCESSED || exportTo === EEstateExport.YANDEX_REALTY} {...form.$.house.$.houseNumber.input} error={!!form.$.house.$.houseNumber.error} helperText={form.$.house.$.houseNumber.error}/>)}
                {sectionName$ === 'house' && (<TextField variant="outlined" margin="normal" label="Номер дома для выгрузки" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.house.$.houseNumberUploading.input} error={!!form.$.house.$.houseNumberUploading.error} helperText={form.$.house.$.houseNumberUploading.error}/>)}

                {sectionName$ === 'stead' && (<TextField variant="outlined" margin="normal" type="number" label="Расстояние до города" {...form.$.stead.$.distanceToCity.input}/>)}
                {sectionName$ === 'stead' && (<TextField variant="outlined" margin="normal" label="Номер участка" required={status$ !== STATUS_NOT_PROCESSED || exportTo === EEstateExport.YANDEX_REALTY} {...form.$.stead.$.number.input} error={!!form.$.stead.$.number.error} helperText={form.$.stead.$.number.error}/>)}

                {sectionName$ === 'commercial' && (<TextField variant="outlined" margin="normal" label="Номер дома" required={status$ !== STATUS_NOT_PROCESSED || exportTo === EEstateExport.YANDEX_REALTY} {...form.$.commercial.$.houseNumber.input} error={!!form.$.commercial.$.houseNumber.error} helperText={form.$.commercial.$.houseNumber.error}/>)}
                {sectionName$ === 'commercial' && (<TextField variant="outlined" margin="normal" label="Номер дома для выгрузки" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.commercial.$.houseNumberUploading.input} error={!!form.$.commercial.$.houseNumberUploading.error} helperText={form.$.commercial.$.houseNumberUploading.error}/>)}
                {sectionName$ === 'commercial' && (<TextField variant="outlined" margin="normal" label="Корпус / строение" {...form.$.commercial.$.building.input}/>)}

                {isEmployeeVisible && (<>
                    <Typography styleName="form__subtitle" variant="h6" gutterBottom>
                      Агент
                    </Typography>

                    <AutocompleteFromAPI variant="outlined" margin="normal" api={EmployeesService.search} params={{ roles: ['agent'] }} label="Агент" required isClearable={false} {...form.$.estate.$.employee.input} error={form.$.estate.$.employee.error}/>
                  </>)}
              </StepContent>
            </Step>
            <Step>
              <StepButton onClick={onStepClick(1)}>Первичный контакт</StepButton>

              <StepContent>
                {!!sectionName$ && (<>
                    {sectionName$ === 'apartment' &&
        (form.$.apartment.$.propertyType.value === 'new_building' ||
            form.$.apartment.$.propertyType.value === 'shareholder') && (<>
                          <TextField variant="outlined" margin="normal" select label="Стадия строительства" required={exportTo === EEstateExport.YANDEX_REALTY} {...form.$.apartment.$.buildingState.input} error={!!form.$.apartment.$.buildingState.error} helperText={form.$.apartment.$.buildingState.error}>
                            {BUILDING_STATE.map(option => (<MenuItem key={option} value={option}>
                                {t(`estate.buildingState.${option}`)}
                              </MenuItem>))}
                          </TextField>
                          <TextField variant="outlined" margin="normal" select label="Квартал сдачи дома" required={exportTo === EEstateExport.YANDEX_REALTY} {...form.$.apartment.$.constructionQuater.input} error={!!form.$.apartment.$.constructionQuater.error} helperText={form.$.apartment.$.constructionQuater.error}>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                          </TextField>
                        </>)}
                    {sectionName$ === 'house' &&
        (form.$.house.$.propertyType.value === 'new_building' ||
            form.$.house.$.propertyType.value === 'shareholder') && (<>
                          <TextField variant="outlined" margin="normal" select label="Стадия строительства" required={exportTo === EEstateExport.YANDEX_REALTY} {...form.$.house.$.buildingState.input} error={!!form.$.house.$.buildingState.error} helperText={form.$.house.$.buildingState.error}>
                            {BUILDING_STATE.map(option => (<MenuItem key={option} value={option}>
                                {t(`estate.buildingState.${option}`)}
                              </MenuItem>))}
                          </TextField>
                          <TextField variant="outlined" margin="normal" select label="Квартал сдачи дома" required={exportTo === EEstateExport.YANDEX_REALTY} {...form.$.house.$.constructionQuater.input} error={!!form.$.house.$.constructionQuater.error} helperText={form.$.house.$.constructionQuater.error}>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                          </TextField>
                        </>)}

                    <Typography styleName="form__subtitle" variant="h6" gutterBottom>
                      Площадь и технические характеристики
                    </Typography>

                    {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" type="number" label="Общая площадь" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.apartment.$.fullSquare.input} error={!!form.$.apartment.$.fullSquare.error} helperText={form.$.apartment.$.fullSquare.error} InputProps={SquareProps}/>)}
                    {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" type="number" label="Жилая площадь" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.apartment.$.livingSquare.input} error={!!form.$.apartment.$.livingSquare.error} helperText={form.$.apartment.$.livingSquare.error} InputProps={SquareProps}/>)}
                    {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" type="number" label="Площадь кухни" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.apartment.$.kitchenSquare.input} error={!!form.$.apartment.$.kitchenSquare.error} helperText={form.$.apartment.$.kitchenSquare.error} InputProps={SquareProps}/>)}
                    {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" type="number" label="Этаж помещения" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.apartment.$.floor.input} error={!!form.$.apartment.$.floor.error} helperText={form.$.apartment.$.floor.error}/>)}
                    {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" type="number" label="Этажность дома" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.apartment.$.houseStorey.input} error={!!form.$.apartment.$.houseStorey.error} helperText={form.$.apartment.$.houseStorey.error}/>)}
                    {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" select name="apartment.houseType" label="Тип дома" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.apartment.$.houseType.input} error={!!form.$.apartment.$.houseType.error} helperText={form.$.apartment.$.houseType.error}>
                        {MATERIAL_TYPES.map(option => (<MenuItem key={option} value={option}>
                            {t(`estate.material.${option}`)}
                          </MenuItem>))}
                      </TextField>)}
                    {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" select name="apartment.conditionObject" label="Состояние объекта" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.apartment.$.conditionObject.input} error={!!form.$.apartment.$.conditionObject.error} helperText={form.$.apartment.$.conditionObject.error}>
                        {CONDITION_TYPES.map(option => (<MenuItem key={option} value={option}>
                            {t(`estate.condition.${option}`)}
                          </MenuItem>))}
                      </TextField>)}
                    {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" type="number" label="Год постройки" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.apartment.$.constructionYear.input} error={!!form.$.apartment.$.constructionYear.error} helperText={form.$.apartment.$.constructionYear.error}/>)}
                    {sectionName$ === 'apartment' && (<TextField variant="outlined" margin="normal" select label="Санузел" required={status$ !== STATUS_NOT_PROCESSED && isRequiredMine} {...form.$.apartment.$.wc.input} error={!!form.$.apartment.$.wc.error} helperText={form.$.apartment.$.wc.error}>
                        {WC_TYPES.map(option => (<MenuItem key={option} value={option}>
                            {t(`estate.wc.${option}`)}
                          </MenuItem>))}
                      </TextField>)}
                    {sectionName$ === 'apartment' && (<RadioToggle label="Балкон" {...form.$.apartment.$.balcony.input}/>)}
                    {sectionName$ === 'apartment' && (<RadioToggle label="Лоджия" {...form.$.apartment.$.loggia.input}/>)}

                    {sectionName$ === 'house' && (<TextField variant="outlined" margin="normal" type="number" label="Общая площадь" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.house.$.fullSquare.input} error={!!form.$.house.$.fullSquare.error} helperText={form.$.house.$.fullSquare.error} InputProps={SquareProps}/>)}
                    {sectionName$ === 'house' && (<TextField variant="outlined" margin="normal" type="number" label="Жилая площадь" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.house.$.livingSquare.input} error={!!form.$.house.$.livingSquare.error} helperText={form.$.house.$.livingSquare.error} InputProps={SquareProps}/>)}
                    {sectionName$ === 'house' && (<TextField variant="outlined" margin="normal" type="number" label="Площадь кухни" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.house.$.kitchenSquare.input} error={!!form.$.house.$.kitchenSquare.error} helperText={form.$.house.$.kitchenSquare.error} InputProps={SquareProps}/>)}
                    {sectionName$ === 'house' && (<TextField variant="outlined" margin="normal" type="number" name="house.landSquare" label="Площадь участка" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.house.$.landSquare.input} error={!!form.$.house.$.landSquare.error} helperText={form.$.house.$.landSquare.error} InputProps={LandSquareProps}/>)}
                    {sectionName$ === 'house' && (<TextField variant="outlined" margin="normal" type="number" label="Этажность дома" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.house.$.houseStorey.input} error={!!form.$.house.$.houseStorey.error} helperText={form.$.house.$.houseStorey.error}/>)}
                    {sectionName$ === 'house' && (<TextField variant="outlined" margin="normal" type="number" label="Число комнат" required={status$ !== STATUS_NOT_PROCESSED && isRequiredMine} {...form.$.house.$.roomsAmount.input} error={!!form.$.house.$.roomsAmount.error} helperText={form.$.house.$.roomsAmount.error}/>)}
                    {sectionName$ === 'house' && (<TextField variant="outlined" margin="normal" select label="Материал стен" {...form.$.house.$.wallMaterial.input}>
                        {WALL_MATERIAL_TYPES.map(option => (<MenuItem key={option} value={option}>
                            {t(`estate.material.${option}`)}
                          </MenuItem>))}
                      </TextField>)}
                    {sectionName$ === 'house' && (<TextField variant="outlined" margin="normal" select label="Состояние объекта" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.house.$.conditionObject.input} error={!!form.$.house.$.conditionObject.error} helperText={form.$.house.$.conditionObject.error}>
                        {CONDITION_TYPES.map(option => (<MenuItem key={option} value={option}>
                            {t(`estate.condition.${option}`)}
                          </MenuItem>))}
                      </TextField>)}
                    {sectionName$ === 'house' && (<TextField variant="outlined" margin="normal" type="number" name="house.constructionYear" label="Год постройки" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.house.$.constructionYear.input} error={!!form.$.house.$.constructionYear.error} helperText={form.$.house.$.constructionYear.error}/>)}
                    {sectionName$ === 'house' && (<TextField variant="outlined" margin="normal" select label="Санузел" required={status$ !== STATUS_NOT_PROCESSED && isRequiredMine} {...form.$.house.$.wc.input} error={!!form.$.house.$.wc.error} helperText={form.$.house.$.wc.error}>
                        {WC_TYPES.map(option => (<MenuItem key={option} value={option}>
                            {t(`estate.wc.${option}`)}
                          </MenuItem>))}
                      </TextField>)}

                    {sectionName$ === 'stead' && (<TextField variant="outlined" margin="normal" type="number" label="Площадь участка" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.stead.$.landSquare.input} error={!!form.$.stead.$.landSquare.error} helperText={form.$.stead.$.landSquare.error} InputProps={LandSquareProps}/>)}

                    {sectionName$ === 'commercial' && (<TextField variant="outlined" margin="normal" type="number" label="Общая площадь" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.commercial.$.fullSquare.input} error={!!form.$.commercial.$.fullSquare.error} helperText={form.$.commercial.$.fullSquare.error} InputProps={SquareProps}/>)}
                    {sectionName$ === 'commercial' && (<TextField variant="outlined" margin="normal" type="number" label="Этаж помещения" required={exportTo === EEstateExport.YANDEX_REALTY} {...form.$.commercial.$.floor.input} error={!!form.$.commercial.$.floor.error} helperText={form.$.commercial.$.floor.error}/>)}
                    {sectionName$ === 'commercial' && (<TextField variant="outlined" margin="normal" type="number" label="Этажность дома" {...form.$.commercial.$.houseStorey.input}/>)}
                    {sectionName$ === 'commercial' && (<TextField variant="outlined" margin="normal" select label="Состояние объекта" required={(status$ !== STATUS_NOT_PROCESSED && isRequiredMine) ||
        exportTo === EEstateExport.YANDEX_REALTY} {...form.$.commercial.$.conditionObject.input} error={!!form.$.commercial.$.conditionObject.error} helperText={form.$.commercial.$.conditionObject.error}>
                        {CONDITION_TYPES.map(option => (<MenuItem key={option} value={option}>
                            {t(`estate.condition.${option}`)}
                          </MenuItem>))}
                      </TextField>)}

                    {(sectionName$ === 'house' || sectionName$ === 'stead') && (<RadioToggle label="Канализация" {...form.$.estate.$.sewerage.input}/>)}
                    {(sectionName$ === 'house' || sectionName$ === 'stead') && (<RadioToggle label="Газ" {...form.$.estate.$.gas.input}/>)}
                    {(sectionName$ === 'house' || sectionName$ === 'stead') && (<RadioToggle label="Вода" {...form.$.estate.$.water.input}/>)}
                    {(sectionName$ === 'house' || sectionName$ === 'stead') && (<RadioToggle label="Электричество" {...form.$.estate.$.electricity.input}/>)}
                  </>)}

                <Typography styleName="form__subtitle" variant="h6" gutterBottom>
                  Дополнительная информация
                </Typography>

                <TextField variant="outlined" margin="normal" multiline name="estate.description" label="Описание" required={status$ !== STATUS_NOT_PROCESSED && isRequiredMine} rows={3} rowsMax={Infinity} {...form.$.estate.$.description.input} error={!!form.$.estate.$.description.error} helperText={form.$.estate.$.description.error || 'Будет видно всем'}/>

                <RadioToggle label="Интернет" {...form.$.estate.$.internet.input}/>

                {(sectionName$ === 'apartment' ||
        sectionName$ === 'house' ||
        sectionName$ === 'commercial') && (<RadioToggle label="Охрана" {...form.$.estate.$.security.input}/>)}

                {(sectionName$ === 'apartment' || sectionName$ === 'commercial') && (<RadioToggle label="Закрытая территория" {...form.$.estate.$.closedTerritory.input}/>)}
                {(sectionName$ === 'apartment' || sectionName$ === 'commercial') && (<RadioToggle label="Подземная паркова" {...form.$.estate.$.undergroundParking.input}/>)}

                {sectionName$ === 'apartment' && (<RadioToggle label="Подходит под ипотеку" {...form.$.apartment.$.mortgage.input}/>)}

                {sectionName$ === 'house' && (<RadioToggle label="Подходит под ипотеку" {...form.$.house.$.mortgage.input}/>)}

                {(sectionName$ === 'apartment' ||
        sectionName$ === 'house' ||
        sectionName$ === 'commercial') && (<TextField variant="outlined" margin="normal" label="Ссылка на 3-D тур" {...form.$.estate.$.link3d.input} error={!!form.$.estate.$.link3d.error} helperText={form.$.estate.$.link3d.error}/>)}

                {(sectionName$ === 'apartment' ||
        sectionName$ === 'house' ||
        sectionName$ === 'commercial') && (<TextField variant="outlined" margin="normal" label="Ссылка на YouTube" {...form.$.estate.$.linkYoutube.input} error={!!form.$.estate.$.linkYoutube.error} helperText={form.$.estate.$.linkYoutube.error}/>)}

                <TextField variant="outlined" margin="normal" multiline label="Комментарий" rows={3} rowsMax={Infinity} {...form.$.estate.$.comment.input}/>
              </StepContent>
            </Step>
            <Step>
              <StepButton onClick={onStepClick(2)}>Просмотры</StepButton>

              <StepContent>
                <Dropzone label="Фотографии" required={status$ === STATUS_SHOWS && isRequiredMine} name={form.$.estate.$.photos.name} value={form.$.estate.$.photos.value} onChange={form.$.estate.$.photos.onChange} isMultiple error={!!form.$.estate.$.photos.error}/>

                <Dropzone label="Растяжка" name={form.$.estate.$.stretchMarks.name} value={form.$.estate.$.stretchMarks.value} onChange={form.$.estate.$.stretchMarks.onChange} isMultiple/>
              </StepContent>
            </Step>
          </Stepper>

          <div styleName="form__actions">
            <Button styleName="form__action" type="button" color="primary" variant="outlined" size="medium" onClick={onCancel} disabled={isSending}>
              Отмена
            </Button>
            <Button type="submit" styleName="form__action" color="primary" variant="contained" size="medium" disabled={isSending}>
              Сохранить
            </Button>
          </div>
        </form>);
}));
EstatesForm.defaultProps = {
    withStatus: false,
    isSending: false
};
