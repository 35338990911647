import * as SECTION_NAME from '~constants/types/section';
import { isValidExport } from './is-valid-export';
export const isValid = (estate, status, exportTo) => {
    const { adType, city, country, dealType, description, district, ownerName, ownerPhone, fromThirdPartyAgent, thirdPartyAgentName, thirdPartyAgentPhone, photos, estateContracts, price, region, sectionName, street, subdistrict } = estate.general;
    if (exportTo && !isValidExport(estate, exportTo)) {
        return false;
    }
    if (!(sectionName &&
        dealType &&
        adType &&
        price &&
        country &&
        region &&
        city &&
        district &&
        subdistrict &&
        street &&
        description)) {
        return false;
    }
    if (adType === 'exclusive' && !estateContracts.length) {
        return false;
    }
    if (fromThirdPartyAgent) {
        if (!(thirdPartyAgentName && thirdPartyAgentPhone.length)) {
            return false;
        }
    }
    else {
        if (!(ownerName && ownerPhone.length)) {
            return false;
        }
    }
    if (status === 'shows' && !photos.length) {
        return false;
    }
    if (sectionName === SECTION_NAME.APARTMENT) {
        const { apartmentNumber, conditionObject, constructionYear, floor, fullSquare, houseNumber, houseNumberUploading, houseStorey, houseType, kitchenSquare, livingSquare, propertyType, typeObject, wc } = estate.section;
        if (!(typeObject &&
            propertyType &&
            houseNumber &&
            houseNumberUploading &&
            fullSquare &&
            apartmentNumber &&
            floor &&
            houseStorey &&
            kitchenSquare &&
            livingSquare &&
            conditionObject &&
            houseType &&
            wc &&
            constructionYear)) {
            return false;
        }
    }
    if (sectionName === SECTION_NAME.HOUSE) {
        const { conditionObject, constructionYear, fullSquare, houseNumber, houseNumberUploading, houseStorey, kitchenSquare, landSquare, livingSquare, propertyType, roomsAmount, typeObject, wc } = estate.section;
        if (!(typeObject &&
            propertyType &&
            houseNumber &&
            houseStorey &&
            houseNumberUploading &&
            roomsAmount &&
            fullSquare &&
            livingSquare &&
            kitchenSquare &&
            landSquare &&
            conditionObject &&
            constructionYear &&
            wc)) {
            return false;
        }
    }
    if (sectionName === SECTION_NAME.STEAD) {
        const { landCategory, landSquare, number: steadNumber } = estate.section;
        if (!(landCategory && landSquare && steadNumber)) {
            return false;
        }
    }
    if (sectionName === SECTION_NAME.COMMERCIAL) {
        const { conditionObject, fullSquare, houseNumber, houseNumberUploading, typeObject } = estate.section;
        if (!(typeObject && houseNumber && houseNumberUploading && fullSquare && conditionObject)) {
            return false;
        }
    }
    return true;
};
