import { funnelsAgentsStore } from './views/funnels-agents/funnels-agents.store';
import { funnelsEstatesStore } from './views/funnels-estates/funnels-estates.store';
import { funnelsLeadsStore } from './views/funnels-leads/funnels-leads.store';
import { funnelsTransfersEstatesStore } from './views/funnels-transfers-estates/funnels-transfers-estates.store';
import { funnelsTransfersLeadsStore } from './views/funnels-transfers-leads/funnels-transfers-leads.store';
export default [
    {
        name: 'view',
        path: '/',
        forwardTo: 'funnels.leads'
    },
    {
        name: 'estates',
        path: '/estates',
        component: () => import('./funnels').then(m => m.default),
        onActivate: funnelsEstatesStore.getAll
    },
    {
        name: 'leads',
        path: '/leads',
        component: () => import('./funnels').then(m => m.default),
        onActivate: funnelsLeadsStore.getAll
    },
    {
        name: 'transfers-estates',
        path: '/transfers-estates',
        component: () => import('./funnels').then(m => m.default),
        onActivate: funnelsTransfersEstatesStore.getAll
    },
    {
        name: 'transfers-leads',
        path: '/transfers-leads',
        component: () => import('./funnels').then(m => m.default),
        onActivate: funnelsTransfersLeadsStore.getAll
    },
    {
        name: 'agents',
        path: '/agents',
        component: () => import('./funnels').then(m => m.default),
        onActivate: funnelsAgentsStore.getAll
    }
];
