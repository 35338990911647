import { Socket } from 'phoenix';
import { createContext } from 'react';
export class SocketStore {
    constructor() {
        this.socket = null;
        this.connect = (token) => {
            const SOCKET_URL = process.env.SOCKETS_URL;
            if (SOCKET_URL) {
                this.socket = new Socket(SOCKET_URL, {
                    params: { jwt: token }
                });
                this.socket.connect();
            }
            else {
                console.error('SOCKET_URL is missing');
            }
        };
        this.disconnect = () => {
            if (this.socket && this.socket.isConnected()) {
                this.socket.disconnect();
                this.socket = null;
            }
        };
    }
}
export const socketStore = new SocketStore();
export const SocketStoreContext = createContext(socketStore);
