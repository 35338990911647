var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action, runInAction, computed } from 'mobx';
import { createContext, useContext } from 'react';
import { ChatsService } from '~modules/features/chats';
import { socketStore } from '~stores/socket';
import { Chat } from './chat';
class ChatsStore {
    constructor() {
        this.chats = [];
        this.isLoaded = false;
        this.channels = {};
        this.setUnreadCount = (id) => (unreadCount) => {
            const chat = this.chats.find(chat => chat.id === id);
            if (chat) {
                runInAction(() => {
                    chat.unreadCount = unreadCount;
                });
            }
        };
        this.updateUnreadCount = (id) => (amount) => {
            const chat = this.chats.find(chat => chat.id === id);
            if (chat) {
                runInAction(() => {
                    chat.unreadCount += amount;
                });
            }
        };
        this.getChats = async () => {
            if (this.isLoaded) {
                return null;
            }
            this.isLoaded = true;
            const result = await ChatsService.getAll();
            runInAction(() => {
                this.chats = result;
            });
            result.forEach(chat => {
                if (this.channels[chat.id] === undefined) {
                    this.getChat(chat.id);
                }
            });
        };
        this.getChat = (id) => {
            if (!socketStore.socket) {
                return;
            }
            id = +id;
            if (this.channels[id]) {
                this.channels[id].updateDivider();
                return this.channels[id];
            }
            else {
                this.channels[id] = null;
                const channel = new Chat(id, socketStore.socket, this.setUnreadCount(id), this.updateUnreadCount(id));
                this.channels[id] = channel;
                return channel.connect();
            }
        };
        this.createDirectChat = async (employeeIds) => {
            const { data } = await ChatsService.createDirect(employeeIds);
            const chat = data.data;
            if (!this.chats.find(e => e.id === chat.id)) {
                runInAction(() => {
                    this.chats = [...this.chats, chat];
                });
                await this.getChat(chat.id);
            }
            return chat.id;
        };
        this.clearStore = () => {
            this.isLoaded = false;
            this.chats = [];
            this.channels = {};
        };
    }
    addChat(chat) {
        this.chats = [...this.chats, chat];
        this.getChat(chat.id);
    }
    get hasUnreadMessages() {
        return this.chats.some(chat => chat.unreadCount > 0);
    }
    get filtredChats() {
        const publicChats = this.chats.filter(e => e.type === 'public');
        const privateChats = this.chats.filter(e => e.type === 'private');
        const direct = this.chats.filter(e => e.type === 'direct');
        return {
            public: publicChats,
            private: privateChats,
            direct
        };
    }
}
__decorate([
    observable
], ChatsStore.prototype, "chats", void 0);
__decorate([
    action.bound
], ChatsStore.prototype, "addChat", null);
__decorate([
    computed
], ChatsStore.prototype, "hasUnreadMessages", null);
__decorate([
    computed
], ChatsStore.prototype, "filtredChats", null);
export const chatsStore = new ChatsStore();
export const ChatsStoreContext = createContext(chatsStore);
export const useChatsStore = () => {
    const chatsStore = useContext(ChatsStoreContext);
    return { chatsStore };
};
