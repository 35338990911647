import dataStoreFactory from '~utils/data-store-factory';
import { TransfersLeadsService } from '~modules/features/transfers';
import userStore from '~stores/user';

import { NEW_FIRST, NEW_LAST, PRICE_ASC, PRICE_DESC } from '~constants/types/sort';
import { ACTIVE } from '~constants/transfer-status';

const Leads = dataStoreFactory(TransfersLeadsService, {
  sortOptions: [NEW_FIRST, NEW_LAST, PRICE_ASC, PRICE_DESC],
  initialFilters: () => ({
    sectionNames: [],
    customer: '',
    statuses: [],
    price: { from: '', to: '' },
    cities: [{ id: userStore.user.city.id, title: userStore.user.city.name }],
    districts: [],
    subdistricts: [],
    apartmentsTypes: [],
    commertionalObjectTypes: [],
    houseObjectTypes: [],
    landCategories: [],
    street: '',
    agent: '',
    date: null,
    dealType: '',
    completed: false,
    my: false,
    phone: ''
  }),
  transformFilters: ({
    cities,
    districts,
    subdistricts,
    apartmentsTypes,
    commertionalObjectTypes,
    houseObjectTypes,
    landCategories,
    completed,
    ...filters
  }) => ({
    cityIds: cities.map(city => city.id),
    districtIds: districts.map(district => district.id),
    subdistrictIds: subdistricts.map(subdistrict => subdistrict.id),
    apartmentsTypes: apartmentsTypes.map(e => e.id),
    commertionalObjectTypes: commertionalObjectTypes.map(e => e.id),
    houseObjectTypes: houseObjectTypes.map(e => e.id),
    landCategories: landCategories.map(e => e.id),
    transferStatuses: completed ? [] : [ACTIVE],
    ...filters
  })
});

export default new Leads();
