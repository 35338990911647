import { sessionStore, SessionService } from '~modules/core/session';
export const OAuthRoutes = [
    {
        name: 'oauth',
        path: '/oauth',
        component: () => import('./oauth').then(m => m.OAuth),
        beforeActivate: async (router) => {
            try {
                if (await sessionStore.isAuthorized()) {
                    const { token } = await SessionService.getOAuth();
                    router.cancel();
                    window.location.replace(`http://amethyst.su/login?token=${token}`);
                    return null;
                }
                else {
                    router.navigate('signin.view');
                    return null;
                }
            }
            catch (error) {
                router.navigate('signin.view');
                return null;
            }
        }
    }
];
