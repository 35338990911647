import userStore from '~stores/user';

const hasPermission = persmission => {
  return userStore.permissions.includes(persmission);
};

export default async (router, toState) => {
  if (hasPermission('deals.new')) {
    return toState;
  }
  router.cancel();
  router.navigate('home.view');
  return null;
};
