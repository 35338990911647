var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, runInAction, computed } from 'mobx';
import { createContext } from 'react';
import { CalendarService } from '~modules/features/calendar';
import moment from 'moment';
export class Calendar {
    constructor() {
        this.events = [];
        this.daysReculculate = {}; // FIXME: Ужасный хак
        this.isLoading = false;
        this.startDay = null;
        this.getEvents = async (startDay) => {
            this.startDay = startDay;
            runInAction(() => {
                this.isLoading = true;
            });
            const events = await CalendarService.getAll(startDay);
            runInAction(() => {
                this.events.replace(events);
                this.daysReculculate = {};
                this.isLoading = false;
            });
        };
    }
    get days() {
        // tslint:disable-next-line
        this.daysReculculate;
        if (this.startDay === null) {
            return {};
        }
        const days = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
        const date = moment(this.startDay).startOf('week'); // day(1);
        const result = {};
        for (const day of days) {
            result[`${day}:${date.date()}`] = [];
            date.add(1, 'day');
        }
        for (const event of this.events) {
            try {
                const date = moment.utc(event.startAt).local();
                const day = `${date.format('dd').toUpperCase()}:${date.format('D')}`;
                result[day].push(event);
            }
            catch (error) {
                //
            }
        }
        return result;
    }
    get start() {
        let min = 25;
        for (const event of this.events) {
            const date = moment.utc(event.startAt).local();
            const hour = date.get('hour');
            if (min === -1 || hour < min) {
                min = hour;
            }
        }
        return Math.min(Math.max(min - 1, 0), 9);
    }
    get end() {
        let max = 0;
        for (const event of this.events) {
            const date = moment.utc(event.startAt).local();
            const hour = date.get('hour');
            if (hour > max) {
                max = hour;
            }
        }
        return Math.max(max + 2, 18);
    }
}
__decorate([
    observable
], Calendar.prototype, "events", void 0);
__decorate([
    observable
], Calendar.prototype, "daysReculculate", void 0);
__decorate([
    observable
], Calendar.prototype, "isLoading", void 0);
__decorate([
    computed
], Calendar.prototype, "days", null);
__decorate([
    computed
], Calendar.prototype, "start", null);
__decorate([
    computed
], Calendar.prototype, "end", null);
export const calendarStore = new Calendar();
export const CalendarStoreContext = createContext(calendarStore);
