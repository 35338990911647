export default [
    'hotel',
    'office_space',
    'free_appointment',
    'production_room',
    'warehouse_space',
    'commercial_premises',
    'restaurant',
    'beauty_saloon'
];
