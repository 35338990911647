import request from '~services/request';

export const LocationsService = {
  getCountries: (cancelToken, params) =>
    request.get('/countries', { cancelToken, params }).then(response =>
      response.data.map(e => ({
        title: e.name,
        id: e.id
      }))
    ),
  getRegions: (cancelToken, params) =>
    request.get('/regions', { cancelToken, params }).then(response =>
      response.data.map(e => ({
        title: e.name,
        id: e.id
      }))
    ),
  getCities: (cancelToken, params) =>
    request.get('/cities', { cancelToken, params }).then(response =>
      response.data.map(e => ({
        title: e.name,
        id: e.id
      }))
    ),
  getDistricts: (cancelToken, params) =>
    request.get('/districts', { cancelToken, params }).then(response =>
      response.data.map(e => ({
        title: e.name,
        id: e.id
      }))
    ),
  getDistrictsWithSubdistricts: (cancelToken, params) =>
    request.get('/districts', { cancelToken, params }).then(response =>
      response.data.map(d => ({
        title: d.name,
        id: d.id,
        children: d.subdistricts.length
          ? d.subdistricts.map(s => ({
              title: s.name,
              id: s.id
            }))
          : null
      }))
    ),
  getSubdistricts: (cancelToken, params) =>
    request.get('/subdistricts', { cancelToken, params }).then(response =>
      response.data.map(e => ({
        title: e.name,
        id: e.id
      }))
    )
};
