import dataStoreFactory from '~utils/data-store-factory';
import { DealsService } from '~modules/features/deals';
import { createContext } from 'react';

import {
  NEW_FIRST,
  NEW_LAST,
  ASSIGNED_DEPOSITE_DATE_ASC,
  ASSIGNED_DEPOSITE_DATE_DESC
} from '~constants/types/sort';

import {
  DEAL_CREATED,
  ASSIGNED_DEPOSIT_DATE,
  ASSIGNED_DEAL_DATE,
  DEAL_FINISHED,
  REFUSE
} from '~constants/deal-statuses';

const Deals = dataStoreFactory(DealsService, {
  sortOptions: [NEW_FIRST, NEW_LAST, ASSIGNED_DEPOSITE_DATE_ASC, ASSIGNED_DEPOSITE_DATE_DESC],
  sortInitial: 3,
  initialFilters: () => ({
    searchValue: '',
    comment: '',
    id: '',
    offices: [],
    statuses: [],
    depositDate: { from: null, to: null },
    dealDate: { from: null, to: null },
    my: false,
    expired: false
  }),
  transformFilters: ({ offices, statuses, ...filters }) => ({
    statuses: statuses.length
      ? statuses
      : [DEAL_CREATED, ASSIGNED_DEPOSIT_DATE, ASSIGNED_DEAL_DATE, DEAL_FINISHED, REFUSE],
    officeIds: offices.map(office => office.id),
    ...filters
  })
});

const dealsStore = new Deals();
export default dealsStore;
export const DealsStoreContext = createContext(dealsStore);
