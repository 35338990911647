import { createContext, useContext } from 'react';

import dataStoreFactory from '~utils/data-store-factory';
import { EstatesService } from '~modules/features/estates';
import userStore from '~stores/user';

import FILTERS from '~constants/estates/filters';

import { NEW_FIRST, NEW_LAST, PRICE_ASC, PRICE_DESC } from '~constants/types/sort';

const STATUSES_ALL = [
  'not_processed',
  'first_contact',
  'shows',
  'assigned_deposite_date',
  'assigned_deal_date',
  'refuse'
];

const transformDistricts = value => {
  const districts = [];

  value.forEach(v => {
    const isSubdistict = !!v.parent;
    const district = isSubdistict ? v.parent : v;
    const e = districts.find(e => e.id === district.id);

    if (e) {
      if (isSubdistict) {
        e.subdistrictIds.push(v.id);
      }
    } else {
      const newDistrict = {
        id: district.id,
        subdistrictIds: []
      };

      if (isSubdistict) {
        newDistrict.subdistrictIds.push(v.id);
      }

      districts.push(newDistrict);
    }
  });

  return districts;
};

const Estates = dataStoreFactory(EstatesService, {
  sortOptions: [NEW_FIRST, NEW_LAST, PRICE_ASC, PRICE_DESC],
  initialFilters: params => ({
    sectionName: params.sectionName || 'apartment',
    id: '',
    ...FILTERS[params.sectionName || 'apartment'],
    cities: [{ id: userStore.user.city.id, title: userStore.user.city.name }]
  }),
  transformFilters: ({ cities, districts, statuses, showSelled, employee, ...filters }) => {
    return {
      cityIds: cities.map(e => e.id),
      districts: transformDistricts(districts),
      statuses: (!statuses.length ? STATUSES_ALL : statuses)
        .concat(showSelled ? 'deal_finished' : null)
        .filter(Boolean),
      employeeId: employee?.id,
      ...filters
    };
  }
});

const estatesStore = new Estates();

export default estatesStore;

export const EstatesStoreContext = createContext(estatesStore);

export const useEstatesStore = () => {
  const estatesStore = useContext(EstatesStoreContext);
  return estatesStore;
};
