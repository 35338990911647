import './drag-drop-column.module.scss';
import React, { memo } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
export const DragDropColumn = memo(({ children, name, count }) => {
    return (<Paper styleName="column">
      <div styleName="column__head">
        <Typography styleName="column__name" variant="h6" component="div">
          {name}
        </Typography>
        <Typography styleName="column__count" variant="caption" component="div">
          {count}
        </Typography>
      </div>

      <div styleName="column__container">{children}</div>
    </Paper>);
});
