import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from '~public/locales/ru/translations.json';
i18n.use(initReactI18next).init({
    lng: 'ru',
    fallbackLng: 'ru',
    ns: ['translations'],
    defaultNS: 'translations',
    debug: false,
    interpolation: {
        escapeValue: false
    },
    react: {
        wait: true
    },
    resources: {
        ru: {
            translations
        }
    }
});
export default i18n;
