import styles from './select.module.scss';

import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import ListItem from '@material-ui/core/ListItem';

import { Icon } from '~modules/ui/icon';

import Validatable from '~hoc/Validatable';

import compose from '~utils/compose';

import { SelectChips } from './components/select-chips';
import { SelectSearch } from './components/select-search';

const VALUE = [];

class SelectComponent extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    // Поиск
    isSearchable: PropTypes.bool,
    isSearching: PropTypes.bool,
    onSearch: PropTypes.func,
    searchValue: PropTypes.string,
    // Все остальное
    isClearable: PropTypes.bool,
    isFlat: PropTypes.bool,
    isMultiple: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          title: PropTypes.node.isRequired,
          id: PropTypes.any.isRequired
        }),
        PropTypes.shape({
          title: PropTypes.node.isRequired,
          children: PropTypes.arrayOf(
            PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.shape({
                title: PropTypes.node.isRequired,
                id: PropTypes.any.isRequired
              })
            ])
          )
        })
      ])
    ).isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.shape({
        title: PropTypes.node.isRequired,
        id: PropTypes.any.isRequired
      }),
      PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.node.isRequired,
          id: PropTypes.any.isRequired
        })
      ).isRequired,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired
    ]),
    variant: PropTypes.oneOf(['standard', 'outlined']),
    margin: PropTypes.string,
    isDisabled: PropTypes.bool,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    isTrackable: PropTypes.bool,
    isStringValue: PropTypes.bool,
    error: PropTypes.string,
    t: PropTypes.func.isRequired,
    translation: PropTypes.string,
    helperText: PropTypes.string,
    max: PropTypes.number,
    placeholder: PropTypes.string,
    required: PropTypes.bool
  };

  static defaultProps = {
    error: null,
    isClearable: true,
    isFlat: false,
    isMultiple: false,
    isSearchable: false,
    isSearching: false,
    isDisabled: false,
    isRequired: false,
    isTrackable: false,
    isStringValue: false,
    label: null,
    margin: 'normal',
    variant: 'standard',
    helperText: '',
    placeholder: ''
  };

  static muiName = 'Select';

  state = {
    labelWidth: 0
  };

  componentDidMount() {
    /* eslint-disable react/no-find-dom-node */
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
    });
    /* eslint-enable react/no-find-dom-node */
  }

  onSelectChange = event => {
    const { name, onChange, isFlat, isTrackable, isStringValue, options } = this.props;

    const { dataset } = event.currentTarget;
    const title = dataset.title;
    const parent = +dataset.parent;

    const value = isFlat
      ? dataset.id
      : {
          title,
          id: isStringValue ? dataset.id : +dataset.id
        };

    if (!isFlat && isTrackable && parent) {
      const p = options.find(e => e.id === parent);
      value.parent = {
        title: p.title,
        id: p.id
      };
    }

    onChange({ target: { name, value } });
  };

  onMultiselectChange = event => {
    const { name, onChange, isFlat, isStringValue, isTrackable, options } = this.props;
    const newValue = this.props.value.slice();

    const { dataset } = event.currentTarget;
    const title = dataset.title;
    const id = isFlat || isStringValue ? dataset.id : +dataset.id;
    const parent = +dataset.parent;
    const checked = dataset.checked === 'true';

    const index = newValue.findIndex(v => (isFlat ? v === id : v.id === id));
    const v = isFlat
      ? id
      : {
          title,
          id
        };

    if (!isFlat && isTrackable && parent) {
      const p = options.find(e => e.id === parent);
      v.parent = {
        title: p.title,
        id: p.id
      };
    }

    if (checked) {
      if (index === -1) {
        newValue.push(v);
      } else {
        newValue[index] = v;
      }
    } else if (index !== -1) {
      newValue.splice(index, 1);
    }

    onChange({ target: { name, value: newValue } });
  };

  onGroupChange = event => {
    const { name, options, value, onChange, isFlat, isTrackable } = this.props;
    const { name: eventName, checked } = event.target;

    const group = options.find(option => option.title === eventName.split('|')[1]);
    let children = group.children;

    if (!isFlat && isTrackable) {
      children = children.map(c => ({
        title: c.title,
        id: c.id,
        parent: {
          title: group.title,
          id: group.id
        }
      }));
    }

    if (checked) {
      const c = children.filter(c => !value.find(v => (isFlat ? c === v : c.id === v.id)));
      onChange({ target: { name, value: value.concat(c) } });
    } else {
      const v = value.filter(v => !children.find(c => (isFlat ? c === v : c.id === v.id)));
      onChange({ target: { name, value: v } });
    }
  };

  onDelete = chip => () => {
    const { name, onChange, value, isFlat } = this.props;
    if (isFlat) {
      onChange({ target: { name, value: value.filter(v => v !== chip) } });
    } else {
      onChange({ target: { name, value: value.filter(v => v.id !== chip) } });
    }
  };

  onClear = () => {
    const { name, onChange, isFlat } = this.props;
    if (this.props.isMultiple) {
      onChange({ target: { name, value: [] } });
    } else {
      onChange({ target: { name, value: isFlat ? '' : null } });
    }
  };

  translate = text => {
    const { t, translation } = this.props;
    return translation ? t(`${translation}.${text}`) : text;
  };

  renderClear = () => {
    const { value, isClearable, isMultiple } = this.props;

    if ((isMultiple ? value.length : !!value) && isClearable) {
      return (
        <InputAdornment styleName="select__clear" position="end">
          <IconButton aria-label="Очистить" onClick={this.onClear}>
            <Icon name="close" />
          </IconButton>
        </InputAdornment>
      );
    }

    return null;
  };

  renderValue = () => {
    const { value } = this.props;
    if (this.props.isMultiple) {
      const { isFlat, isClearable, variant, translation } = this.props;
      return (
        <SelectChips
          value={value}
          onDelete={this.onDelete}
          isFlat={isFlat}
          isClearable={isClearable}
          isOutlined={variant === 'outlined'}
          translation={translation}
        />
      );
    } else {
      return <div>{this.translate(this.props.isFlat ? value : value.title)}</div>;
    }
  };

  renderOptions = (options, inset) => {
    if (this.props.isMultiple) {
      return this.renderMultiselectOptions(options, inset);
    } else {
      return this.renderSelectOptions(options, inset);
    }
  };

  renderSelectOptions = (options, parent) => {
    const { value, isFlat } = this.props;

    return options.map(option => {
      if (typeof option === 'object' && option.children) {
        return [
          <ListSubheader styleName="select__subheader" key={option.title} disableSticky>
            {this.translate(option.title)}
          </ListSubheader>,
          this.renderSelectOptions(option.children, option.id || true)
        ];
      }

      const isChecked = isFlat ? value === option : value === option.id;
      return (
        <li key={isFlat ? option : option.id} styleName="select__item">
          <MenuItem
            styleName={classNames({ select__inset: !!parent })}
            component="div"
            selected={isChecked}
            data-title={isFlat ? option : option.title}
            data-id={isFlat ? option : option.id}
            data-checked={!isChecked}
            data-parent={parent}
            onClick={this.onSelectChange}
          >
            <ListItemText primary={this.translate(isFlat ? option : option.title)} />
          </MenuItem>
        </li>
      );
    });
  };

  renderMultiselectOptions = (options, parent) => {
    const { name, value, isFlat, max } = this.props;

    return options.map(option => {
      if (typeof option === 'object' && option.children) {
        let count = 0;
        for (const o of option.children) {
          for (const v of value) {
            if (isFlat ? v === o : v.id === o.id) {
              count++;
            }
          }
        }

        return [
          <ListItem styleName="select__subheader" key={option.title}>
            <Checkbox
              name={`${name}|${option.title}`}
              checked={count === option.children.length}
              indeterminate={count !== option.children.length && count > 0}
              onChange={this.onGroupChange}
            />
            <ListItemText primary={this.translate(option.title)} />
          </ListItem>,
          this.renderMultiselectOptions(option.children, option.id || true)
        ];
      }

      const isChecked = !!value.find(v => (isFlat ? v === option : v.id === option.id));
      return (
        <li key={isFlat ? option : option.id} styleName="select__item">
          <MenuItem
            styleName={classNames({ select__inset: !!parent })}
            component="div"
            selected={isChecked}
            data-title={isFlat ? option : option.title}
            data-id={isFlat ? option : option.id}
            data-checked={!isChecked}
            data-parent={parent}
            onClick={this.onMultiselectChange}
            disabled={!!max && value.length >= max && !isChecked}
          >
            <Checkbox checked={isChecked} />
            <ListItemText primary={this.translate(isFlat ? option : option.title)} />
          </MenuItem>
        </li>
      );
    });
  };

  render() {
    const { labelWidth } = this.state;

    const {
      className,
      error,
      disabled,
      isDisabled,
      isRequired,
      required,
      isMultiple,
      isSearchable,
      isSearching,
      label,
      margin,
      name,
      onSearch,
      options,
      searchValue,
      value,
      variant,
      helperText,
      placeholder
    } = this.props;
    const isOutlined = variant === 'outlined';

    const classes = {
      select: classNames(styles.select__input, { [styles.select__input_outlined]: isOutlined })
    };

    const isFilled = isMultiple ? !!value.length : !!value;

    return (
      <FormControl
        styleName={classNames('select', { select_outlined: isOutlined })}
        className={className}
        aria-describedby={`${name}-helper`}
        error={!!error}
        margin={margin}
        variant={variant}
        fullWidth
        disabled={isDisabled || disabled}
      >
        <InputLabel
          htmlFor={name}
          ref={ref => {
            this.InputLabelRef = ref;
          }}
          shrink={isFilled}
        >
          {`${label}${isRequired || required ? ' *' : ''}`}
        </InputLabel>
        <Select
          classes={classes}
          multiple={isMultiple}
          value={isMultiple ? VALUE : ''}
          displayEmpty={isFilled}
          input={
            isOutlined ? (
              <OutlinedInput
                id={name}
                name={name}
                labelWidth={labelWidth}
                notched={isFilled}
                endAdornment={this.renderClear()}
              />
            ) : (
              <Input id={name} name={name} endAdornment={this.renderClear()} />
            )
          }
          renderValue={this.renderValue}
          MenuProps={{
            style: {
              maxHeight: 500
            },
            classes: {
              paper: isSearchable ? styles.select__paper : null
            }
          }}
        >
          {isSearchable && (
            <SelectSearch
              name={name}
              searchValue={searchValue}
              onChange={onSearch}
              isSearching={isSearching}
              placeholder={placeholder}
            />
          )}
          {this.renderOptions(options)}
        </Select>
        {(helperText || error) && (
          <FormHelperText id={`${name}-helper`}>{error || helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default compose([withTranslation('translations'), Validatable])(SelectComponent);
