export function scrollToError(form) {
    let min = null;
    let minInput = null;
    function scroll(form) {
        Object.keys(form.$).forEach(key => {
            if (form.$[key].$) {
                scroll(form.$[key]);
            }
            else {
                if (form.$[key].error) {
                    const input = document.getElementsByName(form.$[key].name)[0];
                    if (input) {
                        const parent = input.parentNode;
                        const top = parent?.getBoundingClientRect().top;
                        if (min === null || top < min) {
                            min = top;
                            minInput = parent;
                        }
                    }
                }
            }
        });
    }
    scroll(form);
    if (minInput) {
        minInput.scrollIntoView(true);
        document.documentElement.scrollTop -= 92;
    }
}
