export const UserNamePipe = (user, isFullName = false) => {
    const { lastName, firstName, middleName } = user;
    if (!lastName) {
        return null;
    }
    let name = lastName;
    if (firstName) {
        name += ` ${isFullName ? firstName : `${firstName[0]}.`}`;
    }
    if (middleName) {
        name += ` ${isFullName ? middleName : `${middleName[0]}.`}`;
    }
    return name;
};
