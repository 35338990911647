import { chatsStore } from '~stores/chats';
export default [
    {
        name: 'show',
        path: '/:id',
        component: () => import('./views/Show').then(m => m.default),
        onActivate: async ({ id }) => {
            const chat = await chatsStore.getChat(id);
            if (chat) {
                await chat.getChat();
                return chat;
            }
        }
    }
];
