import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import NumberFormat from 'react-number-format';
import MaterialTextField from '@material-ui/core/TextField';

import Validatable from '~hoc/Validatable';

import compose from '~utils/compose';

class FormattedTextField extends PureComponent {
  static propTypes = {
    error: PropTypes.string,
    formatter: PropTypes.object,
    helperText: PropTypes.node,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    margin: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    error: null,
    formatter: {},
    isDisabled: false,
    isRequired: false,
    margin: 'normal'
  };

  static muiName = 'TextField';

  onChange = event => {
    const { name, onChange } = this.props;
    onChange({
      target: {
        name,
        value: event.value
      }
    });
  };

  renderInput = ({ inputRef, ...props }) => {
    const { formatter } = this.props;

    return (
      <NumberFormat
        onValueChange={this.onChange}
        getInputRef={inputRef}
        {...formatter}
        {...props}
      />
    );
  };

  render() {
    /* eslint-disable no-unused-vars */
    const {
      error,
      formatter,
      helperText,
      isDisabled,
      isRequired,
      label,
      name,
      onChange,
      value,
      ...props
    } = this.props;
    /* eslint-enable no-unused-vars */

    return (
      <MaterialTextField
        id={name}
        name={name}
        value={value}
        error={!!error}
        helperText={error || helperText}
        disabled={isDisabled}
        label={`${label}${isRequired && !isDisabled ? ' *' : ''}`}
        InputProps={{
          inputComponent: this.renderInput
        }}
        InputLabelProps={{
          shrink: value !== null && value !== undefined && value !== '' ? true : undefined
        }}
        {...props}
      />
    );
  }
}

export default compose([Validatable])(FormattedTextField);
