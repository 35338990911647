import styles from './autocomplete-from-dadata.module.scss';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { AutocompleteFromDadataService } from './autocomplete-from-dadata.service';
import { TextField } from '~modules/ui/text-field';
import debounce from '~utils/debounce';
import LinearProgress from '@material-ui/core/LinearProgress';
import combinations from '~utils/combinations';
const classes = {
    root: styles.autocomplete__root,
    container: styles.autocomplete__container,
    suggestionsContainerOpen: styles['autocomplete__suggestions-container_open'],
    suggestion: styles.autocomplete__suggestion,
    suggestionsList: styles['autocomplete__suggestions-list'],
    divider: styles.autocomplete__divider
};
const renderInputComponent = inputProps => {
    // tslint:disable-next-line:no-empty
    const { classes, inputRef = () => { }, ref, ...other } = inputProps;
    return (<TextField fullWidth InputProps={{
        inputRef: (node) => {
            ref(node);
            inputRef(node);
        },
        classes: {
            input: classes.input
        }
    }} {...other}/>);
};
const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);
    return (<MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => part.highlight ? (<strong key={String(index)} styleName="autocomplete__highlight_strong">
              {part.text}
            </strong>) : (<span key={String(index)} styleName="autocomplete__highlight_think">
              {part.text}
            </span>))}
      </div>
    </MenuItem>);
};
export class AutocompleteFromDadata extends PureComponent {
    constructor(props) {
        super(props);
        // tslint:enable:no-empty
        this.cache = new Map();
        this.source = null;
        this.mounted = false;
        this.state = {
            value: this.props.value || '',
            suggestions: [],
            isSearching: false,
            open: false
        };
        this.getCache = (key) => {
            if (this.cache.has(key)) {
                return this.cache.get(key);
            }
            return false;
        };
        this.setCache = (key, value) => {
            this.cache.set(key, value);
        };
        this.getSuggestions = async (value) => {
            const inputValue = value.trim().toLowerCase();
            const inputLength = inputValue.length;
            if (this.source) {
                this.source.cancel('User is canceled');
                this.source = null;
            }
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            this.source = source;
            const { display, bound, count, countries, regions, cities } = this.props;
            const params = {
                count,
                from_bound: bound && { value: bound },
                to_bound: bound && { value: bound },
                query: inputValue,
                locations: mapToDadataLocations({ countries, regions, cities }),
                restrict_value: true
            };
            return inputLength === 0
                ? []
                : AutocompleteFromDadataService.address(source.token, params).then((res) => res.reduce((res, { data, value }) => {
                    const outValue = data[`${display}`] || data[`${bound}_with_type`] || value;
                    const suggestion = {
                        label: outValue,
                        value: outValue
                    };
                    // stay only with unique label
                    if (res.some(({ label }) => suggestion.label === label)) {
                        return res;
                    }
                    else {
                        return [...res, suggestion];
                    }
                }, []));
        };
        this.handleSuggestionsFetchRequested = async ({ value, reason }) => {
            if (reason === 'input-changed') {
                if (this.mounted) {
                    this.setState({ isSearching: true });
                }
                const suggestions = this.getCache(value) || (await this.debounceGetSuggestions(value));
                this.setCache(value, suggestions);
                this.setState({ suggestions, isSearching: false, open: true });
            }
            else {
                this.setState({ open: true });
            }
        };
        this.handleSuggestionsClearRequested = () => {
            this.setState({ open: false });
        };
        this.handleChange = (event, changeEvent) => {
            const { newValue: value } = changeEvent;
            const { name, onChange } = this.props;
            onChange({ target: { name, value } });
        };
        this.debounceGetSuggestions = debounce(this.getSuggestions, 500);
    }
    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
        if (this.source) {
            this.source.cancel('AutocompleteFromDadata is unmounting');
            this.source = null;
        }
    }
    render() {
        const { suggestions, value, isSearching, open } = this.state;
        const { placeholder, label, onChange, bound, ...other } = this.props;
        const autosuggestProps = {
            renderInputComponent,
            suggestions: open ? suggestions : [],
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue,
            renderSuggestion
        };
        return (<div styleName="autocomplete">
        <Autosuggest {...autosuggestProps} inputProps={{
            classes,
            label,
            placeholder,
            value,
            onChange: this.handleChange,
            ...other
        }} theme={{
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
        }} renderSuggestionsContainer={options => (<div styleName="autocomplete__suggestions-wrapper">
              <Paper {...options.containerProps} square>
                {options.children}
              </Paper>
            </div>)}/>
        {false && isSearching && <LinearProgress styleName="autocomplete__progress"/>}
      </div>);
    }
}
AutocompleteFromDadata.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    bound: PropTypes.string,
    display: PropTypes.string,
    count: PropTypes.number,
    countries: PropTypes.arrayOf(PropTypes.string),
    regions: PropTypes.arrayOf(PropTypes.string),
    cities: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func
};
// tslint:disable:no-empty
AutocompleteFromDadata.defaultProps = {
    onChange: () => { },
    label: '',
    count: 10,
    placeholder: '',
    countries: [],
    regions: [],
    cities: []
};
function getSuggestionValue(suggestion) {
    return suggestion.value;
}
function isCity(value) {
    return !value.includes('(');
}
function mapToDadataLocations(parents) {
    const { cities, countries, regions } = parents;
    const countriesCombinations = countries && countries.length
        ? countries.map(country => ({ country: country.toLowerCase() }))
        : [{}];
    const regionsCombinations = regions && regions.length
        ? regions.map(region => ({
            region: region
                .toLowerCase()
                .replace(/область|край|республика/, '')
                .trim()
        }))
        : [{}];
    const citiesAndSettlements = cities &&
        cities.reduce((res, city) => {
            if (isCity(city)) {
                return [...res, { city: city.toLowerCase() }];
            }
            const cityName = city.slice(0, city.indexOf('(')).toLowerCase();
            const area = city
                .toLowerCase()
                .slice(city.indexOf('(') + 1, city.indexOf(')'))
                .replace('район', '')
                .trim();
            return [...res, { city: cityName }, { settlement: cityName, area }];
        }, []);
    const locations = combinations([countriesCombinations, regionsCombinations, citiesAndSettlements].filter(v => v != null));
    return locations;
}
