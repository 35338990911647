import { TransfersLeadsService, TransfersEstatesService } from '~modules/features/transfers';
import leadsStore from './Leads/stores/leads';
import estatesStore from './Estates/stores/estates';

export const ESTATES_LIST = 'transfer.estates';
export const ESTATE_SHOW = 'transfer.estates.show';
export const ESTATE_NEW = 'transfer.estates.new';
export const ESTATE_EDIT = 'transfer.estates.edit';
export const LEADS_LIST = 'transfer.leads';
export const LEAD_SHOW = 'transfer.leads.show';
export const LEAD_NEW = 'transfer.leads.new';
export const LEAD_EDIT = 'transfer.leads.edit';

export default [
  {
    name: 'view',
    path: '/',
    forwardTo: 'transfer.leads.list'
  },
  {
    name: 'estates',
    path: '/estates',
    forwardTo: 'transfer.estates.list',
    children: [
      {
        name: 'list',
        path: '/',
        component: () => import('./Estates/views/List').then(m => m.default),
        onActivate: estatesStore.getInitialPage
      },
      {
        name: 'show',
        path: '/:id',
        component: () => import('./Estates/views/estates-show').then(m => m.default),
        onActivate: ({ id }) => TransfersEstatesService.getOne(id)
      },
      {
        name: 'edit',
        path: '/:id/edit',
        component: () => import('./Estates/views/Form').then(m => m.default),
        onActivate: ({ id }) => TransfersEstatesService.getOne(id)
      },
      {
        name: 'new',
        path: '/new',
        component: () => import('./Estates/views/Form').then(m => m.default)
      }
    ]
  },
  {
    name: 'leads',
    path: '/leads',
    forwardTo: 'transfer.leads.list',
    children: [
      {
        name: 'list',
        path: '/',
        component: () => import('./Leads/views/List').then(m => m.default),
        onActivate: leadsStore.getInitialPage
      },
      {
        name: 'show',
        path: '/:id',
        component: () => import('./Leads/views/Show').then(m => m.default),
        onActivate: ({ id }) => TransfersLeadsService.getOne(id)
      },
      {
        name: 'edit',
        path: '/:id/edit',
        component: () => import('./Leads/views/Form').then(m => m.default),
        onActivate: ({ id }) => TransfersLeadsService.getOne(id)
      },
      {
        name: 'new',
        path: '/new',
        component: () => import('./Leads/views/Form').then(m => m.default)
      }
    ]
  }
];
