import request from '~services/request';
import generateLeadName from '~utils/@transfer/generate-lead-name';

export const TransfersLeadsService = {
  getAll: ({ sectionNames, transferStatuses: statuses, my, ...leadParams }, page, pageSize, sort) =>
    request
      .get('/transfers/leads', {
        params: { filterParams: { sectionNames, my, statuses }, page, pageSize, sort, leadParams }
      })
      .then(response => response.data),
  search: (cancelToken, params) => {
    const { searchValue } = params;
    const filterSearch = {
      customer: searchValue,
      my: true,
      noActiveTransfers: true,
      statuses: ['interested', 'not_processed', 'shows', 'inactive', 'first_contact']
    };
    return request.get('/leads/search', { cancelToken, params: filterSearch }).then(response =>
      response.data.data.map(lead => ({
        title: generateLeadName(lead),
        id: lead.id,
        maxAvailableTransferPercent: lead.maxAvailableTransferPercent
      }))
    );
  },
  getOne: id => request.get(`/transfers/leads/${id}`).then(response => response.data),
  create: body => request.post('/transfers/leads', body).then(response => response.data),
  update: (id, body) => request.put(`/transfers/leads/${id}`, { params: body }),
  archive: id => request.post(`/transfers/leads/${id}/archive`),
  complete: id => request.post(`/transfers/leads/${id}/take_transfer`),
  getByLead: leadId =>
    request
      .get('/transfers/get_transfer_by_lead', { params: { leadId } })
      .then(response => response.data.data)
};
