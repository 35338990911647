import { useContext } from 'react';
import { UserStoreContext } from '~stores/user';
export const useUser = () => {
    const userStore = useContext(UserStoreContext);
    const employee = userStore.employee;
    const user = userStore.user;
    const roles = Object.keys(userStore.roles);
    const can = (action) => userStore.permissions.includes(action);
    return { employee, user, roles, can };
};
