import { EstatesService } from '~modules/features/estates';
import estatesStore from './stores/estates';
export const EstatesRoutes = [
    {
        name: 'list',
        path: '/',
        component: () => import('./views/estates-list').then(m => m.default),
        onActivate: estatesStore.getInitialPage
    },
    {
        name: 'show',
        path: '/:id',
        component: () => import('./views/estates-show').then(m => m.default),
        onActivate: ({ id }) => EstatesService.getOne(id),
        afterActivate: (router, toState) => {
            if (toState.data.general.archivedAt) {
                router.cancel();
                router.navigate('estates.list');
                return null;
            }
            return toState;
        }
    },
    {
        name: 'edit',
        path: '/:id/edit',
        component: () => import('./views/estates-form').then(m => m.default),
        onActivate: ({ id }) => EstatesService.getOne(id)
    },
    {
        name: 'new',
        path: '/new?section',
        component: () => import('./views/estates-form').then(m => m.default)
    }
];
