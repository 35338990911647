import dataStoreFactory from '~utils/data-store-factory';
import { ReferralsService } from '~modules/features/referrals';

const Referrals = dataStoreFactory(ReferralsService, {
  initialFilters: () => ({
    employeeSource: null,
    franchises: []
  }),
  transformFilters: ({ employeeSource, franchises }) => ({
    employeeSourceId: employeeSource ? employeeSource.id : undefined,
    franchiseIds: franchises.map(f => f.id)
  })
});

export default new Referrals();
