import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Validatable from '~hoc/Validatable';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { DatePicker as MaterialDatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { Icon } from '~modules/ui/icon';

import compose from '~utils/compose';

const adornment = {
  endAdornment: (
    <InputAdornment position="end">
      <IconButton>
        <Icon name="calendar" />
      </IconButton>
    </InputAdornment>
  )
};

class DatePicker extends Component {
  static propTypes = {
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isDisabled: PropTypes.bool,
    disabled: PropTypes.bool,
    helperText: PropTypes.node,
    margin: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    variant: PropTypes.string,
    keyboard: PropTypes.bool
  };

  static defaultProps = {
    margin: 'normal',
    isDisabled: false
  };

  static muiName = 'TextField';

  onChange = value => {
    const { name, onChange } = this.props;

    onChange({
      target: {
        name,
        value: value
          ? value.isValid()
            ? moment.utc(value.format('YYYY-MM-DD HH:mm:ss'))
            : value
          : null
      }
    });
  };

  // moment.utc(value.format('YYYY-MM-DD HH:mm:ss'))

  render() {
    /* eslint-disable */
    const {
      error,
      helperText,
      name,
      disabled,
      isDisabled,
      variant,
      onChange,
      keyboard,
      ...props
    } = this.props;
    /* eslint-enable */

    const PickerComponent = keyboard ? KeyboardDatePicker : MaterialDatePicker;

    return (
      <PickerComponent
        id={name}
        name={name}
        onChange={this.onChange}
        format="DD/MM/YYYY"
        error={!!error}
        helperText={error || helperText}
        cancelLabel="Отмена"
        clearLabel="Сбросить"
        disabled={disabled || isDisabled}
        InputProps={adornment}
        inputVariant={variant || 'standard'}
        {...props}
      />
    );
  }
}

export default compose([Validatable])(DatePicker);
