import './drag-drop-sentinel.module.scss';
import React, { memo, useState, useEffect } from 'react';
export const DragDropSentinel = memo(({ onVisible }) => {
    const [sentinel, setSentinel] = useState(null);
    useEffect(() => {
        if (sentinel) {
            const observer = new IntersectionObserver(entries => {
                if (entries[0]) {
                    if (entries[0].isIntersecting) {
                        onVisible();
                    }
                }
            });
            observer.observe(sentinel);
            return () => {
                observer.disconnect();
            };
        }
    }, [sentinel, onVisible]);
    return <div styleName="sentinel" ref={setSentinel}/>;
});
