import { confirms } from './confirms';
import { email } from './email';
import { maxLength } from './max-length';
import { maxValue } from './max-value';
import { minValue } from './min-value';
import { min } from './min';
import { pattern } from './pattern';
import { requiredTrue } from './required-true';
import { required } from './required';
import { unique } from './unique';
import { equal } from './equal';
export const Validators = {
    confirms,
    email,
    maxLength,
    maxValue,
    minValue,
    min,
    pattern,
    requiredTrue,
    required,
    unique,
    equal
};
