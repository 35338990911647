import './estates-table-actions.module.scss';
import React, { memo } from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Icon } from '~modules/ui/icon';
export const EstatesTableActions = memo(({ count, onExport, onCancel, isPending }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    return (<>
        <div styleName="estates-table-actions">
          <Typography styleName="estates-table-actions__count" component="span" variant="h6">
            Выбрано {count}
          </Typography>
          <div styleName="estates-table-actions__buttons">
            {isMobile ? (<>
                <Tooltip title="Выгрузить в XLSX">
                  <IconButton color="secondary" onClick={onExport} disabled={isPending}>
                    <Icon name="file-excel"/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Отмена">
                  <IconButton color="secondary" onClick={onCancel}>
                    <Icon name="close-circle"/>
                  </IconButton>
                </Tooltip>
              </>) : (<>
                <Button color="secondary" onClick={onExport} disabled={isPending}>
                  Выгрузить в XLSX
                </Button>
                <Button color="secondary" onClick={onCancel}>
                  Отмена
                </Button>
              </>)}
          </div>
        </div>
        <Divider />
      </>);
});
