var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, action, computed, reaction } from 'mobx';
export class FormControl {
    constructor(value, validator) {
        this.error = null;
        this.isDirty = false;
        this.isTouched = false;
        this.setValidator = (validator) => {
            this.validator = validator;
        };
        this.removeValidator = () => {
            this.validator = undefined;
        };
        this.onChange = (event) => {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            this.setValue(value);
        };
        this.validateReactionDisposer = reaction(() => ({
            value: this.value,
            isDirty: this.isDirty,
            isTouched: this.isTouched,
            validator: this.validator
        }), () => {
            if (this.validator && this.isDirty && this.isTouched) {
                this.error = this.validator(this.value);
            }
            else {
                if (this.error !== null) {
                    this.error = null;
                }
            }
        });
        this.toJS = () => {
            return this.value;
        };
        this.name = '';
        this.value = value;
        this.validator = validator;
    }
    setValue(value) {
        this.value = value;
        if (!this.isDirty) {
            this.isDirty = true;
        }
    }
    setTouched(isTouched) {
        this.isTouched = isTouched;
    }
    setDirty(isDirty) {
        this.isDirty = isDirty;
    }
    onBlur() {
        if (!this.isTouched) {
            this.isTouched = true;
        }
    }
    setName(name) {
        this.name = name;
    }
    setError(error) {
        this.error = error;
    }
    validate() {
        this.isDirty = true;
        this.isTouched = true;
        if (this.validator) {
            this.error = this.validator(this.value);
        }
        return this.error === null;
    }
    get input() {
        return {
            id: this.name,
            name: this.name,
            value: this.value,
            onChange: this.onChange,
            onBlur: this.onBlur
        };
    }
}
__decorate([
    observable
], FormControl.prototype, "name", void 0);
__decorate([
    observable
], FormControl.prototype, "value", void 0);
__decorate([
    observable
], FormControl.prototype, "error", void 0);
__decorate([
    observable
], FormControl.prototype, "isDirty", void 0);
__decorate([
    observable
], FormControl.prototype, "isTouched", void 0);
__decorate([
    observable
], FormControl.prototype, "validator", void 0);
__decorate([
    action.bound
], FormControl.prototype, "setValue", null);
__decorate([
    action.bound
], FormControl.prototype, "setTouched", null);
__decorate([
    action.bound
], FormControl.prototype, "setDirty", null);
__decorate([
    action.bound
], FormControl.prototype, "onBlur", null);
__decorate([
    action.bound
], FormControl.prototype, "setName", null);
__decorate([
    action.bound
], FormControl.prototype, "setError", null);
__decorate([
    action.bound
], FormControl.prototype, "validate", null);
__decorate([
    computed
], FormControl.prototype, "input", null);
