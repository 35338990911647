import { useState, useCallback } from 'react';
import request from '~services/request';
import { useUser } from '~modules/hooks/use-user';
import { useRouter } from 'react-router5';
import { useChatsStore } from '~stores/chats';
export const ChatsService = {
    getAll: () => request.get('/chats').then(response => response.data.data),
    getOne: (id) => request.get(`/chats/${id}`).then(response => response.data.data),
    getEmployees: (id, page = 1) => request
        .get(`/chats/${id}/chat_employees`, { params: { page, pageSize: 15 } })
        .then(response => response.data.data),
    createDirect: (params) => request.post('/chats/create_direct_chat', params),
    getMessages: (id, messageId, direction = 'up') => request
        .get(`/chats/${id}/messages`, { params: { messageId, direction } })
        .then(response => response.data.data),
    readAllMessages: (id) => request.post(`/chats/${id}/read_all_messages`)
};
export const useCreateDirect = (employeeId) => {
    const { employee } = useUser();
    const { chatsStore } = useChatsStore();
    const router = useRouter();
    const [isPending, setPending] = useState(false);
    const onCreateDirect = useCallback(async () => {
        if (isPending) {
            return;
        }
        try {
            setPending(true);
            const chatId = await chatsStore.createDirectChat({
                employeeId1: employee.id,
                employeeId2: employeeId
            });
            router.navigate('chats.show', { id: chatId });
        }
        catch (error) {
            setPending(false);
        }
    }, [employeeId, employee, chatsStore, router, isPending, setPending]);
    return { onCreateDirect };
};
