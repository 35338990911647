export default {
    apartment: {
        sources: [],
        typeObject: [],
        price: { from: '', to: '' },
        cities: [],
        districts: [],
        street: '',
        houseNumber: '',
        fullSquare: { from: '', to: '' },
        livingSquare: { from: '', to: '' },
        kitchenSquare: { from: '', to: '' },
        floor: '',
        houseStorey: '',
        date: null,
        dealType: '',
        suspicious: false,
        groupByPhone: false
    },
    house: {
        sources: [],
        typeObject: [],
        price: { from: '', to: '' },
        cities: [],
        districts: [],
        street: '',
        houseNumber: '',
        fullSquare: { from: '', to: '' },
        livingSquare: { from: '', to: '' },
        kitchenSquare: { from: '', to: '' },
        landSquare: { from: '', to: '' },
        date: null,
        dealType: '',
        suspicious: false,
        groupByPhone: false
    },
    stead: {
        sources: [],
        landCategory: [],
        price: { from: '', to: '' },
        cities: [],
        districts: [],
        street: '',
        houseNumber: '',
        landSquare: { from: '', to: '' },
        date: null,
        infrastructure: [],
        suspicious: false,
        groupByPhone: false
    },
    commercial: {
        sources: [],
        typeObject: [],
        price: { from: '', to: '' },
        cities: [],
        districts: [],
        street: '',
        houseNumber: '',
        fullSquare: { from: '', to: '' },
        creator: '',
        date: null,
        dealType: '',
        suspicious: false,
        groupByPhone: false
    }
};
