export default [
    'studio',
    'room_hotel',
    'one_room',
    'two_room',
    'three_room',
    'four_room',
    'more_room',
    'room'
];
