import React, { memo } from 'react';
import { Provider } from 'mobx-react';
import { sessionStore } from '~modules/core/session';
import layoutStore from '~stores/layout';
import userStore from '~stores/user';
import { chatsStore } from '~stores/chats';
import { ErrorBoundary } from '~modules/ui/error-boundary';
import Notifier from '~ui/Notifier';
import { ApplicationDatePicker } from './components/application-date-picker';
import { ApplicationOffline } from './components/application-offline';
import { ApplicationRouter } from './components/application-router';
import { ApplicationSnackbar } from './components/application-snackbar';
import { ApplicationTheme } from './components/application-theme';
export const Application = memo(() => {
    return (<ErrorBoundary>
      <ApplicationTheme>
        <ApplicationDatePicker>
          <ApplicationSnackbar>
            <Provider sessionStore={sessionStore} layoutStore={layoutStore} userStore={userStore} chatsStore={chatsStore}>
              <>
                <ApplicationRouter />
                <Notifier />
                <ApplicationOffline />
              </>
            </Provider>
          </ApplicationSnackbar>
        </ApplicationDatePicker>
      </ApplicationTheme>
    </ErrorBoundary>);
});
