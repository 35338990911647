export const NOT_PROCESSED = 'not_processed';
export const FIRST_CONTACT = 'first_contact';
export const INTERESTED = 'interested';
export const SHOWS = 'shows';
export const ASSIGNED_DEPOSIT_DATE = 'assigned_deposite_date';
export const ASSIGNED_DEAL_DATE = 'assigned_deal_date';
export const DEAL_FINISHED = 'deal_finished';
export const INACTIVE = 'inactive';
export const REFUSE = 'refuse';
export default [
    { id: NOT_PROCESSED },
    { id: FIRST_CONTACT },
    { id: INTERESTED },
    { id: SHOWS },
    { id: ASSIGNED_DEPOSIT_DATE, readonly: true },
    { id: ASSIGNED_DEAL_DATE, readonly: true },
    { id: DEAL_FINISHED, readonly: true, finish: true },
    { id: REFUSE, finish: true }
];
